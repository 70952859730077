import React from "react";
import styles from "../../styles/global";
import componentStyles from "../styles/adviceComponentStyles";
import { Linking, Text, View, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

export default function AdvicePubtrans(props) {
  return (
    <>
      <View style={{ marginTop: 20 }}>
        <Text style={[styles.lightNormalText, { marginBottom: 20 }]}>
          Pour vous déplacer sur le réseau Astuce, vous n’avez plus besoin de
          prendre vos tickets en station ou dans le bus, vous pouvez le faire
          dès maintenant, par voie dématérialisée.
        </Text>
        <Text style={styles.lightTitle}>Payez par SMS :</Text>
        <Text style={[styles.lightNormalText, { marginTop: 20 }]}>
          Juste avant de monter à bord d’un véhicule, il vous suffit d’envoyer
          depuis votre mobile « V1 » au 93000 pour acheter un titre 1 Voyage (à
          1,70€) ou le code « J1 » au 93000 pour le titre 24 Heures (5,10€).
        </Text>
        <Text style={[styles.lightNormalText, { marginVertical: 10 }]}>
          Vous recevrez instantanément votre titre de transport SMS pré-validé.
          Le titre est valable sur l’ensemble du réseau ASTUCE et ce, pour tous
          les modes de transport du réseau : Bus, TEOR et Métro !
        </Text>
        <Text style={[styles.lightNormalText, { marginVertical: 10 }]}>
          (Si vous voyagez uniquement sur les lignes du secteur d’Elbeuf,
          envoyez « E1 » au 93000 pour le titre 1 Voyage)
        </Text>
        <Text style={[styles.lightNormalText, { marginVertical: 10 }]}>
          En cas de contrôle, le vérificateur scanne le code de contrôle et peut
          ainsi s’assurer de la validité du titre. Le titre est directement
          débité sur votre facture d’opérateur mobile ou décompté de votre carte
          mobile pré-payée.
        </Text>
        <TouchableOpacity
          style={componentStyles.linkContainer}
          onPress={async () => await Linking.openURL("sms:93000")}
        >
          <Text style={styles.textBold}>J'achète mon ticket par SMS</Text>
          <Text>👉</Text>
        </TouchableOpacity>
        <Text style={[styles.lightTitle, { marginTop: 20 }]}>
          Ou payez grâce au M-ticket :
        </Text>
        <Text style={[styles.lightNormalText, { marginTop: 20 }]}>
          Vous pouvez aussi télécharger l&#39;application My Astuce, et vous
          créer un compte, puis de cliquer sur M-Ticket. Vous pouvez alors
          choisir votre titre de transport, et le valider grâce aux scanneurs de
          QR codes disponibles dans tous les véhicules du réseau.
        </Text>
        <Text style={[styles.lightNormalText, { marginVertical: 10 }]}>
          Si vous voyagez à plusieurs (vous + vos 2 enfants par exemple) : une
          fois le nombre de voyages sélectionné correspondant au nombre de
          voyageurs (par exemple 3), il vous suffit de scanner une seule fois le
          M-Ticket et le nombre de voyages sera décompté. Retrouvez davantage
          d’informations ici :
        </Text>
        <TouchableOpacity
          style={componentStyles.linkContainer}
          onPress={async () =>
            await Linking.openURL(
              "https://www.reseau-astuce.fr/fr/titre-sms-ou-m-ticket/1026"
            )
          }
        >
          <Text style={styles.textBold}>Pour plus d'informations, par ici</Text>
          <Text>👉</Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
