import React, { useEffect } from "react";
import { AppLoading } from "expo";
import MainNavigator from "./src/navigation";
import { NavigationContainer } from "@react-navigation/native";
import {
  useFonts,
  Raleway_400Regular,
  Raleway_600SemiBold,
  Raleway_700Bold,
} from "@expo-google-fonts/raleway";
import { AuthProvider } from "./src/context/authContext";
import { config } from "./src/config/default";
import { GoalProvider } from "./src/context/goalContext";
import { DashboardProvider } from "./src/context/dashboardContext";
import { CoachProvider } from "./src/context/coachContext";
import { BusLinesProvider } from "./src/context/busLinesContext";

export default function App() {
  let [fontsLoaded] = useFonts({
    Raleway_400Regular,
    Raleway_600SemiBold,
    Raleway_700Bold,
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}`;
    script.async = true;

    document.body.appendChild(script);
  }, []);

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <NavigationContainer>
        <AuthProvider>
          <CoachProvider>
            <DashboardProvider>
              <GoalProvider>
                <BusLinesProvider>
                  <div className={"top-banner-warning"}>
                    Téléchargez ACCTIV pour Android ou iOS, l'expérience est
                    plus fuide sur l'application mobile.
                  </div>
                  <MainNavigator />
                </BusLinesProvider>
              </GoalProvider>
            </DashboardProvider>
          </CoachProvider>
        </AuthProvider>
      </NavigationContainer>
    );
  }
}
