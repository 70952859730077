import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  title: {
    fontSize: 20,
    color: "white",
    fontFamily: "Raleway_700Bold",
    textAlign: "center",
  },
  body: {
    flex: 1,
    margin: 20,
    color: "white",
    fontFamily: "Raleway_400Regular",
    textAlign: "center",
  },
  titleContainer: {
    paddingTop: 60,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
});
