import React from "react";
import { Text, View, StatusBar } from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/modeIconStyles";
import { checkColorForText } from "../services/utils";
import { FontAwesome5 } from "@expo/vector-icons";
import colors from "../styles/variables";

export default function ModeIconCoach(props) {
  const { mode } = props;
  return (
    <>
      {mode === "PUBTRANS" ? (
        <View style={styles.flexRow}>
          <View
            style={[
              componentStyles.modeIconContainer,
              {
                backgroundColor: colors[mode],
              },
            ]}
          >
            <FontAwesome5 name="bus" size={14} color="white" />
          </View>
        </View>
      ) : mode === "CAR" ? (
        <View style={styles.flexRow}>
          <View
            style={[
              componentStyles.modeIconContainer,
              {
                backgroundColor: colors[mode],
              },
            ]}
          >
            <FontAwesome5 name="car" size={14} color="white" />
          </View>
        </View>
      ) : mode === "EBIKE" ? (
        <View
          style={[
            componentStyles.modeIconContainer,
            {
              backgroundColor: colors[mode],
            },
          ]}
        >
          <FontAwesome5 name="motorcycle" size={14} color="white" />
        </View>
      ) : mode === "BIKE" ? (
        <View
          style={[
            componentStyles.modeIconContainer,
            {
              backgroundColor: colors[mode],
            },
          ]}
        >
          <FontAwesome5 name="bicycle" size={14} color="white" />
        </View>
      ) : mode === "COVOIT" ? (
        <View
          style={[
            componentStyles.modeIconContainer,
            {
              backgroundColor: colors[mode],
            },
          ]}
        >
          <FontAwesome5 name="user-friends" size={14} color="white" />
        </View>
      ) : (
        <View>
          <Text>{mode}</Text>
        </View>
      )}
    </>
  );
}
