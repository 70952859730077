import React, { useState, useEffect } from "react";
import styles from "../styles/global";
import screenStyles from "../styles/informationStyles";
import { Text, View, ScrollView, ImageBackground } from "react-native";
import Markdown from "react-native-markdown-renderer";
import HelmetMeta from "./Helmet/Meta";

InformationScreen.navigationOptions = ({ navigation }) => {
  return {
    title: navigation.getParam("headerTitle"),
  };
};

export default function InformationScreen(props) {
  const [data, setData] = useState(props.route.params.data);

  return (
    <View style={styles.container}>
      <HelmetMeta title={`ACCTIV - ${data.name}`} />
      <ImageBackground
        source={{ uri: data.logo.url }}
        style={{
          borderRadius: 8,
          width: "100%",
          height: 320,
          resizeMode: "contain",
        }}
      />
      <View style={{ flex: 1, alignItems: "center" }}>
        <View style={screenStyles.titleContainer}>
          <Text style={screenStyles.title}>{data.name}</Text>
        </View>
        <ScrollView
          contentContainerStyle={{
            borderRadius: 15,
            padding: 20,
          }}
        >
          <Markdown
            style={{
              margin: 20,
              text: {
                color: "white",
              },
            }}
          >
            {data.description}
          </Markdown>
        </ScrollView>
      </View>
    </View>
  );
}
