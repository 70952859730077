import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  datePickerText: {
    fontSize: 16,
    color: colors.primary,
    padding: 4,
    fontFamily: "Raleway_400Regular",
  },
  textInput: {
    fontSize: 16,
    backgroundColor: "white",
    borderRadius: 33,
    color: colors.primary,
    paddingVertical: 10,
    fontFamily: "Raleway_400Regular",
    // borderWidth: 1,
    // borderColor: colors.lightgray,
  },
  simulateTextInput: {
    position: "absolute",
    bottom: "50%",
    marginLeft: 12,
    left: 0,
    right: 0,
    fontSize: 16,
    color: colors.primary,
    fontFamily: "Raleway_400Regular",
  },
  alertBeta: {
    marginBottom: 20,
    borderRadius: 8,
    padding: 8,
    backgroundColor: `${colors.pink}50`,
    borderColor: `${colors.pink}`,
    borderWidth: 1,
    borderStyle: "solid",
  },
  alertCompanySuccess: {
    marginBottom: 20,
    borderRadius: 8,
    padding: 8,
    backgroundColor: `${colors.primary}50`,
    borderColor: `${colors.primary}`,
    borderWidth: 1,
    borderStyle: "solid",
    flexDirection: "row",
    alignItems: "center",
  },
  alertBetaText: {
    flex: 1,
    fontSize: 12,
    color: colors.primary,
    fontFamily: "Raleway_400Regular",
  },
});
