import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  title: {
    fontSize: 16,
    color: "white",
    fontFamily: "Raleway_700Bold",
  },
  titleContainer: {
    marginTop: -20,
    backgroundColor: colors.secondary,
    borderRadius: 33,
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  ctaContainer: {
    backgroundColor: `${colors.secondary}EE`,
    flex: 1,
    maxHeight: 110,
    marginTop: -10,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    paddingHorizontal: "10%",
    paddingVertical: 20,
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  optionsContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  optionContainer: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 33,
    margin: 10,
    backgroundColor: colors.verylightgray,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
});
