import colors from "../styles/variables";
import JwtDecode from "jwt-decode";
import * as SecureStore from "expo-secure-store";

export const checkColorForText = (c) => {
  var c = c.substring(1); // strip #
  var rgb = parseInt(c, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  if (luma < 50) {
    return "white";
  } else {
    return colors.primary;
  }
};

export const getStrokeColor = (mode, lines) => {
  switch (mode) {
    case "BIKE":
      return colors.BIKE;
    case "WALK":
      return colors.WALK;
    default:
      return `#${lines[0].color}`;
  }
};

export const getToken = async () => {
  const token = localStorage.getItem("userToken");
  const decodedToken = JwtDecode(token);
  if (decodedToken.exp < Date.now() / 1000) {
    console.log("Token is expired");
    return false;
  } else {
    console.log("Token is not expired", decodedToken.exp, Date.now() / 1000);
    return token;
  }
};
