import React, { useState, useEffect, useRef } from "react";
import { Text, View, StatusBar, StyleSheet, Dimensions } from "react-native";
import * as Location from "expo-location";
import { getStrokeColor } from "../services/utils";
import moment from "../services/localeMoment";
import BackButton from "./MapComponents/ButtonBack";
import polyline from "@mapbox/polyline";
import colors from "../styles/variables";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import cycleLanes from "../../assets/data/cycle_lanes.json";
import ModalShowBusStops from "./ModalShowBusStops";
import ShowCycleLanesButton from "./MapComponents/ButtonShowCycleLanes";
import ShowBusStopsButton from "./MapComponents/ButtonShowBusStops";
import MarkerInterchange from "./MapComponents/MarkerInterchange";
import ShowTrafficButton from "./MapComponents/ButtonShowTraffic";
import { useBusLines } from "../context/busLinesContext";

export default function Map(props) {
  const { journey, isFullScreen } = props;

  const mapRef = useRef();

  const { busStops } = useBusLines();

  const [itinerarySteps] = useState(journey ? journey.steps : null);

  const [location, setLocation] = useState(null);
  const [permissionDenied, setPermissionDenied] = useState(null);

  const [showBusStopsModal, setShowBusStopsModal] = useState(false);
  const [showCycleLanes, setShowCycleLanes] = useState(false);
  const [showTraffic, setShowTraffic] = useState(false);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestPermissionsAsync();
      if (status !== "granted") {
        setPermissionDenied(true);
      }

      let location = await Location.getCurrentPositionAsync({});
      console.log("Updated location");

      setLocation(location);
    })();
  }, []);

  const handleBackToRegion = (coords) => {
    mapRef.current.animateToRegion({
      latitude: coords.lat,
      longitude: coords.long,
      latitudeDelta: 0.01,
      longitudeDelta: 0.01,
    });
  };

  const getPolylineCoordinates = (arrayOfCoordinates, reverse = false) => {
    return arrayOfCoordinates.map((point) => {
      return {
        latitude: point[reverse ? 1 : 0],
        longitude: point[reverse ? 0 : 1],
      };
    });
  };

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (permissionDenied)
    return (
      <View style={{ marginTop: 60, marginBottom: "auto", padding: 20 }}>
        <Text style={{ textAlign: "center" }}>
          Pour le bon fonctionnement de ACCTIV, vous devez autoriser l'accès à
          votre localisation.
        </Text>
        <Text style={{ textAlign: "center", marginTop: 10 }}>
          Pour cela, rendez-vous dans les paramètres de votre téléphone, onglet
          "Applications" puis dans les autorisations, veuillez autoriser l'accès
          aux informations de localisation.
        </Text>
        <Text style={{ textAlign: "center", marginTop: 10 }}>
          C'est fait ? Relancer l'application et vous pourrez accéder à la carte
          !
        </Text>
      </View>
    );

  // if (mapRef?.current) {
  //   const trafficLayer = new google.maps.TrafficLayer();
  //   trafficLayer.setMap(mapRef.current);
  // }

  return location ? (
    <>
      <View
        style={[
          styles.container,
          { marginBottom: !journey && !isFullScreen ? 200 : 0 },
        ]}
      >
        <BackButton
          isHome={!journey}
          handleBack={() =>
            handleBackToRegion({
              lat: location.coords.latitude,
              long: location.coords.longitude,
            })
          }
        />
        {!journey ? (
          <>
            <ShowTrafficButton
              show={showTraffic}
              handleShow={() => setShowTraffic(!showTraffic)}
            />
            <ShowBusStopsButton
              handleShow={() => setShowBusStopsModal(!showBusStopsModal)}
            />
            <ShowCycleLanesButton
              show={showCycleLanes}
              handleShow={() => setShowCycleLanes(!showCycleLanes)}
            />
          </>
        ) : null}
        {showBusStopsModal && (
          <ModalShowBusStops close={() => setShowBusStopsModal(false)} />
        )}
        <View style={styles.mapStyle}>
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            center={
              itinerarySteps
                ? {
                    lat: journey?.isTrain
                      ? journey.start_location.lat
                      : itinerarySteps[0].start.lat,
                    lng: journey?.isTrain
                      ? journey.start_location.lng
                      : itinerarySteps[0].start.long,
                    // latitudeDelta: 0.01,
                    // longitudeDelta: 0.01,
                  }
                : {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude,
                    // latitudeDelta: 0.1,
                    // longitudeDelta: 0.1,
                  }
            }
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {showCycleLanes &&
              cycleLanes.map((lane, index) => {
                return lane.WKT ? (
                  <Polyline
                    key={index}
                    strokeColor={colors.BIKE}
                    strokeWidth={2}
                    coordinates={getPolylineCoordinates(
                      JSON.parse(
                        `[${lane.WKT.replace(/,/g, "],[")
                          .replace(/LINESTRING \(/g, "[")
                          .replace(/\)/, "]")
                          .replace(/ /g, ",")}]`
                      ),
                      true
                    )}
                  />
                ) : null;
              })}

            {busStops &&
              busStops.map((stop, index) => {
                return (
                  <Marker
                    key={index}
                    coordinate={{
                      latitude: stop.lat,
                      longitude: stop.lon,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "white",
                        opacity: 0.8,
                        borderRadius: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Raleway_600SemiBold",
                          color: colors.primary,
                        }}
                      >
                        🚏 {stop.name}
                      </Text>
                    </View>
                  </Marker>
                );
              })}
            {itinerarySteps && (
              <>
                <Marker
                  position={{
                    lat: journey?.isTrain
                      ? journey.start_location.lat
                      : itinerarySteps[0].start.lat,
                    lng: journey?.isTrain
                      ? journey.start_location.lng
                      : itinerarySteps[0].start.long,
                  }}
                  title={`Départ ${moment(
                    journey?.isTrain
                      ? journey.departure_time.value * 1000
                      : journey.time.start
                  ).format("HH:mm")}`}
                />
                <Marker
                  position={{
                    lat: journey?.isTrain
                      ? journey.end_location.lat
                      : itinerarySteps.slice(-1).pop().end.lat,
                    lng: journey?.isTrain
                      ? journey.end_location.lng
                      : itinerarySteps.slice(-1).pop().end.long,
                  }}
                  title={`Arrivée ${moment(
                    journey?.isTrain
                      ? journey.arrival_time.value * 1000
                      : journey.time.arrival
                  ).format("HH:mm")}`}
                />
                {itinerarySteps.map((step, index) => {
                  if (journey?.isTrain) {
                    return (
                      <Polyline
                        key={index}
                        strokeColor={colors["TRAIN"]}
                        strokeWidth={6}
                        coordinates={getPolylineCoordinates(
                          polyline.decode(step.polyline.points)
                        )}
                      />
                    );
                  } else if (step.mode === "BUS" || step.mode === "METRO") {
                    return (
                      <>
                        <Marker
                          coordinate={{
                            latitude: step.start.lat,
                            longitude: step.start.long,
                          }}
                        >
                          <MarkerInterchange
                            type={`Prendre : ${step.lines[0].acronym}`}
                            labelStart={`Montée à l'arrêt : ${step.start.name}`}
                            labelEnd={`Descente à l'arrêt : ${step.end.name}`}
                          />
                        </Marker>

                        <Polyline
                          key={index}
                          strokeColor={
                            step.lines[0]?.color
                              ? `#${step.lines[0].color}`
                              : colors["BUS"]
                          }
                          strokeWidth={6}
                          coordinates={getPolylineCoordinates(
                            polyline.decode(step.shapes[0])
                          )}
                        />
                      </>
                    );
                  } else {
                    return step.shapes[0] ? (
                      <Polyline
                        key={index}
                        strokeColor={colors[step.mode || "WALK"]}
                        strokeWidth={6}
                        coordinates={getPolylineCoordinates(
                          polyline.decode(step.shapes[0])
                        )}
                      />
                    ) : (
                      step.indications.map((el, indexEl) =>
                        indexEl + 1 < step.indications.length ? (
                          <Line
                            index={`${index}${indexEl}`}
                            step={step}
                            start={el}
                            end={step.indications[indexEl + 1]}
                          />
                        ) : null
                      )
                    );
                  }
                })}
              </>
            )}
          </GoogleMap>
        </View>
      </View>
    </>
  ) : null;
}

const Line = ({ index, step, start, end }) => {
  return (
    <Polyline
      path={[
        {
          lat: start.lat,
          lng: start.long,
        },
        {
          lat: end.lat,
          lng: end.long,
        },
      ]}
      options={{
        strokeColor: getStrokeColor(step.mode, step.lines),
        strokeWeight: 6,
        strokeOpacity: 1,
      }}
    />
  );
};

const CycleLane = ({ index, point, nextPoint }) => {
  return (
    <View key={index}>
      {nextPoint && nextPoint.com_insee === point.com_insee ? (
        <Polyline
          key={index}
          coordinates={[
            {
              latitude: point.Y,
              longitude: point.X,
            },
            {
              latitude: nextPoint.Y,
              longitude: nextPoint.X,
            },
          ]}
          strokeColor={"green"}
          strokeWidth={2}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  mapStyle: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
});
