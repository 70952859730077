import React, {
  useReducer,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import api from "../services/api";
import * as SecureStore from "expo-secure-store";
import * as Permissions from "expo-permissions";
import * as Notifications from "expo-notifications";

const Auth = React.createContext();

const initialState = {
  isLoading: true,
  isSignout: false,
  userToken: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...state,
        userToken: action.token,
        userData: action.user,
        firstTime: action.firstTime || false,
        // userToken: null,
        // userData: null,
        isLoading: false,
      };
    case "END_TUTORIAL":
      return {
        ...state,
        firstTime: false,
      };
    case "SIGN_IN":
      return {
        ...state,
        isSignout: false,
        firstTime: action.firstTime || false,
        userToken: action.token,
        userData: action.user,
        error: action.error,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isSignout: true,
        userToken: null,
        userData: null,
      };
    case "UPDATE_USER":
      return {
        ...state,
        userData: action.user,
      };
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const registerForPushNotificationsAsync = async (userId) => {
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS
    );

    let finalStatus = existingStatus;
    console.log("Push notif status : " + existingStatus);
    if (existingStatus === "granted" || finalStatus === "granted") {
      const token = await Notifications.getExpoPushTokenAsync();
      console.log("Expo push token **********************************", token);
      return await api.update("users", userId, { push_token: token });
    } else if (existingStatus === "denied") {
      return finalStatus;
    } else {
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
    }
  };

  const authMemo = useMemo(
    () => ({
      signIn: async (email, password) => {
        console.log("Signing in...", email);

        const { jwt, user } = await api.login({
          identifier: email,
          password: password,
        });

        if (jwt && user) {
          try {
            console.log("Saving user id ::", user.id);
            localStorage.setItem("userId", JSON.stringify(user.id));
            console.log("user id saved successfully");
            console.log("Saving token ::", jwt);
            localStorage.setItem("userToken", jwt);
            console.log("Token saved successfully");
          } catch (e) {
            console.log("Problem saving in store");
          }

          console.log("Setting user in store...", user);
          // await registerForPushNotificationsAsync(user.id);
          dispatch({
            type: "SIGN_IN",
            token: jwt,
            user,
          });
        }
      },
      signOut: async () => {
        localStorage.removeItem("userToken");
        dispatch({ type: "SIGN_OUT" });
      },
      signUp: async ({ user, jwt }) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `AsyncStorage`
        // In the example, we'll use a dummy token
        console.log("Setting user in store...", user);
        // registerForPushNotificationsAsync(user.id);
        dispatch({ type: "SIGN_IN", token: jwt, user });
      },
      restoreToken: async (token) => {
        console.log("Restoring token...", token);
        let me;
        let hasError = null;
        if (token) {
          me = await api.get("users/me");
          // check error
          if (!me.survey_data) {
            hasError = true;
          }
        }

        const firstTime = localStorage.getItem("firstTime");

        dispatch({
          type: "RESTORE_TOKEN",
          token,
          firstTime,
          user: {
            ...me,
            hasError,
          },
        });
      },
      updateUser: async (user) => dispatch({ type: "UPDATE_USER", user }),
      endTutorial: () => dispatch({ type: "END_TUTORIAL" }),
      refresh: async () => {
        let me = await api.get("users/me");
        let token = localStorage.getItem("userToken");
        dispatch({ type: "RESTORE_TOKEN", token, user: me });
      },
    }),
    []
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const checkAuth = async () => {
      let userToken;

      try {
        console.log("Checking auth...");
        userToken = localStorage.getItem("userToken");
      } catch (e) {
        // Restoring token failed
        console.log("Problem getting token");
      }

      // if (userToken) {
      //   var decoded = jwtDecode(userToken);
      // }

      authMemo.restoreToken(userToken);
    };

    if (state.isLoading) {
      checkAuth();
    }
  }, []);

  return (
    <Auth.Provider value={{ ...state, ...authMemo }}>{children}</Auth.Provider>
  );
};

export const useAuth = () => useContext(Auth);
