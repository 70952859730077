import React, { useState, useEffect, useRef } from "react";
import { Text, View, StyleSheet, Dimensions } from "react-native";
import styles from "../styles/global";
import screenStyles from "../styles/dashboardStyles";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import Header from "../components/Header";
import colors from "../styles/variables";
import BarGraph from "../components/BarGraph";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { useGoal } from "../context/goalContext";
import HelmetMeta from "./Helmet/Meta";
import { useDashboard } from "../context/dashboardContext";
import MicroModalFeedback from "../components/MicroModalFeedback";
import MicroModalCheckPhase from "../components/MicroModalCheckPhase";
import { useAuth } from "../context/authContext";
import api from "../services/api";

const TabBudget = (props) => {
  const [h, set] = useState(0);
  return (
    <View
      style={screenStyles.scene}
      onLayout={(event) => {
        var { x, y, width, height } = event.nativeEvent.layout;
        set(height);
      }}
    >
      <BarGraph {...props} unit={"€ par an"} h={h} />
    </View>
  );
};

const TabDuration = (props) => {
  const [h, set] = useState(0);
  return (
    <View
      style={screenStyles.scene}
      onLayout={(event) => {
        var { x, y, width, height } = event.nativeEvent.layout;
        set(height);
      }}
    >
      <BarGraph {...props} unit={"min par an"} h={h} />
    </View>
  );
};

const TabEmission = (props) => {
  const [h, set] = useState(0);
  console.log("h", h);
  return (
    <View
      style={screenStyles.scene}
      onLayout={(event) => {
        var { x, y, width, height } = event.nativeEvent.layout;
        set(height);
      }}
    >
      <BarGraph {...props} unit={"kg de CO2 par an"} h={h} />
    </View>
  );
};

const initialLayout = { width: Dimensions.get("window").width };

function DashboardScreen(props) {
  const navigation = useNavigation();

  const [openFeedback, setOpenFeedback] = useState({
    val: false,
  });

  const authState = useAuth();
  const { dashboard, functions } = useDashboard();
  const { goal } = useGoal();
  const { update } = props.route.params;

  const [showCheckPhase, setShowCheckPhase] = useState(
    authState?.userData?.phase_check_required
  );
  const [dashboardData, setDashboardData] = useState({
    budget: {
      initial: 0,
      current: 0,
      goal: 0,
    },
    duration: {
      initial: 0,
      current: 0,
      goal: 0,
    },
    emission: {
      initial: 0,
      current: 0,
      goal: 0,
    },
  });

  useEffect(() => {
    if (dashboard.id) {
      setDashboardData({
        budget: {
          initial: dashboard.initial_budget || 0,
          current: dashboard.current_budget || 0,
          goal: dashboard.goal_budget || 0,
        },
        duration: {
          initial: dashboard.initial_duration || 0,
          current: dashboard.current_duration || 0,
        },
        emission: {
          initial: dashboard.initial_emission || 0,
          current: dashboard.current_emission || 0,
        },
      });
    }
  }, [dashboard]);

  useEffect(() => {
    if (update) {
      console.log("Updating dashboard data...");
      functions.refreshDashboard();
    }
  }, [update]);

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "budget", title: "Budget" },
    { key: "duration", title: "Temps" },
    { key: "emission", title: "CO2" },
  ]);

  const renderScene = SceneMap({
    budget: (props) =>
      TabBudget({
        ...props,
        values: dashboardData.budget,
        createdAt: dashboard.created_at,
        phase: goal && goal.user && goal.user.phase,
        phaseVal: goal && goal.user && goal.user.phase_value,
      }),
    duration: (props) =>
      TabDuration({
        ...props,
        values: dashboardData.duration,
        createdAt: dashboard.created_at,
        phase: goal && goal.user && goal.user.phase,
        phaseVal: goal && goal.user && goal.user.phase_value,
      }),
    emission: (props) =>
      TabEmission({
        ...props,
        values: dashboardData.emission,
        createdAt: dashboard.created_at,
        phase: goal && goal.user && goal.user.phase,
        phaseVal: goal && goal.user && goal.user.phase_value,
      }),
  });

  // useEffect(() => {
  //   refreshGoal();
  // }, [isFocused]);

  const handleCheckPhase = async (ans) => {
    const updatedUser = await api.update(`users-permissions/phase`, "", {
      answer: ans,
    });
    console.log("updatedUser:", updatedUser);
    authState.updateUser(updatedUser);
  };

  const getTextEstimationPrice = () => {
    let estimation =
      authState?.userData?.survey_data?.estimation_price_vp_per_year;
    let real = dashboardData?.budget?.initial;
    if (estimation < real) {
      return (
        <>
          {`et votre voiture vous coûte plus cher que vous ne le pensez`}
          <Text style={{ fontWeight: "bold" }}>
            {` (+ ${Math.round(((real - estimation) * 100) / estimation)}%)`}
          </Text>
          {`. `}
          <Text
            style={{
              textDecorationLine: "underline",
            }}
            onPress={() =>
              navigation.navigate("Advice", {
                data: {
                  key: "COST_CAR",
                  value:
                    "Votre voiture vous coûte plus cher que vous ne le pensez !",
                  data: {
                    estimation,
                    real,
                  },
                },
              })
            }
          >{`En savoir plus`}</Text>
        </>
      );
    } else {
      return "!";
    }
  };

  return (
    <>
      <HelmetMeta title={"ACCTIV - Dashboard"} />
      <View style={screenStyles.container}>
        <Header title={"Dashboard"} />
        {showCheckPhase && (
          <MicroModalCheckPhase
            open={showCheckPhase}
            close={() => setShowCheckPhase(false)}
            handleCheckPhase={(data) => handleCheckPhase(data)}
          />
        )}

        <TabView
          navigationState={{ index, routes }}
          renderTabBar={renderTabBar}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={initialLayout}
        />

        <View style={screenStyles.goalContainer}>
          <TouchableOpacity
            containerStyle={{
              marginLeft: "auto",
              marginTop: -25,
              marginRight: "-8%",
            }}
            onPress={() => navigation.navigate("Settings", { to: "about" })}
          >
            <Ionicons
              name="md-information-circle-outline"
              size={24}
              color={"white"}
            />
          </TouchableOpacity>

          {openFeedback?.val && (
            <MicroModalFeedback
              open={openFeedback?.val}
              data={{
                type: openFeedback?.type,
              }}
              close={() =>
                setOpenFeedback({
                  val: false,
                })
              }
            />
          )}

          <TouchableOpacity
            style={screenStyles.savingsContainer}
            containerStyle={{
              overflow: "visible",
            }}
            onPress={() =>
              setOpenFeedback({
                val: true,
                type: "SAVINGS",
              })
            }
          >
            <View style={screenStyles.savingsInternalContainer}>
              <Text style={screenStyles.savingsText}>
                💰 {dashboard.total_accumulated_budget_savings || 0}€
              </Text>
              <Text style={screenStyles.savingsTextSmall}>
                économisés depuis le début du programme
              </Text>
            </View>
          </TouchableOpacity>
          <View>
            <Text style={[styles.textBold, { marginBottom: 6 }]}>
              🤔 Le saviez vous ?
            </Text>
            <Text style={styles.normalText}>
              Vos déplacements entre domicile et travail vous coûtent{" "}
              {parseInt(dashboardData.budget?.initial)}€ contre 3413€ en moyenne
              à Rouen {getTextEstimationPrice()}
            </Text>
            {/* <TouchableOpacity
            style={styles.buttonContainer}
            onPress={() => navigation.navigate("Coach")}
          >
            <Text style={styles.buttonText}>
              Je découvre des solutions alternatives
            </Text>
          </TouchableOpacity> */}
          </View>
        </View>
        <TouchableOpacity
          style={screenStyles.ctaContainer}
          onPress={() =>
            navigation.navigate("Survey", {
              screen: "SimplifiedSurveyIntroduction",
            })
          }
        >
          <Text style={screenStyles.ctaContainerText}>
            Votre situation ou vos pratiques ont évolué ? Prenez 1 minute pour
            mettre à jour les calculs en{" "}
            <Text style={{ textDecorationLine: "underline" }}>
              répondant à nouveau au questionnaire
            </Text>{" "}
            ✍️
          </Text>
        </TouchableOpacity>
      </View>
    </>
  );
}

const renderTabBar = (props) => (
  <TabBar
    {...props}
    indicatorStyle={screenStyles.indicator}
    style={screenStyles.tabBar}
    activeColor={colors.secondary}
    inactiveColor={colors.primary}
  />
);

export default DashboardScreen;
