import React, { useEffect, useState } from "react";
import { Text, View, ScrollView, Platform, ActionSheetIOS } from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/coachTabStyles.js";
import { modes } from "../config/modes";
import ModeIconCoach from "./ModeIconCoach";
import { leisures, leisuresRatio } from "../config/leisures";
import { Picker } from "@react-native-community/picker";
import colors from "../styles/variables";
import { TouchableOpacity } from "react-native-gesture-handler";

export default function CoachTab(props) {
  const { goal, data, type, unit } = props;
  const [leisureSelected, setLeisureSelected] = useState("COST");

  // removed "...jour par semaine..."
  const budgetText = {
    covoit: {
      1: "En utilisant",
      2: ", vous pourriez réduire vos frais de carburants de",
      3: ", vous économiseriez",
      4: "",
    },
    pubtrans: {
      1: "En utilisant",
      2: "",
      3: ", vous économiseriez",
      4: "",
    },
    car: {
      1: "Utiliser",
      2: "",
      3: " vous coûte",
      4: "",
      5: " dont ",
      6: " pour le carburant",
    },
    default: {
      1: "En utilisant",
      2: " ",
      3: ", vous économiseriez",
      4: "",
    },
  };

  const durationText = {
    car: {
      1: "En utilisant",
      2: "",
      3: ", vous mettriez",
      4: "pour aller au travail",
    },
    default: {
      1: "En utilisant",
      2: "",
      3: ", vous mettriez",
      4: "pour aller au travail",
    },
  };

  const emissionText = {
    car: {
      1: "En utilisant",
      2: "",
      3: ", vous consommeriez",
      4: "",
    },
    default: {
      1: "En utilisant",
      2: " tous les jours",
      3: ", vos émissions seraient réduites de",
      4: "",
    },
  };

  const getComparison = (val, mode, modeFreq, isBudget = false) => {
    const carVal = data.find((el) => el.mode === "CAR");
    if (carVal) {
      if (mode !== "CAR") {
        if (mode === "PUBTRANS" && isBudget) {
          return Math.max(carVal.value - val, 0);
        } else if (mode === "COVOIT" && isBudget) {
          return Math.max(
            (carVal.value - val) *
              ((goal.frequency || carVal.frequency) / modeFreq),
            0
          );
        } else {
          const oneDayCarVal = carVal.value / carVal.frequency;
          const oneDayModeVal = val / modeFreq;

          const carFrequency = Math.max(
            0,
            carVal.frequency - (goal.frequency || carVal.frequency)
          );

          return Math.max(
            carVal.value -
              (carFrequency * oneDayCarVal +
                (goal.frequency || carVal.frequency) * oneDayModeVal),
            0
          );
        }
      } else {
        return Math.max(carVal.value - val, 0);
      }
    }
  };

  const getText = (index, mode) => {
    switch (type) {
      case "budget":
        if (index === 2 && mode !== "CAR") {
          return ` ${goal.frequency || "tous les "} jours`;
        } else {
          return mode === "CAR"
            ? budgetText.car[index]
            : mode === "COVOIT"
            ? budgetText.covoit[index]
            : mode === "PUBTRANS"
            ? budgetText.pubtrans[index]
            : budgetText.default[index];
        }
      case "duration":
        return mode === "CAR"
          ? durationText.car[index]
          : durationText.default[index];
      case "emission":
        if (index === 2) {
          return ` ${goal.frequency || "tous les "} jours`;
        } else {
          return mode === "CAR"
            ? emissionText.car[index]
            : emissionText.default[index];
        }
      default:
        return null;
    }
  };

  const getValue = (val, mode, modeFreq) => {
    switch (type) {
      case "budget":
        const equivalent =
          (mode === "CAR" ? val : getComparison(val, mode, modeFreq, true)) /
          getLeisuresRatio()[leisureSelected];
        return leisureSelected === "TRAVEL" ||
          leisureSelected === "SKI" ||
          leisureSelected === "EBIKE"
          ? Math.round(equivalent * 10) / 10
          : Math.round(equivalent);
      case "duration":
        return `~${Math.round(val / 60)}`;
      case "emission":
        return Math.round(
          mode === "CAR" ? val : getComparison(val, mode, modeFreq)
        );
      default:
        return;
    }
  };

  const getUnit = (mode) => {
    switch (type) {
      case "budget":
        return getLeisures()[leisureSelected];
      case "duration":
        return "min ⏱️";
      case "emission":
        if (mode === "CAR") return "kg de CO2 par an ☢️";
        else return "kg de CO2 par an 🌍";
      default:
        return null;
    }
  };

  var order = ["CAR", "COVOIT", "PUBTRANS", "BIKE", "EBIKE"];
  data.sort(function (a, b) {
    return order.indexOf(a.mode) - order.indexOf(b.mode);
    //for the sake of recent versions of Google Chrome use:
    //return a.key.charCodeAt(0) > b.key.charCodeAt(0); or return a.key.charCodeAt(0) - b.key.charCodeAt(0);
  });

  const onPress = () => {
    let options = Object.keys(getLeisures()).map((el) => getLeisures()[el]);
    options = [...options, "Fermer"];
    ActionSheetIOS.showActionSheetWithOptions(
      {
        options: [...options],
        destructiveButtonIndex: options.length - 1,
      },
      (buttonIndex) => {
        if (buttonIndex !== options.length - 1) {
          setLeisureSelected(Object.keys(getLeisures())[buttonIndex]);
        }
      }
    );
  };

  const getLeisures = () => {
    let displayLeisures = Object.keys(leisures)
      .filter((el) => typeof leisures[el] !== "object")
      .map((firstLevelLeisure) => {
        return {
          key: firstLevelLeisure,
          val: leisures[firstLevelLeisure],
        };
      });
    goal.user.motivations.forEach((key) => {
      if (leisures[key]) {
        Object.keys(leisures[key]).forEach((secondLevelLeisure) => {
          if (
            !displayLeisures.map((el) => el.key).includes(secondLevelLeisure)
          ) {
            displayLeisures.push({
              key: secondLevelLeisure,
              val: leisures[key][secondLevelLeisure],
            });
          }
        });
      }
    });
    return displayLeisures.reduce((obj, item) => {
      return {
        ...obj,
        [item.key]: item.val,
      };
    }, {});
  };

  const getLeisuresRatio = () => {
    let displayLeisures = Object.keys(leisuresRatio)
      .filter((el) => typeof leisures[el] !== "object")
      .map((firstLevelLeisure) => {
        return {
          key: firstLevelLeisure,
          val: leisuresRatio[firstLevelLeisure],
        };
      });
    goal.user.motivations.forEach((key) => {
      if (leisuresRatio[key]) {
        Object.keys(leisuresRatio[key]).forEach((secondLevelLeisure) => {
          if (
            !displayLeisures.map((el) => el.key).includes(secondLevelLeisure)
          ) {
            displayLeisures.push({
              key: secondLevelLeisure,
              val: leisuresRatio[key][secondLevelLeisure],
            });
          }
        });
      }
    });
    return displayLeisures.reduce((obj, item) => {
      return {
        ...obj,
        [item.key]: item.val,
      };
    }, {});
  };

  return data && data.length ? (
    <View>
      <ScrollView
        style={{
          padding: 20,
        }}
        contentContainerStyle={{
          paddingBottom: 40,
        }}
      >
        {type === "budget" ? (
          <View
            style={{
              paddingVertical: 6,
            }}
          >
            <Text style={componentStyles.compareInText}>
              Comparer vos économies en...{" "}
            </Text>
            <View style={componentStyles.textInput}>
              {Platform.OS === "ios" ? (
                <>
                  <TouchableOpacity
                    style={componentStyles.iOSTextInput}
                    onPress={() => onPress()}
                  >
                    <Text style={componentStyles.simulateTextInput}>
                      {getLeisures()[leisureSelected]}
                    </Text>
                  </TouchableOpacity>
                </>
              ) : (
                <>
                  <Picker
                    style={{
                      opacity: 0,
                      color: "white",
                      height: 16,
                    }}
                    selectedValue={leisureSelected}
                    onValueChange={(itemValue, itemIndex) =>
                      setLeisureSelected(itemValue)
                    }
                  >
                    {Object.keys(getLeisures()).map((oneLeisureKey, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          label={getLeisures()[oneLeisureKey]}
                          value={oneLeisureKey}
                        />
                      );
                    })}
                  </Picker>
                  <Text style={componentStyles.simulateTextInput}>
                    {getLeisures()[leisureSelected]}
                  </Text>
                </>
              )}
            </View>
          </View>
        ) : null}
        {data.map((item, index) => {
          return (
            <View
              key={index}
              style={[
                componentStyles.coachItemContainer,
                {
                  borderColor: colors.secondary,
                  borderWidth: item.mode === goal.mode ? 3 : 0,
                },
              ]}
            >
              <ModeIconCoach mode={item.mode} />
              <View
                style={{
                  flex: 1,
                  marginLeft: 8,
                }}
              >
                <Text style={styles.normalText}>
                  {`${
                    item.mode === goal?.mode ? "C'est votre objectif ! " : ""
                  }${getText(1, item.mode)} ${modes[item.mode]}${getText(
                    2,
                    item.mode
                  )}${getText(3, item.mode)} `}
                  <Text style={{ fontFamily: "Raleway_700Bold" }}>{` ${getValue(
                    item.value,
                    item.mode,
                    item.frequency
                  )} ${getUnit(item.mode)}`}</Text>
                  {` ${getText(4, item.mode)}`}
                  {item.value_2 ? (
                    <>
                      {`${getText(5, item.mode)}`}
                      <Text style={{ fontFamily: "Raleway_700Bold" }}>
                        {`${getValue(
                          item.value_2,
                          item.mode,
                          item.frequency
                        )} ${getUnit(item.mode)}`}
                      </Text>
                      {`${getText(6, item.mode)}`}
                    </>
                  ) : null}
                </Text>
              </View>
            </View>
          );
        })}
      </ScrollView>
    </View>
  ) : null;
}
