import React from "react";
import { Text } from "react-native";
import { View } from "react-native";

export default function Alert({ text }) {
  return (
    <View
      style={{
        padding: 10,
        marginBottom: 15,
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        borderColor: "red",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
      }}
    >
      <Text style={{ color: "white" }}>{text}</Text>
    </View>
  );
}
