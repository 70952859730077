import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    backgroundColor: colors.primary,
  },
  divider: {
    paddingVertical: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  dividerLine: {
    flex: 1,
    margin: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#efefef",
  },
});
