import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  container: {
    paddingTop: 16,
    paddingHorizontal: 20,
    backgroundColor: "white",
    borderBottomColor: colors.verylightgray,
    borderBottomWidth: 1,
  },
  compareInText: {
    color: "white",
    fontSize: 12,
    fontFamily: "Raleway_400Regular",
    paddingBottom: 6,
  },
  textInput: {
    flex: 1,
    fontSize: 16,
    backgroundColor: "white",
    borderRadius: 33,
    color: colors.primary,
    // paddingVertical: 10,
    fontFamily: "Raleway_400Regular",
    borderWidth: 1,
    borderColor: colors.lightgray,
    alignItems: "center",
    justifyContent: "center",
  },
  iOSTextInput: {
    flex: 1,
    fontSize: 16,
    backgroundColor: "white",
    borderRadius: 33,
    color: colors.primary,
    paddingVertical: 10,
    fontFamily: "Raleway_400Regular",
    // borderWidth: 1,
    // borderColor: colors.lightgray,
  },
  simulateTextInput: {
    position: "absolute",
    textAlign: "center",
    // bottom: "60%",
    left: 0,
    right: 0,
    fontSize: 12,
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
  },

  iconContainer: {
    backgroundColor: colors.secondary,
    padding: 15,
    marginLeft: 10,
    borderRadius: 33,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  majText: {
    marginTop: 10,
    marginBottom: 6,
    marginLeft: "auto",
    fontSize: 12,
    color: colors.lightgray,
  },
  coachItemContainer: {
    backgroundColor: "white",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 4,
    padding: 8,
    borderRadius: 8,
    width: "100%",
  },
});
