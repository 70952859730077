import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  container: {
    marginTop: 15,
    marginHorizontal: 10,
  },
  internalContainer: {
    padding: 5,
    backgroundColor: "white",
    borderRadius: 5,
    opacity: 0.8,
    flexWrap: "wrap",
  },
  internalContainerInterchange: {
    padding: 5,
    backgroundColor: colors.secondary,
    borderRadius: 5,
    opacity: 0.8,
    flexWrap: "wrap",
  },
  label: {
    marginTop: 6,
    width: 150,
    fontFamily: "Raleway_400Regular",
  },
  type: {
    fontFamily: "Raleway_700Bold",
  },
  arrowDown: {
    opacity: 0.8,
    width: 0,
    height: 0,
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopWidth: 20,
    borderTopColor: "white",
    marginLeft: "auto",
    marginRight: "auto",
  },
  timeContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginTop: -15,
    marginRight: 5,
    borderRadius: 99,
    backgroundColor: colors.secondary,
    zIndex: 999,
    alignItems: "center",
  },
  timeText: {
    color: "white",
    fontFamily: "Raleway_700Bold",
  },
  durationText: {
    color: "white",
    fontSize: 10,
  },
});
