import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TextInput,
  StatusBar,
  Platform,
  TouchableOpacity,
  Image,
  ActionSheetIOS,
} from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/signUpForm";
import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "../services/localeMoment";
import { Picker } from "@react-native-community/picker";
import api from "../services/api";

export default function SignUpForm(props) {
  const { accountData, setAccountData, isSettings } = props;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [inputCompanyCode, setInputCompanyCode] = useState("");
  const [company, setCompany] = useState(null);
  const [cities, setCities] = useState(null);

  const onChangeDate = (event) => {
    setAccountData({ ...accountData, birthdate: event.target.value });
  };

  const getLabelAndPlaceholder = (key) => {
    switch (key) {
      case "firstname":
        return {
          label: "Prénom",
          placeholder: "Marie",
        };
      case "lastname":
        return {
          label: "Nom",
          placeholder: "Nollet",
        };
      case "email":
        return {
          label: "Email",
          placeholder: "marie.nollet@acctiv.co",
        };
      case "password":
        return {
          label: "Mot de passe",
          placeholder: "",
        };
      default:
        return {
          label: key,
          placeholder: "",
        };
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setCities(await api.get("cities", null, null, true));
    };
    if (!cities) {
      fetchInitialData();
    }
  }, []);

  const handleSearchCompanyByCode = async () => {
    const res = await api.get(
      `companies?code_eq=${inputCompanyCode}`,
      null,
      null,
      true
    );
    if (res && res[0]) {
      setCompany(res[0]);
      setAccountData({
        ...accountData,
        company: res[0].id,
      });
    }
  };

  const debouncedInput = useDebounce(inputCompanyCode, 400);

  useEffect(() => {
    if (debouncedInput) {
      handleSearchCompanyByCode();
    }
  }, [debouncedInput]);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    React.useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const onPress = (type) => {
    let options = [];
    switch (type) {
      case "city":
        options = [...cities.map((el) => el.name), "Fermer"];
        ActionSheetIOS.showActionSheetWithOptions(
          {
            options: [...options],
            destructiveButtonIndex: options.length - 1,
          },
          (buttonIndex) => {
            if (buttonIndex !== options.length - 1 && cities[buttonIndex]?.id) {
              setAccountData({ ...accountData, city: cities[buttonIndex].id });
            }
          }
        );
        break;

      default:
        return;
    }
  };

  return (
    <>
      {/* <TouchableOpacity
        onPress={() => {
          setAccountData({
            firstname: "Maxime",
            lastname: "Test",
            email: "max@test.fr",
            password: "test",
            birthdate: "2018-09-15T00:17:11.790Z",
            city: cities[0].id,
          });
          setInputCompanyCode("x2s24");
        }}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "yellow",
          padding: 5,
          zIndex: 999,
        }}
      >
        <Text>Fill form for dev</Text>
      </TouchableOpacity> */}
      {["firstname", "lastname", "email", "password"]
        .filter((el) => (isSettings ? el !== "password" : el))
        .map((key, index) => {
          const content = getLabelAndPlaceholder(key);
          return (
            <View key={index}>
              <Text style={styles.formLabel}>{content.label}</Text>
              <TextInput
                keyboardType={key === "email" ? "email-address" : "default"}
                secureTextEntry={key === "password"}
                style={styles.textInput}
                onChangeText={(text) =>
                  setAccountData({ ...accountData, [key]: text })
                }
                value={accountData[key]}
                placeholder={content.placeholder}
              />
            </View>
          );
        })}
      {!isSettings ? (
        <>
          <View>
            <Text style={styles.formLabel}>Date de naissance</Text>
            <input
              type="date"
              value={moment(accountData.birthdate).format("YYYY-MM-DD")}
              onChange={onChangeDate}
            />
            {/* <TouchableOpacity
              style={styles.textInput}
              onPress={() => setShowDatePicker(true)}
            >
              <Text style={componentStyles.datePickerText}>
                {accountData.birthdate
                  ? moment(accountData.birthdate).format("LL")
                  : "Sélectionnez..."}
              </Text>
              {showDatePicker && (
                <DateTimePicker
                  testID="dateTimePicker"
                  value={accountData.birthdate || new Date()}
                  mode={"date"}
                  display="default"
                  onChange={onChangeDate}
                  maximumDate={new Date()}
                />
              )}
            </TouchableOpacity> */}
          </View>
          <View>
            <Text style={styles.formLabel}>Ma ville</Text>
            <View style={styles.textInput}>
              {Platform.OS === "ios" ? (
                <>
                  <TouchableOpacity
                    style={componentStyles.textInput}
                    onPress={() => onPress("city")}
                  >
                    <Text style={componentStyles.simulateTextInput}>
                      {cities &&
                        accountData.city &&
                        cities.find((el) => el.id === accountData.city).name}
                    </Text>
                  </TouchableOpacity>
                </>
              ) : (
                <>
                  <Picker
                    style={{
                      height: 24,
                      color: "white",
                    }}
                    selectedValue={accountData.city}
                    onValueChange={(itemValue, itemIndex) =>
                      setAccountData({ ...accountData, city: itemValue })
                    }
                  >
                    {cities &&
                      cities.map((city, index) => (
                        <Picker.Item
                          key={index}
                          label={`${city.name}`}
                          value={city.id}
                        />
                      ))}
                  </Picker>
                  <Text style={componentStyles.simulateTextInput}>
                    {cities &&
                      accountData.city &&
                      cities.find((el) => el.id === accountData.city).name}
                  </Text>
                </>
              )}
            </View>
          </View>
          <View style={componentStyles.alertBeta}>
            <Text style={componentStyles.alertBetaText}>
              ACCTIV est en beta test et n'est disponible que à Rouen pour le
              moment.
            </Text>
          </View>
          <View>
            <Text style={styles.formLabel}>Mon entreprise</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(text) => setInputCompanyCode(text)}
              value={inputCompanyCode}
              placeholder={"CODE"}
            />
          </View>
        </>
      ) : null}
      {company ? (
        <View style={componentStyles.alertCompanySuccess}>
          {company.logo ? (
            <Image
              source={{ uri: company.logo.url }}
              style={{ width: 40, height: 40, borderRadius: 8, marginRight: 8 }}
            />
          ) : null}

          <Text style={componentStyles.alertBetaText}>
            Bravo, le code que vous avez saisi correspond à l'entreprise{" "}
            {company.name} !
          </Text>
        </View>
      ) : inputCompanyCode ? (
        <View style={componentStyles.alertBeta}>
          <Text style={componentStyles.alertBetaText}>
            Aucune entreprise ne correspond à ce code.
          </Text>
        </View>
      ) : null}
    </>
  );
}
