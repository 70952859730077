const colors = {
  primary: "#3C3D6D",
  secondary: "#F3BD4E",
  pink: "#FFC7F9",
  lightgray: "#CCCCE2",
  verylightgray: "#EDEDF5",
  WALK: "#9BD73B",
  METRO: "#D7B43B",
  BUS: "#D7B43B",
  BIKE: "#D73B73",
  PUBTRANS: "#D7B43B",
  EBIKE: "#9BD73B",
  COVOIT: "#3BB1D7",
  CAR: "#3C3D6D",
  TRAIN: "#6E1E78",
};

export default colors;
