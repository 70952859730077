import React, { useEffect, useRef, useState } from "react";
import { Modal, View, Text, TouchableOpacity, Linking } from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/modalNotificationsStyles";

export default function ModalDownloadMaps(props) {
  const { open, close } = props;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <View style={componentStyles.centeredView}>
        <View style={componentStyles.modalView}>
          <Text style={componentStyles.titleText}>
            Télécharger les plans de transports de la métropole Rouen Normandie
          </Text>

          <TouchableOpacity
            style={componentStyles.linkContainer}
            onPress={async () =>
              await Linking.openURL(
                "https://reseau-astuce.fr/ftp/document/pdfplans/plangen2020.pdf"
              )
            }
          >
            <Text style={styles.textBold}>
              Plan général du réseau Astuce 2020 - 2021
            </Text>
            <Text>👉</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={componentStyles.linkContainer}
            onPress={async () =>
              await Linking.openURL(
                "https://reseau-astuce.fr/ftp/document/pdfplans/zrouen2020.pdf"
              )
            }
          >
            <Text style={styles.textBold}>Zoom Rouen centre</Text>
            <Text>👉</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={componentStyles.linkContainer}
            onPress={async () =>
              await Linking.openURL(
                "https://reseau-astuce.fr/ftp/document/pdfplans/zelbeuf2020.pdf"
              )
            }
          >
            <Text style={styles.textBold}>Zoom Elbeuf centre</Text>
            <Text>👉</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={componentStyles.linkContainer}
            onPress={async () =>
              await Linking.openURL(
                "https://reseau-astuce.fr/ftp/document/pdfplans/scolgen20202021.pdf"
              )
            }
          >
            <Text style={styles.textBold}>Plan des lignes scolaires</Text>
            <Text>👉</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              componentStyles.openButton,
              {
                marginTop: "auto",
              },
            ]}
            onPress={() => close()}
          >
            <Text style={componentStyles.textStyle}>Fermer</Text>
          </TouchableOpacity>
        </View>
      </View>
    </div>
  );
}
