export const leisures = {
  COST: "euros par an 💸",
  CINEMA: "places de cinéma 🍿",
  SPORT: "mois d'abo. en salle de sport 🏋️",
  MUSIC: "places de concert  🎶 ",
  RESTAURANT: "repas au restaurant pour deux 🍽️",
  AMUSEMENT_PARK: "entrées pour un parc d'attraction 🎢",
  "Réaliser des économies": {
    EBIKE: "vélos à assistance éléctrique 🚲🔋",
    BIKE: "vélos mécanique 🚲",
    TRAIN: "allers-retours en train entre Rouen et Nice 🚅",
  },
  "Réduire mes émissions de CO2": {
    AMAP:
      "paniers de légumes variés soutenant l’agriculture paysanne et biologique 🥦",
    TRAIN: "allers-retours en train entre Rouen et Nice 🚅",
    ASSO:
      "mois de soutien à une association de protection de l’environnement 🌳",
  },

  "Gagner du temps pour moi, au quotidien": {
    TRAIN: "allers-retours en train entre Rouen et Nice 🚅",
    SKI: "séjours au ski pour 2 personnes ⛷️",
  },
  "Adopter un mode de vie plus sain et améliorer ma condition physique": {
    YOGA: "cours de yoga 🧘",
  },
};

export const leisuresRatio = {
  COST: 1,
  CINEMA: 12,
  SPORT: 30,
  MUSIC: 30,
  TRAVEL: 500,
  RESTAURANT: 60,
  AMUSEMENT_PARK: 50,
  "Réaliser des économies": {
    EBIKE: 1500,
    BIKE: 300,
    TRAIN: 95,
  },
  "Réduire mes émissions de CO2": {
    AMAP: 13,
    TRAIN: 95,
    ASSO: 8,
  },
  "Gagner du temps pour moi, au quotidien": {
    TRAIN: 95,
    SKI: 1600,
  },
  "Adopter un mode de vie plus sain et améliorer ma condition physique": {
    EBIKE: 1500,
    BIKE: 300,
    YOGA: 18,
  },
};
