import React, { useState } from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import Map from "../../components/Map";
import styles from "../../styles/global";
import screenStyles from "../../styles/itineraryStepsStyles";
import { FontAwesome } from "@expo/vector-icons";
import ModeIconStep from "../../components/ModeIconStep";
import { useNavigation } from "@react-navigation/native";
import HelmetMeta from "../Helmet/Meta";

export default function ItineraryStepsScreen(props) {
  const navigation = useNavigation();
  const journey = { ...props.route.params };

  const [showInstructions, setShowInstructions] = useState(true);

  return (
    <View style={{ flex: 1 }}>
      <HelmetMeta />
      <Map journey={journey} showMarginBottom={showInstructions} />
      <TouchableOpacity
        style={[
          screenStyles.switchView,
          { bottom: showInstructions ? "72%" : "2%" },
        ]}
        onPress={() => setShowInstructions(!showInstructions)}
      >
        <Text style={screenStyles.switchViewText}>
          <FontAwesome
            name={`long-arrow-${showInstructions ? "down" : "up"}`}
            size={14}
            color="white"
          />
          {"  "}
          {showInstructions ? "Masquer" : "Montrer"} les instructions
        </Text>
      </TouchableOpacity>
      {showInstructions ? (
        <View style={screenStyles.modalContainer}>
          <ScrollView style={screenStyles.modalInternalContainer}>
            <View style={screenStyles.stepsContainer}>
              {journey.steps.map((step, index) => {
                return (
                  <View key={index}>
                    <View style={styles.flexRow}>
                      <Text style={screenStyles.oneStepLabel}>
                        Étape {index + 1}
                      </Text>
                      <FontAwesome name={"clock-o"} size={14} color="white" />
                      <Text style={screenStyles.oneStepDuration}>
                        {Math.round(
                          journey.isTrain
                            ? step.duration.value / 60
                            : step.duration / 60
                        )}
                        min
                      </Text>
                    </View>

                    <View style={screenStyles.oneStepContainer}>
                      {!journey.isTrain && <ModeIconStep mode={step} />}
                      {step.mode === "BUS" || step.mode === "METRO" ? (
                        <View>
                          <Text
                            style={[styles.normalText, { marginVertical: 6 }]}
                          >
                            {step.stop_points.total + 1} arrêts
                          </Text>
                          <Text style={screenStyles.indicText}>
                            <Text style={styles.textBold}>{1}.</Text> Montez à
                            l'arrêt : {step.start.name}
                          </Text>
                          {step.stop_points.stops.map((stop, stopIndex) => {
                            return (
                              <View key={stopIndex}>
                                <Text style={screenStyles.indicText}>
                                  <Text style={styles.textBold}>
                                    {stopIndex + 2}.
                                  </Text>{" "}
                                  {stop.name}
                                </Text>
                              </View>
                            );
                          })}
                          <Text style={screenStyles.indicText}>
                            <Text style={styles.textBold}>
                              {step.stop_points.stops.length + 2}.
                            </Text>{" "}
                            Descendez à l'arrêt : {step.end.name}
                          </Text>
                        </View>
                      ) : journey.isTrain ? (
                        <View>
                          <Text style={screenStyles.indicText}>
                            {step.html_instructions}
                          </Text>
                        </View>
                      ) : (
                        step.indications?.length &&
                        step.indications.map((indic, indicIndex) => {
                          return (
                            <View key={indicIndex}>
                              <Text style={screenStyles.indicText}>
                                <Text style={styles.textBold}>
                                  {indicIndex + 1}.
                                </Text>{" "}
                                {indic.instruction}
                              </Text>
                            </View>
                          );
                        })
                      )}
                    </View>
                  </View>
                );
              })}
              {!journey.isTrain && (
                <>
                  <Text style={screenStyles.oneStepLabel}>
                    Vous êtes arrivé à {journey.steps.slice(-1).pop().end.name}{" "}
                    !
                  </Text>
                  <TouchableOpacity
                    style={styles.buttonLightContainer}
                    onPress={() => navigation.navigate("Itinerary")}
                  >
                    <Text style={styles.buttonText}>Retour à l'accueil</Text>
                  </TouchableOpacity>
                </>
              )}
            </View>
          </ScrollView>
        </View>
      ) : null}
    </View>
  );
}
