import React from "react";
import { Text, View, StatusBar, TouchableOpacity } from "react-native";
import styles from "../styles/global";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

export default function Header(props) {
  const { title, isOnboarding } = props;
  const navigation = useNavigation();
  return (
    <>
      <StatusBar />
      <View style={styles.headerContainer}>
        {title === "Paramètres" || title === "Je crée mon compte" ? (
          <TouchableOpacity
            onPress={() => {
              if (title === "Je crée mon compte") {
                navigation.navigate("Auth");
              } else {
                navigation.goBack();
              }
            }}
          >
            <FontAwesome name="arrow-left" size={20} style={styles.arrowBack} />
          </TouchableOpacity>
        ) : null}
        <Text style={styles.headerTitle}>{title}</Text>
        {!isOnboarding ? (
          <TouchableOpacity
            style={styles.headerIconWrapper}
            onPress={() => navigation.navigate("Settings")}
          >
            <Ionicons name="ios-settings" size={20} color="white" />
          </TouchableOpacity>
        ) : null}
      </View>
    </>
  );
}
