import React from "react";
import { Text, View } from "react-native";
import componentStyles from "./styles/modeIconStyles";
import { checkColorForText } from "../services/utils";
import { FontAwesome5 } from "@expo/vector-icons";
import colors from "../styles/variables";
import BusIcon from "./BusIcon";

export default function ModeIconStep(props) {
  const { mode } = props;
  return (
    <>
      {mode.mode === "BUS" ? (
        <View>
          <BusIcon
            color={mode.lines[0].color}
            acronym={mode.lines[0].acronym}
          />
          {/* <Text style={componentStyles.lineName}>{mode.lines[0].name}</Text> */}
        </View>
      ) : mode.mode === "METRO" ? (
        <View>
          <View
            style={[
              componentStyles.modeIconContainer,
              {
                backgroundColor: `#${mode.lines[0].color}`,
              },
            ]}
          >
            <Text
              style={[
                componentStyles.modeIconText,
                {
                  color: "white",
                },
              ]}
            >
              M
            </Text>
          </View>
          {/* <Text style={componentStyles.lineName}>{mode.lines[0].name}</Text> */}
        </View>
      ) : mode.mode === "WALK" ? (
        <View
          style={[
            componentStyles.modeIconContainer,
            {
              backgroundColor: colors[mode.mode],
            },
          ]}
        >
          <FontAwesome5 name="walking" size={14} color="white" />
        </View>
      ) : mode.mode === "BIKE" ? (
        <View
          style={[
            componentStyles.modeIconContainer,
            {
              backgroundColor: colors[mode.mode],
            },
          ]}
        >
          <FontAwesome5 name="bicycle" size={14} color="white" />
        </View>
      ) : mode.mode === "TRAIN" ? (
        <View
          style={[
            componentStyles.modeIconContainer,
            {
              backgroundColor: colors[mode.mode],
            },
          ]}
        >
          <FontAwesome5 name="train" size={14} color="white" />
        </View>
      ) : (
        <View>
          <Text>{mode.mode}</Text>
        </View>
      )}
      {/* <Text style={componentStyles.durationStep}>
        {Math.round(mode.duration / 60)}min
      </Text> */}
    </>
  );
}
