import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.primary,
    paddingTop: 20,
    paddingBottom: 40,
  },
  coachScene: {
    flex: 1,
    backgroundColor: colors.primary,
  },
  indicator: {
    backgroundColor: colors.secondary,
  },
  tabBar: {
    backgroundColor: "white",
  },
  quTitle: {
    margin: 8,
    textAlign: "center",
    fontSize: 12,
    color: "white",
    fontFamily: "Raleway_400Regular",
  },
  savingsContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -30,
  },
  savingsInternalContainer: {
    minWidth: 120,
    backgroundColor: colors.verylightgray,
    alignItems: "center",
    borderRadius: 33,
    marginBottom: 15,
    paddingVertical: 10,
    paddingHorizontal: 18,
    zIndex: 9,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    // borderColor: colors.lightgray,
    // borderWidth: 2,
    // borderStyle: "solid",
  },
  savingsText: {
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
    fontSize: 26,
  },
  savingsTextSmall: {
    fontFamily: "Raleway_600SemiBold",
    color: colors.COVOIT,
    fontSize: 10,
  },
  goalContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    paddingHorizontal: "9%",
    // marginTop: 50,
    paddingBottom: 15,
    paddingTop: 0,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    zIndex: 99,
    overflow: "visible",
  },
  ctaContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.pink,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  ctaContainerText: {
    fontSize: 12,
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
  },
  goalItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.lightgray,
    paddingVertical: 8,
    paddingLeft: 10,
    paddingRight: 4,
    marginHorizontal: 4,
    marginVertical: 2,
    borderRadius: 33,
  },
  goalItemText: {
    fontSize: 12,
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
  },
});
