import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  container: {
    marginBottom: 50,
  },
  journeyContainer: {
    backgroundColor: colors.verylightgray,
    borderRadius: 5,
    marginVertical: 4,
    paddingVertical: 6,
    paddingHorizontal: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  priceCar: {
    fontSize: 12,
    fontFamily: "Raleway_600SemiBold",
    color: "white",
    marginBottom: 10,
  },
  priceCarHighlight: {
    color: colors.secondary,
  },
  duration: {
    marginLeft: "auto",
    fontSize: 18,
    fontFamily: "Raleway_700Bold",
    color: colors.primary,
  },
  emission: {
    width: "50%",
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Raleway_400Regular",
    color: "gray",
  },
  price: {
    textAlign: "center",
    fontSize: 14,
    padding: 2,
    fontFamily: "Raleway_600SemiBold",
    color: "white",
  },
  trainHelper: {
    marginTop: 4,
    fontSize: 11,
    fontFamily: "Raleway_400Regular",
    color: "gray",
  },
});
