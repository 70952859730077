import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  switchView: {
    position: "absolute",
    right: 0,
    marginRight: 10,
    borderRadius: 99,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: `${colors.primary}50`,
  },
  switchViewText: {
    color: "white",
  },
  modalContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "70%",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: colors.primary,
    display: "flex",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  modalInternalContainer: {
    paddingTop: 20,
    width: "80%",
  },
  stepsContainer: {
    marginBottom: 40,
  },
  oneStepContainer: {
    marginBottom: 10,
    backgroundColor: colors.verylightgray,
    borderRadius: 5,
    marginVertical: 4,
    paddingVertical: 6,
    paddingHorizontal: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  oneStepLabel: {
    fontSize: 14,
    marginBottom: 2,
    marginRight: 10,
    color: colors.secondary,

    fontFamily: "Raleway_600SemiBold",
  },
  oneStepDuration: {
    fontFamily: "Raleway_400Regular",
    marginLeft: 4,
    color: colors.verylightgray,
  },
  indicText: {
    fontFamily: "Raleway_400Regular",
    marginVertical: 2,
  },
});
