import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  StatusBar,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import styles from "../styles/global";
import screenStyles from "../styles/itineraryStyles";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import api from "../services/api";
import Map from "../components/Map";
import { Ionicons } from "@expo/vector-icons";
import withAuth from "../hoc/withAuth";
import HelmetMeta from "./Helmet/Meta";
import logoAstuceRouen from "../../assets/astuce.png";
import colors from "../styles/variables";
import ShowFullScreenButton from "../components/MapComponents/ButtonShowFullScreen";
import CoachAdviceCTA from "../components/CoachAdviceCTA";
import { useGoal } from "../context/goalContext";
import ModalDownloadMaps from "../components/ModalDownloadMaps";
import withModal from "../hoc/withModal";

// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

function ItineraryScreen(props) {
  const { userData, refreshNotificationsOnFocus } = props;
  const { goal } = useGoal();

  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const [openDownloadMaps, setOpenDownloadMaps] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [city, setCity] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (userData?.id) {
      let script = document.querySelector(
        `script[src="/register-service-worker.js"]`
      );
      if (!script) {
        console.log("Adding script");
        script = document.createElement("script");
        script.src = "/register-service-worker.js";
        document.body.appendChild(script);
      }

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("expo-service-worker.js")
          .then(function () {
            return navigator.serviceWorker.ready;
          })
          .then(function (reg) {
            console.log("Service Worker is ready", reg);
            const applicationServerKey = urlB64ToUint8Array(
              "BF30YXYFZifZ_qUMWku-SlV7WhKakm_W7L0x3KSEiNEAMIrUXEVjYUUgaiWM_Wf1TSOzt6oiyyEulkEn5KLF1jU"
            );
            const options = { applicationServerKey, userVisibleOnly: true };
            reg.pushManager.subscribe(options).then(async function (sub) {
              return await api.saveSubscription({
                id: userData.id,
                subscription: sub,
              });
            });
          })
          .catch(function (error) {
            console.log("Error : ", error);
          });
      }
    }
  }, [userData]);

  // console.log("User Data *******************************", userData);

  useEffect(() => {
    const fetchCity = async () => {
      console.log("Fetching city...");
      setCity(await api.get("cities", userData.city.id || userData.city));
    };

    const fetchCompany = async () => {
      console.log("Fetching company...");
      setCompany(
        await api.get("companies", userData.company.id || userData.company)
      );
    };

    if (userData && userData.city && !city) fetchCity();
    if (userData && userData.company && !company) fetchCompany();
  }, [userData]);

  const handleClickGoTo = (dest) => {
    if (dest === "home" && userData.home_address) {
      navigation.navigate("SearchItinerary", { goTo: userData.home_address });
    } else if (dest === "work" && userData.work_address) {
      navigation.navigate("SearchItinerary", { goTo: userData.work_address });
    } else {
      navigation.navigate("SettingsAddresses");
    }
  };

  return (
    <View style={styles.container}>
      <HelmetMeta />

      <View style={screenStyles.mapContainer}>
        <Map isFullScreen={showFullScreen} />
      </View>

      <ShowFullScreenButton
        showFullScreen={showFullScreen}
        setShowFullScreen={setShowFullScreen}
      />

      {showBanner && (
        <CoachAdviceCTA
          isBanner={true}
          setShowBanner={setShowBanner}
          goalMode={goal?.mode}
          motivations={goal?.user?.motivations}
          profile={goal?.user?.profile}
          refresh={isFocused}
        />
      )}

      {!showFullScreen && (
        <View style={screenStyles.modalContainer}>
          <View style={screenStyles.modalInternalContainer}>
            <TouchableOpacity
              style={screenStyles.inputPlaceContainer}
              onPress={() => navigation.navigate("SearchItinerary")}
            >
              <Text style={screenStyles.inputPlaceText}>Où allons-nous ?</Text>
            </TouchableOpacity>
            <View style={styles.flexRow}>
              <TouchableOpacity
                style={screenStyles.buttonGoTo}
                onPress={() => handleClickGoTo("home")}
              >
                <FontAwesome5 name="home" size={14} color="white" />
                <Text style={screenStyles.buttonGoToText}>
                  Aller à mon domicile
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("SettingsAddresses", {
                    homeAddress: userData.home_address,
                    workAddress: userData.work_address,
                  })
                }
              >
                <Ionicons
                  name="ios-settings"
                  size={24}
                  color="white"
                  style={{ marginLeft: 8 }}
                />
              </TouchableOpacity>
            </View>
            <View style={styles.flexRow}>
              <TouchableOpacity
                style={screenStyles.buttonGoTo}
                onPress={() => handleClickGoTo("work")}
              >
                <FontAwesome5 name="building" size={14} color="white" />
                <Text style={screenStyles.buttonGoToText}>
                  Aller au travail
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("SettingsAddresses", {
                    homeAddress: userData.home_address,
                    workAddress: userData.work_address,
                  })
                }
              >
                <Ionicons
                  name="ios-settings"
                  size={24}
                  color="white"
                  style={{ marginLeft: 8 }}
                />
              </TouchableOpacity>
            </View>
          </View>

          <View style={screenStyles.modalBottomContainer}>
            <View style={screenStyles.modalInternalContainer}>
              <Text style={screenStyles.modalTitle}>
                Informations pratiques
              </Text>
              <View style={screenStyles.iconsContainer}>
                {city && city.logo ? (
                  <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() =>
                      navigation.navigate("Information", { data: city })
                    }
                  >
                    <ImageBackground
                      source={{ uri: city.logo.url }}
                      style={screenStyles.oneIconWrapper}
                      imageStyle={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 5,
                      }}
                    ></ImageBackground>
                  </TouchableOpacity>
                ) : null}

                {company && company.logo ? (
                  <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() =>
                      navigation.navigate("Information", { data: company })
                    }
                  >
                    <ImageBackground
                      style={screenStyles.oneIconWrapper}
                      source={{ uri: company.logo.url }}
                      imageStyle={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 5,
                      }}
                    ></ImageBackground>
                  </TouchableOpacity>
                ) : null}
                <TouchableOpacity
                  style={{
                    flex: 1,
                  }}
                  onPress={() => setOpenDownloadMaps(true)}
                >
                  <ImageBackground
                    style={screenStyles.oneIconWrapper}
                    source={logoAstuceRouen}
                    imageStyle={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 5,
                      backgroundColor: colors.BUS,
                    }}
                  ></ImageBackground>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 1 }}></TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      )}

      {openDownloadMaps && (
        <ModalDownloadMaps
          open={openDownloadMaps}
          close={() => setOpenDownloadMaps(false)}
        />
      )}
    </View>
  );
}

export default withAuth(withModal(ItineraryScreen));
