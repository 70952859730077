import React, { useEffect, useState } from "react";
import { Text, View, Image, TextInput, TouchableOpacity } from "react-native";
import styles from "../styles/global";
import screenStyles from "../styles/authStyles";
import Header from "../components/Header";
import colors from "../styles/variables";
import { useNavigation } from "@react-navigation/native";
import logo from "../../assets/logo_white.png";
import HelmetMeta from "./Helmet/Meta";
import { useAuth } from "../context/authContext";
import Alert from "../components/Alert";

export default function AuthScreen(props) {
  const navigation = useNavigation();
  const authState = useAuth();

  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  const handleSignIn = async () => {
    console.log("Handle Sign In", emailInput);
    await authState.signIn(emailInput, passwordInput);
  };

  useEffect(() => {
    if (authState.userData?.id && !authState.userData?.surveyData) {
      navigation.navigate("Questionnaire");
    }
  }, [authState]);

  return (
    <>
      <HelmetMeta />
      <View style={screenStyles.container}>
        <View style={{ flex: 1, justifyContent: "center", minWidth: 200 }}>
          <Image
            source={logo}
            style={{
              resizeMode: "contain",
              width: "100%",
              maxHeight: 150,
              height: "100%",
            }}
          />
        </View>
        <View style={{ flex: 2, justifyContent: "center", maxWidth: 400 }}>
          {authState.error ? <Alert text={authState.error} /> : null}
          <Text style={styles.whiteFormLabel}>Email</Text>
          <TextInput
            keyboardType={"email-address"}
            style={styles.textInput}
            placeholder={"Example@acctiv.io"}
            value={emailInput}
            onChangeText={setEmailInput}
          />
          <Text style={styles.whiteFormLabel}>Mot de passe</Text>
          <TextInput
            style={styles.textInput}
            secureTextEntry={true}
            placeholder={"Mot de passe"}
            value={passwordInput}
            onChangeText={setPasswordInput}
          />
          <TouchableOpacity
            style={styles.buttonLightContainer}
            onPress={handleSignIn}
          >
            <Text style={styles.buttonText}>Me connecter</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.buttonTransparentContainer}
            onPress={() => navigation.navigate("Questionnaire")}
          >
            <Text style={styles.buttonTransparentText}>
              Pas encore inscrit ?{" "}
              <Text style={{ textDecorationLine: "underline" }}>
                Je crée mon compte
              </Text>
            </Text>
          </TouchableOpacity>
          {/* <View style={screenStyles.divider}>
            <View style={screenStyles.dividerLine} />
            <Text style={{ color: "white" }}>ou</Text>
            <View style={screenStyles.dividerLine} />
          </View>
          <Text style={{ color: "white" }}>Quick connect</Text> */}
        </View>
      </View>
    </>
  );
}
