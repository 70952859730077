import * as React from "react";
import { Text, View, Button, StyleSheet, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { FontAwesome } from "@expo/vector-icons";
import colors from "../../styles/variables";
import img from "../../../assets/busstops.png";

export default function ShowBusStopsButton(props) {
  return (
    <View
      style={[
        styles.main,
        {
          top: "30%",
        },
      ]}
    >
      <TouchableOpacity onPress={props.handleShow}>
        <Image source={img} style={{ width: 33, height: 33 }} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    zIndex: 99,
    position: "absolute",
    right: 20,
    backgroundColor: colors.PUBTRANS,
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
