import React, { useState, useEffect } from "react";
import styles from "../../styles/global";
import screenStyles from "../../styles/introductionSurveyStyles";
import { Text, View, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import illu from "../../../assets/acctiv_info_survey.png";
import HelmetMeta from "../Helmet/Meta";

export default function IntroductionSurveyScreen(props) {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <HelmetMeta />
      <View
        style={{ flex: 1, width: "100%", alignItems: "center", padding: 20 }}
      >
        <View style={screenStyles.titleContainer}>
          <Text style={screenStyles.title}>
            Alors, du neuf dans vos pratiques de mobilité ?
          </Text>
        </View>
        <Image
          source={illu}
          style={{ flex: 3, width: "100%", resizeMode: "contain" }}
        />
        <Text style={screenStyles.body}>
          Il y a un mois, après avoir téléchargé Acctiv, vous répondiez pour la
          première fois à notre petite enquête. Nous souhaiterions aujourd'hui
          savoir si vos pratiques de mobilité ont évolué.
        </Text>
        <TouchableOpacity
          style={styles.buttonLightContainer}
          onPress={() =>
            navigation.navigate("SurveyQuestionnaire", {
              type: "show_after_one_month",
            })
          }
        >
          <Text style={styles.buttonText}>Je réponds au questionnaire</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
