import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  modalContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "50%",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    backgroundColor: colors.primary,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  headerBannerContainer: {
    position: "absolute",
    top: 10,
    left: 20,
    right: 20,
    height: 50,
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#6a9c1a",
    flexDirection: "row",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  bannerCloseContainer: {
    marginLeft: "auto",
    borderRadius: 33,
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff50",
  },
  mapContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalInternalContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    width: "80%",
  },
  modalBottomContainer: {
    flex: 1,
    marginTop: "auto",
    paddingTop: 0,
    paddingBottom: 20,
    width: "100%",
    backgroundColor: colors.lightgray,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitle: {
    color: "white",
    fontFamily: "Raleway_700Bold",
    marginBottom: 10,
  },
  iconsContainer: {
    flexDirection: "row",
  },
  oneIconWrapper: {
    width: 50,
    height: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  buttonGoTo: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 4,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderColor: colors.lightgray,
    borderWidth: 1,
    borderRadius: 5,
  },
  buttonGoToText: {
    fontSize: 14,
    color: "white",
    marginLeft: 8,
    fontFamily: "Raleway_400Regular",
  },
  inputPlaceContainer: {
    backgroundColor: "white",
    borderRadius: 33,
    padding: 10,
    marginTop: 4,
    marginBottom: 16,
    borderColor: colors.lightgray,
    borderWidth: 2,
    borderStyle: "solid",
  },
  inputPlaceText: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: "Raleway_700Bold",
  },
});
