import React, {
  useReducer,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import api from "../services/api";
import { useAuth } from "./authContext";
import { useDashboard } from "./dashboardContext";

const Goal = React.createContext();

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_GOAL":
      return Object.assign({}, state, {
        ...state,
        goal: action.goal,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const GoalProvider = ({ children }) => {
  const authState = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);
  const dashboardContext = useDashboard();

  const functions = useMemo(
    () => ({
      setGoal: async (data) => dispatch({ type: "SET_GOAL", goal: data }),
      updateGoal: async (key, value) => {
        const res = await api.update("user-goals", state.goal.id, {
          [key]: value,
        });
        dashboardContext.functions.refreshDashboard();
        res &&
          dispatch({ type: "SET_GOAL", goal: { ...state.goal, [key]: value } });
      },
    }),
    [state]
  );

  useEffect(() => {
    const init = async () => {
      const userId = authState.userData.id;
      console.log("Init goal", userId);
      const g = await api.get("user-goals", `?user_eq=${userId}`);
      console.log("API returned...", g?.[0]?.id);
      if (g.length) {
        functions.setGoal({ ...g[0] });
      }
    };

    if (
      authState.userToken &&
      authState.userData?.id &&
      !(state.goal && state.goal.mode)
    ) {
      init();
    }
  }, [authState.userToken]);

  console.log("Inside context", state.goal?.mode);

  return (
    <Goal.Provider value={{ goal: state.goal, functions }}>
      {children}
    </Goal.Provider>
  );
};

export const useGoal = () => useContext(Goal);
