import React, { useEffect, useReducer, useMemo, useState, useRef } from "react";
import api from "../services/api";
import { useAuth } from "../context/authContext";

function withAuth(WrappedComponent) {
  function Enhancer(props) {
    const authContext = useAuth();

    const [userData, setUserData] = useState(null);

    useEffect(() => {
      let mounted = true;
      if (!userData) {
        console.log(
          "Initialize HOC withAuth",
          authContext.userData && authContext.userData.id
        );
        mounted && setUserData(authContext.userData);
      }
      return () => {
        mounted = false;
      };
    }, [authContext.userData]);

    const updateUserData = async (key, value) => {
      const updated = await api.update("users", userData.id, {
        [key]: value,
      });
      if (updated) {
        setUserData(updated);
        authContext.updateUser({ ...updated });
      }
    };

    const updateAllUserData = async (data) => {
      const updated = await api.update("users", userData.id, data);
      if (updated) {
        setUserData(updated);
        authContext.updateUser({ ...updated });
      }
    };

    return (
      <>
        <WrappedComponent
          {...props}
          authState={{ ...authContext }}
          userData={userData}
          updateUserData={updateUserData}
          updateAllUserData={updateAllUserData}
          refreshUserData={authContext.refresh}
        />
      </>
    );
  }
  return Enhancer;
}

export default withAuth;
