import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Alert,
} from "react-native";
import * as SecureStore from "expo-secure-store";
import styles from "../styles/global";
import screenStyles from "../styles/questionnaireStyles";
import Header from "../components/Header";
import colors from "../styles/variables";
import api from "../services/api";
import OneQuestion from "../components/OneQuestion";
import SignUpForm from "../components/SignUpForm";
import { acc } from "react-native-reanimated";
import { useNavigation } from "@react-navigation/native";
import SplashScreen from "./SplashScreen";
import itinerary from "../services/itinerary";
import HelmetMeta from "./Helmet/Meta";

export default function QuestionnaireScreen(props) {
  const { authState } = { ...props.route.params };

  const [messageLoading, setMessageLoading] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [accountData, setAccountData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    birthdate: "",
    city: "",
    company: "",
  });
  const [answers, setAnswers] = useState(null);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (authState.userData?.id) {
      setStep(1);
      setError(
        "Oops, votre compte a été créé avec succès mais une erreur est survenue lors de l'enregistrement de vos réponses. Désolé pour ce désagrement. Afin d'accéder à ACCTIV, veuillez remplir à nouveau le questionnaire."
      );
    }
  }, [authState]);

  useEffect(() => {
    (async () => {
      setQuestions(
        await api.get("questions", null, `?show_at_creation=true`, true)
      );
    })();
  }, []);

  useEffect(() => {
    setAnswers(
      questions.reduce((obj, item) => {
        obj[item.label] = "";
        return obj;
      }, {})
    );
  }, [questions]);

  useEffect(() => {
    const fetchDurations = async () => {
      const res = await itinerary.getDurations(
        "rouen",
        {
          lat: answers.address_home.geometry.location.lat,
          long: answers.address_home.geometry.location.lng,
        },
        {
          lat: answers.address_work.geometry.location.lat,
          long: answers.address_work.geometry.location.lng,
        },
        answers.hour_start_work || null,
        answers.hour_end_work || null
      );
      setLoading(false);
      setMessageLoading(null);
      if (res) {
        setAnswers({
          ...answers,
          duration_dt: Math.round(res.durations["CAR"] / 60) || 0,
          distance_dt: Math.round(res.distances["CAR"] / 1000) || 0,
        });
      }
    };

    if (answers) {
      setProgress(
        Math.round(
          (Object.keys(answers).filter((el) => answers[el] !== "").length /
            Object.keys(answers).length) *
            100
        )
      );
    }

    if (
      answers &&
      answers.address_home &&
      answers.address_work &&
      answers.hour_start_work &&
      answers.hour_end_work &&
      !answers.duration_dt
    ) {
      setLoading(true);
      setMessageLoading(
        "Un peu de patience... Nous calculons la distance et la durée de transport entre votre domicile et votre lieu de travail."
      );
      fetchDurations();
    }
  }, [answers]);

  const handleNextStep = async () => {
    switch (step) {
      case 0:
        if (
          Object.keys(accountData).filter(
            (key) =>
              !accountData[key] ||
              accountData[key] === null ||
              accountData[key] === ""
          ).length > 0
        ) {
          Alert.alert("Erreur", "Veuillez remplir tous les champs");
        } else {
          setStep(1);
        }
        break;
      case 1:
        if (
          !answers.vp_everyday_solo ||
          answers.is_vp_eco ||
          (answers.vp_everyday_solo && answers.frequence_dt_vp === 0) ||
          (answers.vp_everyday_solo && answers.frequence_dt_all === 0)
        ) {
          Alert.alert(
            "Information",
            `ACCTIV est une application réservée aux personnes utilisant leur véhicule personnel thermique au moins une fois par semaine pour se rendre sur leur lieu de travail.${
              answers.frequence_dt_all === 0
                ? " Les employés en situation de télétravail ne peuvent pas accéder à ACCTIV."
                : ""
            }`
          );
        } else {
          console.log("Setting step");
          setStep(2);
        }
        break;
      case 2:
        if (
          !(
            answers.vp_everyday_solo &&
            answers.frequence_dt_all &&
            answers.frequence_dt_vp &&
            answers.distance_dt &&
            answers.cv_vp &&
            answers.motorisation_vp &&
            answers.address_home &&
            answers.address_work
          )
        ) {
          Alert.alert("Erreur", "Veuillez remplir tous les champs");
        } else {
          setLoading(true);
          let createdUser;
          let createdJwt;
          if (!error) {
            console.log("Creating account...", accountData);
            const { user, jwt } = await api.signup({
              ...accountData,
              username: accountData.email,
              home_address: answers.address_home,
              work_address: answers.address_work,
            });
            await SecureStore.setItemAsync("userToken", jwt);
            createdUser = user;
            createdJwt = jwt;
          } else {
            createdUser = authState.userData;
            createdJwt = authState.userToken;
          }

          const formattedData = questions.reduce((obj, item) => {
            obj[item.label] = {
              question: {
                text: item.question,
                type: item.type,
                unit: item.unit,
              },
              answer: answers[item.label],
            };
            return obj;
          }, {});

          // init potential null values
          formattedData["year_vp"].answer =
            formattedData["year_vp"].answer !== ""
              ? formattedData["year_vp"].answer
              : 2015;

          const qu = await api.create("answers", {
            data: formattedData,
            user: createdUser.id,
            type: "initial",
          });
          console.log("Survey answers saved", qu, createdUser);
          if (!(qu && createdUser)) {
            Alert.alert("Erreur", "Impossible de créer votre compte");
            setLoading(false);
          } else {
            // Redirection is handled by withAuth
            await authState.signUp({
              user: { ...createdUser, hasError: null },
              jwt: createdJwt,
            });
          }
        }

        break;
      default:
        return null;
    }
  };

  const stepTitles = [
    "Je crée mon compte",
    "Je remplis le questionnaire 1/2",
    "Je remplis le questionnaire 2/2",
  ];

  return (
    <>
      <HelmetMeta />
      <Header isOnboarding={true} title={stepTitles[step]} />
      <View style={screenStyles.progressBar}>
        <View
          style={[
            screenStyles.activeProgress,
            {
              width: `${progress}%`,
            },
          ]}
        />
      </View>
      {loading ? (
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
          }}
        >
          <SplashScreen message={messageLoading} />
        </View>
      ) : null}
      <ScrollView style={screenStyles.container}>
        {error && step === 1 ? (
          <Text style={screenStyles.errorContainer}>{error}</Text>
        ) : null}
        {step === 0 ? (
          <>
            <SignUpForm
              accountData={accountData}
              setAccountData={setAccountData}
            />
          </>
        ) : step === 1 ? (
          questions
            .filter((el) => el.order !== null)
            .filter((el) => el.order < 4)
            .filter((el) =>
              answers.vp_everyday_solo
                ? el
                : !el.label.includes("vp") || el.label === "vp_everyday_solo"
            )
            .sort((a, b) => a.order - b.order)
            .map((qu, index) => {
              return (
                <OneQuestion
                  key={index}
                  index={index}
                  question={qu}
                  answers={answers}
                  handleChange={(key, value) =>
                    setAnswers({ ...answers, [key]: value })
                  }
                />
              );
            })
        ) : step === 2 ? (
          questions
            .filter((el) => el.order !== null)
            .filter((el) => el.order >= 4)
            .filter((el) =>
              answers.vp_everyday_solo
                ? el
                : !el.label.includes("vp") || el.label === "vp_everyday_solo"
            )
            .filter((el) =>
              answers.frequence_dt_covoit && answers.frequence_dt_covoit !== ""
                ? el
                : !el.label.includes("mode_covoit")
            )
            .filter((el) =>
              answers.f_modes !== "" &&
              answers.f_modes.filter((el) => el.includes("Vélo")).length
                ? el
                : !el.label.includes("frequence_dt_velo") &&
                  !el.label.includes("frequence_dt_vae")
            )
            .filter((el) =>
              answers.f_modes !== "" &&
              answers.f_modes.filter((el) => el.includes("-roues")).length
                ? el
                : !el.label.includes("frequence_dt_m")
            )
            .filter((el) =>
              answers.pre_frequence_dt_covoit !== "" &&
              answers.pre_frequence_dt_covoit?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_covoit")
            )
            .filter((el) =>
              answers.pre_frequence_dt_pubtrans !== "" &&
              answers.pre_frequence_dt_pubtrans?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_pubtrans")
            )
            .filter((el) =>
              answers.pre_frequence_dt_velo !== "" &&
              answers.pre_frequence_dt_velo?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_velo")
            )
            .filter((el) =>
              answers.pre_frequence_dt_vae !== "" &&
              answers.pre_frequence_dt_vae?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_vae")
            )
            .filter((el) =>
              answers.pre_frequence_dt_moto !== "" &&
              answers.pre_frequence_dt_moto?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_moto")
            )
            .filter((el) =>
              answers.pre_frequence_dt_marche !== "" &&
              answers.pre_frequence_dt_marche?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_marche")
            )
            .filter((el) =>
              answers.correct_affirmations !== "" &&
              answers.correct_affirmations !== [] &&
              (answers.correct_affirmations?.includes(
                "Jamais je n’irais au travail autrement qu’en voiture"
              ) ||
                answers.correct_affirmations?.includes(
                  "J'aimerais prendre la voiture moins souvent pour aller au travail mais je n'ai pas d'autres choix"
                ))
                ? el
                : !(el.label === "reasons_no_change")
            )
            .sort((a, b) => a.order - b.order)
            .map((qu, index) => {
              return (
                <OneQuestion
                  key={index}
                  index={index}
                  question={qu}
                  answers={answers}
                  handleChange={(key, value) =>
                    setAnswers({ ...answers, [key]: value })
                  }
                />
              );
            })
        ) : null}

        <TouchableOpacity
          style={[
            styles.buttonLightContainer,
            { marginTop: 20, marginBottom: 60 },
          ]}
          onPress={() => handleNextStep()}
        >
          <Text style={styles.buttonText}>
            {step === 2 ? "C'est parti !" : "Suivant"}
          </Text>
        </TouchableOpacity>
      </ScrollView>
    </>
  );
}
