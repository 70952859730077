import React, { useReducer, useContext, useMemo, useEffect } from "react";
import itinerary from "../services/itinerary";
import { useAuth } from "./authContext";

const BusLines = React.createContext();

const initialState = {
  lines: [],
  disruptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LINES":
      return Object.assign({}, state, {
        ...state,
        lines: action.lines,
        disruptions: action.disruptions,
      });
    case "SET_STOPS":
      return Object.assign({}, state, {
        ...state,
        busStops: action.busStops,
      });
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const BusLinesProvider = ({ children }) => {
  const authState = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);

  const functions = useMemo(
    () => ({
      setLines: (data) =>
        dispatch({
          type: "SET_LINES",
          lines: data.lines,
          disruptions: data.disruptions,
        }),
      setStops: async (lineId) => {
        const data = await itinerary.getStops("rouen", lineId);
        const uniqueStops = data.filter(
          (el, index, self) =>
            self.findIndex((t) => t.name === el.name) === index
        );
        dispatch({ type: "SET_STOPS", busStops: uniqueStops });
      },
    }),
    []
  );

  useEffect(() => {
    const init = async () => {
      const data = await itinerary.getLines();
      functions.setLines(data);
    };

    if (authState.userToken && authState.userData?.id && !state.lines.length) {
      init();
    }
  }, [authState.userToken]);

  return (
    <BusLines.Provider
      value={{
        lines: state.lines,
        disruptions: state.disruptions,
        busStops: state.busStops,
        functions,
      }}
    >
      {children}
    </BusLines.Provider>
  );
};

export const useBusLines = () => useContext(BusLines);
