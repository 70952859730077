import { config } from "../config/default";
import { getToken } from "./utils";

const api = {
  login: async (body) => {
    try {
      const res = await fetch(`${config.apiUrl}/auth/local`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  signup: async (body) => {
    try {
      const res = await fetch(`${config.apiUrl}/auth/local/register`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  get: async (resource, oneResourceId = false, query, isPublic = false) => {
    let headers = {
      "Content-Type": "application/json",
    };
    if (!isPublic) {
      headers["Authorization"] = "Bearer " + (await getToken());
    }

    try {
      const res = await fetch(
        `${config.apiUrl}/${resource}${
          oneResourceId ? "/" + oneResourceId : ""
        }${query ? query : ""}`,
        {
          method: "GET",
          headers: { ...headers },
        }
      );
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  create: async (resource, body, isPublic = false) => {
    let headers = {
      "Content-Type": "application/json",
    };
    if (!isPublic) {
      headers["Authorization"] = "Bearer " + (await getToken());
    }

    try {
      const res = await fetch(`${config.apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { ...headers },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  update: async (resource, oneResourceId, body) => {
    try {
      const res = await fetch(
        `${config.apiUrl}/${resource}${
          oneResourceId ? "/" + oneResourceId : ""
        }`,
        {
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await getToken()),
          },
        }
      );
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  searchPlace: async (input) => {
    if (input) {
      try {
        const res = await fetch(`${config.apiUrl}/places`, {
          method: "POST",
          body: JSON.stringify({ name: input }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await getToken()),
          },
        });
        return res && res.ok
          ? await res.json().then((r) => {
              console.log(r);
              return r.results;
            })
          : false;
      } catch (err) {
        console.error(err);
      }
    }
  },
  getDirections: async (from, to) => {
    try {
      // const formattedFrom = `${from.lat}, ${from.long}`;
      // const formattedTo = `${to.lat}, ${to.long}`;
      const res = await fetch(`${config.apiUrl}/directions`, {
        method: "POST",
        body: JSON.stringify({ from, to }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken()),
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  saveSubscription: async (body) => {
    const res = await fetch(
      `${config.apiUrl}/users-permissions/web-subscriptions`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return res && res.ok ? await res.json() : false;
  },
};

export default api;
