import React from "react";
import { Text, View, StatusBar, TouchableOpacity } from "react-native";
import { checkColorForText } from "../services/utils";
import componentStyles from "./styles/modeIconStyles";

export default function BusIcon({ color, acronym }) {
  return (
    <View
      style={[
        componentStyles.modeIconContainer,
        {
          backgroundColor: `#${color}`,
        },
      ]}
    >
      <Text
        style={[
          componentStyles.modeIconText,
          {
            color: checkColorForText(color),
            fontSize: acronym?.length < 3 ? 12 : 6,
          },
        ]}
      >
        {acronym}
      </Text>
    </View>
  );
}
