import React from "react";
import styles from "../../styles/global";
import componentStyles from "../styles/adviceComponentStyles";
import { Linking, Text, View, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

export default function AdviceCostCar({ data }) {
  const navigation = useNavigation();
  return (
    <>
      <View style={{ marginTop: 20 }}>
        <Text style={[styles.lightNormalText, { marginBottom: 20 }]}>
          Vous évaluez vos dépenses liées à votre voiture à {data.estimation}€.
          Au quotidien, on oublie parfois de prendre en compte plusieurs
          dépenses liées à l’usage de celle-ci, au-delà du carburant (assurance,
          amortissement, stationnement, éventuels péages). D’après nos calculs,
          basés sur le baromètre de référence du Ministère de l’Economie et des
          Finances, il semblerait que votre voiture vous coûte en réalité{" "}
          {data.real}€ chaque année.
        </Text>
        <TouchableOpacity
          style={componentStyles.linkContainer}
          onPress={() => navigation.navigate("Settings", { to: "about" })}
        >
          <Text style={styles.textBold}>Je veux comprendre le calcul</Text>
          <Text>👉</Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
