import React, { useEffect, useState } from "react";
import styles from "../styles/global";
import componentStyles from "./styles/coachAdviceCTAStyles";
import { Text, View } from "react-native";
import { modes } from "../config/modes";
import colors from "../styles/variables";
import { TouchableOpacity } from "react-native-gesture-handler";
import { FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

const CONTENT = {
  ELECTRIC_CAR:
    "Vous pouvez bénéficier d’aides pour l’achat d’un véhicule moins polluant 🚗 !",
  EBIKE:
    "Vous pouvez bénéficier d’aides pour l’achat d’un vélo électrique 🚴 !",
  COVOIT:
    "Et si on vous aidait à trouver l’offre de covoiturage qui vous correspond 🙋 ?",
  PUBTRANS:
    "Vous pouvez acheter vos tickets dès maintenant par voie dématérialisée 🚎 !",
};

export default function CoachAdviceCTA({
  isBanner = false,
  setShowBanner,
  goalMode,
  motivations,
  profile,
  refresh,
}) {
  const navigation = useNavigation();
  const [content, setContent] = useState(null);
  useEffect(() => {
    console.log("Updating banner CTA", refresh, goalMode);
    if (
      goalMode === "EBIKE" ||
      (motivations?.length &&
        (motivations[0] === "HEALTH" || motivations[0] === "CO2"))
    ) {
      setContent({
        key: "EBIKE",
        value: CONTENT.EBIKE,
      });
    } else if (goalMode === "PUBTRANS") {
      setContent({
        key: "PUBTRANS",
        value: CONTENT.PUBTRANS,
      });
    } else if (goalMode === "COVOIT") {
      setContent({
        key: "COVOIT",
        value: CONTENT.COVOIT,
      });
    } else {
      switch (profile) {
        case "accompagnateur":
          setContent({
            key: "ELECTRIC_CAR",
            value: CONTENT.ELECTRIC_CAR,
          });
          break;
        case "conservateur":
          setContent({
            key: "ELECTRIC_CAR",
            value: CONTENT.ELECTRIC_CAR,
          });
          break;
        default:
          setContent(null);
          break;
      }
    }
  }, [goalMode, motivations, profile, refresh]);
  if (isBanner && content) {
    return (
      <View style={componentStyles.headerBannerContainer}>
        <TouchableOpacity
          containerStyle={{ flex: 1, paddingRight: 6 }}
          onPress={() =>
            navigation.navigate("Advice", {
              data: content,
            })
          }
        >
          <Text
            style={[
              styles.lightNormalText,
              {
                fontSize: 14,
              },
            ]}
          >
            Psst 🤫 {content?.value}{" "}
            <Text
              style={{
                textDecorationLine: "underline",
              }}
            >
              Cliquer ici pour en savoir plus
            </Text>
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={componentStyles.bannerCloseContainer}
          onPress={() => setShowBanner(false)}
        >
          <FontAwesome name="close" size={14} color="white" />
        </TouchableOpacity>
      </View>
    );
  }
  return content ? (
    <TouchableOpacity
      style={{
        backgroundColor: colors.pink,
        paddingHorizontal: 20,
        paddingVertical: 10,
      }}
      onPress={() =>
        navigation.navigate("Advice", {
          data: content,
        })
      }
    >
      <Text
        style={{
          fontSize: 12,
          color: colors.primary,
          fontFamily: "Raleway_700Bold",
        }}
      >
        Psst 🤫 {content?.value}{" "}
        <Text
          style={{
            textDecorationLine: "underline",
          }}
        >
          Cliquer ici pour en savoir plus
        </Text>
      </Text>
    </TouchableOpacity>
  ) : null;
}
