import React, {
  useReducer,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import api from "../services/api";
import { useAuth } from "./authContext";

const Dashboard = React.createContext();

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DASHBOARD_DATA":
      return Object.assign({}, state, {
        ...state,
        dashboardData: action.dashboardData,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const DashboardProvider = ({ children }) => {
  const authState = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [update, setUpdate] = useState(false);

  const functions = useMemo(
    () => ({
      setDashboard: async (data) =>
        dispatch({ type: "SET_DASHBOARD_DATA", dashboardData: data }),
      refreshDashboard: () => {
        console.log("Refresh dashboard data...");
        setUpdate(true);
      },
    }),
    []
  );

  useEffect(() => {
    const init = async () => {
      const userId = authState.userData.id;
      console.log("Init dashboard data", userId, update);
      const d = await api.get("dashboard-data", `?user_eq=${userId}`);
      // console.log("API returned...", d);
      if (d.length) {
        functions.setDashboard({ ...d[0] });
      }
      setUpdate(false);
    };

    if (
      authState.userData?.id &&
      ((authState.userToken && !state.dashboardData) ||
        !authState.userData?.hasError ||
        update)
    ) {
      init();
    }
  }, [authState.userToken, authState.userData, update]);

  return (
    <Dashboard.Provider value={{ dashboard: state.dashboardData, functions }}>
      {children}
    </Dashboard.Provider>
  );
};

export const useDashboard = () => useContext(Dashboard);
