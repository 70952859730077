import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
    position: "relative",
  },
  title: {
    fontFamily: "Raleway_700Bold",
  },
  welcome: {
    fontSize: 20,
  },
  headerContainer: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 20,
    backgroundColor: "white",
    borderBottomColor: colors.verylightgray,
    borderBottomWidth: 1,
    flexDirection: "row",
  },
  headerTitle: {
    fontSize: 20,
    fontFamily: "Raleway_700Bold",
    color: colors.primary,
  },
  headerIconWrapper: {
    marginLeft: "auto",
    borderRadius: 33,
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primary,
  },
  arrowBack: {
    color: colors.primary,
    marginRight: 10,
  },
  buttonContainer: {
    margin: 7,
    padding: 16,
    borderRadius: 5,
    backgroundColor: colors.primary,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  buttonLightContainer: {
    margin: 7,
    padding: 16,
    borderRadius: 5,
    backgroundColor: colors.secondary,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  buttonPinkContainer: {
    marginVertical: 7,
    padding: 16,
    borderRadius: 5,
    backgroundColor: colors.pink,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  buttonTransparentContainer: {
    margin: 7,
    padding: 8,
    alignItems: "center",
  },
  buttonTransparentText: {
    color: "white",
    fontFamily: "Raleway_600SemiBold",
  },
  buttonText: {
    color: "white",
    fontFamily: "Raleway_700Bold",
  },
  lightNormalText: {
    fontSize: 12,
    fontFamily: "Raleway_400Regular",
    color: "white",
    flexWrap: "wrap",
  },
  lightTitle: {
    fontSize: 18,
    color: "white",
    fontFamily: "Raleway_700Bold",
  },
  normalText: {
    fontSize: 14,
    fontFamily: "Raleway_400Regular",
    color: colors.primary,
    flexWrap: "wrap",
  },
  textBold: {
    fontSize: 14,
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
  },
  formLabel: {
    fontFamily: "Raleway_700Bold",
    marginBottom: 4,
    color: colors.primary,
  },
  whiteFormLabel: {
    fontFamily: "Raleway_700Bold",
    marginBottom: 4,
    color: "white",
  },
  textInput: {
    fontSize: 16,
    backgroundColor: "white",
    borderRadius: 33,
    color: colors.primary,
    padding: 10,
    marginBottom: 16,
    fontFamily: "Raleway_400Regular",
  },

  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
});
