import React from "react";
import styles from "../../styles/global";
import componentStyles from "../styles/adviceComponentStyles";
import { Linking, Text, View, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

const LINKS = {
  "Roulez Malin": "https://www.roulezmalin.com/communes/covoiturages/rouen",
  "Trajet à la carte": "https://www.trajetalacarte.com/covoiturage/rouen",
  "La roue verte":
    "http://www.covoiturage-travail.fr/point-d-interet/17563-rouen.html",
  "Covoiturage 76": "http://www.covoiturage76.net/",
};

export default function AdviceCovoit(props) {
  return (
    <>
      <View style={{ marginTop: 20 }}>
        <Text style={[styles.lightNormalText, { marginBottom: 20 }]}>
          Plusieurs plateformes recensent des trajets réguliers en covoiturage
          entre vos lieux de domicile et de travail. Trouvez l’offre qui vous
          correspond le mieux, ou proposez la vôtre :
        </Text>
        {Object.entries(LINKS).map(([key, value], index) => {
          return (
            <TouchableOpacity
              style={componentStyles.linkContainer}
              key={index}
              onPress={async () => await Linking.openURL(value)}
            >
              <Text style={styles.textBold}>{key}</Text>
              <Text>👉</Text>
            </TouchableOpacity>
          );
        })}
        <Text style={[styles.lightNormalText, { marginTop: 20 }]}>
          Votre entreprise a peut-être déjà mis en place une offre de
          covoiturage entre salariés. Rendez-vous sur la page entreprise de
          cette application pour le savoir ! Si ce n’est pas encore le cas, vous
          pouvez aussi devenir acteur/actrice de votre Plan de Déplacement
          Entreprise, et proposer à vos collègues/votre entreprise de s’inscrire
          à un service comme Klaxit :{" "}
        </Text>
        <TouchableOpacity
          style={componentStyles.linkContainer}
          onPress={async () =>
            await Linking.openURL("https://www.klaxit.com/offre-entreprise")
          }
        >
          <Text style={styles.textBold}>Offre entreprise Klaxit</Text>
          <Text>👉</Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
