import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  modeIconContainer: {
    width: 30,
    height: 30,
    borderRadius: 5,
    margin: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  modeIconText: {
    fontFamily: "Raleway_700Bold",
  },
  lineName: {
    flex: 1,
    flexWrap: "wrap",
    marginLeft: 4,
    fontSize: 12,
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
  },
  durationStep: {
    fontSize: 10,
    fontFamily: "Raleway_400Regular",
  },
});
