import * as React from "react";
import styles from "../styles/global";
import { Text, View, ActivityIndicator } from "react-native";
import HelmetMeta from "./Helmet/Meta";

export default function SplashScreen({ message }) {
  return (
    <View style={styles.container}>
      <HelmetMeta />
      {message ? (
        <Text
          style={{
            color: "white",
            marginBottom: 10,
            padding: 20,
            textAlign: "center",
          }}
        >
          {message}
        </Text>
      ) : null}
      <ActivityIndicator size={"large"} color={"white"} />
    </View>
  );
}
