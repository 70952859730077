import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Alert,
  ActivityIndicator,
} from "react-native";
import styles from "../../styles/global";
import screenStyles from "../../styles/questionnaireStyles";
import Header from "../../components/Header";
import api from "../../services/api";
import OneQuestion from "../../components/OneQuestion";
import SignUpForm from "../../components/SignUpForm";
import {
  useNavigation,
  NavigationHelpersContext,
} from "@react-navigation/native";
import withAuth from "../../hoc/withAuth";
import SplashScreen from "../SplashScreen";
import itinerary from "../../services/itinerary";
import HelmetMeta from "../Helmet/Meta";
import { modes } from "../../config/modes";
import { useGoal } from "../../context/goalContext";

function SurveyQuestionnaireScreen(props) {
  const { userData, refreshUserData } = props;

  const navigation = useNavigation();

  const { goal } = useGoal();
  const { type } = props.route.params;

  const [messageLoading, setMessageLoading] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(null);
  const [progress, setProgress] = useState(0);

  const [shouldUpdateDurations, setShouldUpdateDurations] = useState(false);

  useEffect(() => {
    (async () => {
      setQuestions(await api.get("questions", null, `?${type}=true`, true));
      // setGoal(
      //   await api.get(
      //     "user-goals",
      //     `?user_eq=${userData.id}&_sort=created_at:DESC&_limit=1`
      //   )
      // );
    })();
  }, []);

  useEffect(() => {
    if (questions && questions.length) {
      setAnswers(
        questions.reduce((obj, item) => {
          obj[item.label] = "";
          return obj;
        }, {})
      );
      setLoading(false);
    }
  }, [questions]);

  const checkShouldUpdateDurations = (key) => {
    if (
      key === "address_home" ||
      key === "address_work" ||
      key === "hour_start_work" ||
      key === "hour_end_work"
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const fetchDurations = async () => {
      console.log("checking again", answers.hour_start_work);
      const res = await itinerary.getDurations(
        "rouen",
        {
          lat: answers.address_home.geometry.location.lat,
          long: answers.address_home.geometry.location.lng,
        },
        {
          lat: answers.address_work.geometry.location.lat,
          long: answers.address_work.geometry.location.lng,
        },
        answers.hour_start_work || null,
        answers.hour_end_work || null
      );
      setLoading(false);
      setMessageLoading(null);

      if (res) {
        setAnswers({
          ...answers,
          duration_dt: Math.round(res.durations["CAR"] / 60) || 0,
          distance_dt: Math.round(res.distances["CAR"] / 1000) || 0,
        });
      }
    };

    if (answers) {
      setProgress(
        Math.round(
          (Object.keys(answers).filter((el) => answers[el] !== "").length /
            Object.keys(answers).length) *
            100
        )
      );
    }

    if (
      answers &&
      answers.address_home &&
      answers.address_work &&
      answers.hour_start_work &&
      answers.hour_end_work &&
      (!answers.duration_dt || shouldUpdateDurations)
    ) {
      setShouldUpdateDurations(false);
      setLoading(true);
      setMessageLoading(
        "Un peu de patience... Nous calculons la distance et la durée de transport entre votre domicile et votre lieu de travail."
      );
      fetchDurations();
    }
  }, [answers, shouldUpdateDurations]);

  const handleConfirm = async () => {
    if (
      !(
        answers.changed_mode.length &&
        answers.frequence_dt_vp &&
        answers.changed_sit_perso.length &&
        answers.correct_affirmations.length
      ) ||
      (answers.changed_mode.length &&
        answers.changed_mode.filter((el) => el.includes("plus la même voiture"))
          .length &&
        (answers.motorisation_vp === "" || answers.cv_vp === "")) ||
      (answers.changed_sit_perso.length &&
        answers.changed_sit_perso.filter((el) => el.includes("lieu")).length &&
        (answers.address_home === "" ||
          answers.address_work === "" ||
          answers.distance_dt === "" ||
          answers.duration_dt === "")) ||
      (answers.changed_sit_perso.length &&
        answers.changed_sit_perso.filter((el) => el.includes("jours")).length &&
        (answers.frequence_dt_vp === "" || answers.frequence_dt_all === ""))
    ) {
      Alert.alert("Erreur", "Veuillez remplir tous les champs");
    } else {
      setLoading(true);
      await api.create("answers", {
        data: questions.reduce((obj, item) => {
          obj[item.label] = {
            question: {
              text: item.question,
              type: item.type,
              unit: item.unit,
            },
            answer: answers[item.label],
          };
          return obj;
        }, {}),
        user: userData.id,
        type: type === "show_after_one_month" ? "afterOneMonth" : "light",
      });
      console.log("Survey answers saved");
      refreshUserData();

      setTimeout(() => {
        return navigation.navigate("Dashboard", {
          update: true,
        });
      }, 1200);
    }
  };

  return (
    <>
      <HelmetMeta />
      <Header isOnboarding={true} title={"Je remplis le questionnaire"} />
      <View style={screenStyles.progressBar}>
        <View
          style={[
            screenStyles.activeProgress,
            {
              width: `${progress}%`,
            },
          ]}
        />
      </View>
      {loading ? (
        <SplashScreen message={messageLoading} />
      ) : (
        <ScrollView style={screenStyles.container}>
          {questions
            .filter((el) => el.order !== null)
            .filter((el) =>
              answers.changed_sit_perso &&
              answers.changed_sit_perso.length &&
              answers.changed_sit_perso.find((el) => el.includes("lieu"))
                ? el
                : !el.label.includes("address") &&
                  !el.label.includes("duration") &&
                  !el.label.includes("distance")
            )
            .filter((el) =>
              answers.changed_sit_perso &&
              answers.changed_sit_perso.length &&
              answers.changed_sit_perso.find((el) => el.includes("jours"))
                ? el
                : !el.label.includes("frequence_dt_all") &&
                  !el.label.includes("hour_")
            )
            .filter((el) =>
              answers.changed_mode &&
              answers.changed_mode.length &&
              answers.changed_mode.find((el) => el.includes("acquisition"))
                ? el
                : !el.label.includes("new_bike")
            )
            .filter((el) =>
              answers.changed_mode &&
              answers.changed_mode.length &&
              answers.changed_mode.find((el) =>
                el.includes("plus la même voiture")
              )
                ? el
                : !el.label.includes("cv") &&
                  !el.label.includes("motorisation") &&
                  !el.label.includes("year")
            )
            .filter((el) =>
              (answers.frequence_dt_covoit &&
                answers.frequence_dt_covoit !== 0) ||
              (answers.frequence_dt_velo && answers.frequence_dt_velo !== 0) ||
              (answers.frequence_dt_marche &&
                answers.frequence_dt_marche !== 0) ||
              (answers.frequence_dt_pubtrans &&
                answers.frequence_dt_pubtrans !== 0) ||
              (answers.frequence_dt_moto && answers.frequence_dt_moto !== 0) ||
              (answers.frequence_dt_vae && answers.frequence_dt_vae !== 0)
                ? el
                : !el.label.includes("reasons_change")
            )
            .filter((el) =>
              answers.frequence_dt_covoit && answers.frequence_dt_covoit !== ""
                ? el
                : !el.label.includes("mode_covoit")
            )
            .filter((el) =>
              [
                (answers.frequence_dt_covoit &&
                  answers.frequence_dt_covoit < 2) ||
                  (answers.frequence_dt_velo &&
                    answers.frequence_dt_velo < 2) ||
                  (answers.frequence_dt_marche &&
                    answers.frequence_dt_marche < 2) ||
                  (answers.frequence_dt_pubtrans &&
                    answers.frequence_dt_pubtrans < 2) ||
                  (answers.frequence_dt_moto &&
                    answers.frequence_dt_moto < 2) ||
                  (answers.frequence_dt_vae && answers.frequence_dt_vae < 2),
              ] && type !== "show_after_one_month"
                ? el
                : el.label !== "reasons_no_change"
            )
            .filter((el) =>
              type === "show_after_one_month" && userData.phase === "ACTION"
                ? el
                : el.label !== "reasons_no_change_after_one_month"
            )
            .filter((el) =>
              answers.pre_frequence_dt_covoit !== "" &&
              answers.pre_frequence_dt_covoit?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_covoit")
            )
            .filter((el) =>
              answers.pre_frequence_dt_pubtrans !== "" &&
              answers.pre_frequence_dt_pubtrans?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_pubtrans")
            )
            .filter((el) =>
              answers.pre_frequence_dt_velo !== "" &&
              answers.pre_frequence_dt_velo?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_velo")
            )
            .filter((el) =>
              answers.pre_frequence_dt_vae !== "" &&
              answers.pre_frequence_dt_vae?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_vae")
            )
            .filter((el) =>
              answers.pre_frequence_dt_marche !== "" &&
              answers.pre_frequence_dt_marche?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_marche")
            )
            .filter((el) =>
              answers.pre_frequence_dt_moto !== "" &&
              answers.pre_frequence_dt_moto?.includes("Au moins")
                ? el
                : !(el.label === "frequence_dt_moto")
            )
            .sort((a, b) => a.order - b.order)
            .map((qu, index) => {
              return (
                <OneQuestion
                  key={index}
                  index={index}
                  question={
                    qu.label === "reasons_no_change_after_one_month"
                      ? {
                          ...qu,
                          question: qu.question.replace(
                            "${goalMode}",
                            modes[goal?.mode || "PUBTRANS"]
                          ),
                        }
                      : qu
                  }
                  answers={answers}
                  handleChange={(key, value) => {
                    setAnswers({ ...answers, [key]: value });
                    if (checkShouldUpdateDurations(key)) {
                      setShouldUpdateDurations(true);
                    }
                  }}
                />
              );
            })}

          <TouchableOpacity
            style={[
              styles.buttonLightContainer,
              { marginTop: 20, marginBottom: 60 },
            ]}
            onPress={() => handleConfirm()}
          >
            <Text style={styles.buttonText}>C'est (re)parti !</Text>
          </TouchableOpacity>
        </ScrollView>
      )}
    </>
  );
}

export default withAuth(SurveyQuestionnaireScreen);
