import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  linkContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.verylightgray,
    padding: 10,
    marginVertical: 4,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
});
