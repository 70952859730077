import { useIsFocused, useNavigation } from "@react-navigation/native";
import React, { useEffect, useReducer, useMemo, useState } from "react";
import ModalNotifications from "../components/ModalNotifications";
import api from "../services/api";

function withModal(WrappedComponent) {
  function Enhancer(props) {
    const { userData } = props;

    const navigation = useNavigation();

    const [refresh, setRefresh] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
      const fetchNotifications = async () => {
        setNotifications(
          await api.get(`user-notifications?user_eq=${userData.id}&seen=false`)
        );
      };
      if (userData && userData.id) {
        fetchNotifications();
      }
    }, [userData]);

    useEffect(() => {
      setRefresh(false);
      if (notifications.length) {
        setOpenModal(true);
      }
    }, [notifications, refresh]);

    const markNotificationAsSeen = async (notif) => {
      let newNotifications = [...notifications];
      newNotifications.splice(
        notifications.findIndex((el) => el.id === notif.id),
        1
      );
      setNotifications(newNotifications);
      await api.update(`user-notifications`, notif.id, { seen: true });

      let redirect;

      if (
        notif.notification_id.type === "FEEDBACK" ||
        notif.notification_id.type === "PERSONALIZED_ADVICE"
      ) {
        setOpenModal(false);
        redirect = {
          screen: "Coach",
          params: {},
        };
      } else {
        switch (notif.notification_id.key) {
          case "ANSWER_LIGHT_QUESTIONNAIRE":
            setOpenModal(false);
            redirect = {
              screen: "Dashboard",
              params: {
                screen: "Survey",
                params: {
                  screen: "SimplifiedSurveyIntroduction",
                },
              },
            };
            break;
          case "OBJECTIF_REMINDER":
            setOpenModal(false);
            redirect = {
              screen: "Itinerary",
              params: {
                show_notifs: false,
              },
            };
            break;
          case "CONGRATULATIONS_PHYSICAL_ACTIVITY":
            setOpenModal(false);
            redirect = {
              screen: "Dashboard",
              params: {},
            };
            break;
          default:
            break;
        }
      }

      console.log("Redirect", redirect);
      if (redirect) {
        return navigation.navigate(redirect.screen, redirect.params);
      }

      if (!newNotifications.length) {
        setOpenModal(false);
      }
    };

    const markAllNotificationAsSeen = async () => {
      await Promise.all(
        notifications.map(
          async (notif) =>
            await api.update(`user-notifications`, notif.id, {
              seen: true,
            })
        )
      );
      setNotifications([]);
      setOpenModal(false);
    };

    return (
      <>
        <WrappedComponent
          {...props}
          refreshNotificationsOnFocus={() => setRefresh(true)}
        />
        <ModalNotifications
          userId={userData && userData.id}
          notifications={notifications}
          markNotificationAsSeen={markNotificationAsSeen}
          markAllNotificationAsSeen={markAllNotificationAsSeen}
          open={openModal}
          handleClose={() => setOpenModal(!openModal)}
        />
      </>
    );
  }
  return Enhancer;
}

export default withModal;
