import React, { useEffect, useRef, useState } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import ItineraryScreen from "../screens/ItineraryScreen";
import CoachScreen from "../screens/CoachScreen";
import DashboardScreen from "../screens/DashboardScreen";
import QuestionnaireScreen from "../screens/QuestionnaireScreen";
import styles from "../styles/navigationStyles";
import colors from "../styles/variables";
import { AntDesign } from "@expo/vector-icons";
import { SimpleLineIcons } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import AuthScreen from "../screens/AuthScreen";
import withAuth from "../hoc/withAuth";
import SplashScreen from "../screens/SplashScreen";
import SearchItineraryScreen from "../screens/ItineraryStack/SearchItineraryScreen";
import { Text, View, Image } from "react-native";
import SettingsAddressesScreen from "../screens/ItineraryStack/SettingsAddressesScreen";
import ItineraryStepsScreen from "../screens/ItineraryStack/ItineraryStepsScreen";
import InformationScreen from "../screens/InformationScreen";
import SettingsScreen from "../screens/SettingsScreen";
import IntroductionSurveyScreen from "../screens/SurveyStack/Introduction";
import SurveyQuestionnaireScreen from "../screens/SurveyStack/SurveyQuestionnaire";
import IntroductionSimplifiedSurveyScreen from "../screens/SurveyStack/IntroductionSimplified";
import TutorialScreen from "../screens/TutorialScreen";
import AdviceScreen from "../screens/AdviceScreen";

const TutorialStack = createStackNavigator();
const AuthStack = createStackNavigator();
const SurveyStack = createStackNavigator();
const RootStack = createStackNavigator();
const DashboardStack = createStackNavigator();
const CoachStack = createStackNavigator();
const Tab = createBottomTabNavigator();

function MainNavigator(props) {
  const { authState } = props;

  if (authState.isLoading) {
    // We haven't finished checking for the token yet
    return <SplashScreen />;
  }

  function Itinerary() {
    return authState.userData && authState.userData.survey_required ? (
      <RootStack.Navigator
        initialRouteName={"Survey"}
        screenOptions={{
          headerBackTitle: "Retour",
        }}
      >
        <RootStack.Screen
          name="Survey"
          component={Survey}
          options={{
            headerShown: false,
          }}
        />
      </RootStack.Navigator>
    ) : (
      <RootStack.Navigator
        initialRouteName={"Itinerary"}
        screenOptions={{
          headerBackTitle: "Retour",
        }}
      >
        <RootStack.Screen
          name="Itinerary"
          component={ItineraryScreen}
          initialParams={props}
          options={{
            headerLeft: null,
            headerRight: null,
            headerTransparent: true,
            headerTitle: null,
            // headerTitle: ({ style }) => (
            //   <View style={styles.headerTitleTopLogo}>
            //     <Image
            //       source={iconYellow}
            //       style={{
            //         resizeMode: "contain",
            //         width: 40,
            //         height: 40,
            //       }}
            //     />
            //   </View>
            // ),
          }}
        />
        <RootStack.Screen
          name="SearchItinerary"
          component={SearchItineraryScreen}
          options={{
            headerTitle: () => (
              <Text style={styles.headerTitleText}>Votre itinéraire</Text>
            ),
          }}
        />
        <RootStack.Screen
          name="ItinerarySteps"
          component={ItineraryStepsScreen}
          options={{
            headerTitle: () => (
              <Text style={styles.headerTitleText}>C'est parti !</Text>
            ),
          }}
        />
        <RootStack.Screen
          name="SettingsAddresses"
          component={SettingsAddressesScreen}
          initialParams={props}
          options={{
            headerTitle: () => (
              <Text style={styles.headerTitleText}>Gérer vos adresses</Text>
            ),
          }}
        />
        <RootStack.Screen
          name="Information"
          component={InformationScreen}
          options={({ route }) => {
            return {
              headerTitle: () => {
                return (
                  <Text style={styles.headerTitleText}>
                    A propos de {route.params.data.name}
                  </Text>
                );
              },
            };
          }}
        />
        <RootStack.Screen
          name="Advice"
          component={AdviceScreen}
          options={({ route }) => {
            return {
              headerTitle: () => {
                return (
                  <Text style={styles.headerTitleText}>
                    Un conseil pour vous !
                  </Text>
                );
              },
            };
          }}
        />
      </RootStack.Navigator>
    );
  }

  function Dashboard() {
    return authState.userData && authState.userData.survey_required ? (
      <DashboardStack.Navigator
        initialRouteName={"Survey"}
        screenOptions={{
          headerBackTitle: "Retour",
        }}
      >
        <DashboardStack.Screen
          name="Survey"
          component={Survey}
          options={{
            headerShown: false,
          }}
        />
      </DashboardStack.Navigator>
    ) : (
      <DashboardStack.Navigator
        initialRouteName={"Dashboard"}
        screenOptions={{
          headerBackTitle: "Retour",
        }}
      >
        <DashboardStack.Screen
          name="Dashboard"
          component={DashboardScreen}
          initialParams={props}
          options={{
            headerShown: false,
          }}
        />
        <DashboardStack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{
            headerShown: false,
          }}
        />
        <DashboardStack.Screen
          name="Survey"
          component={Survey}
          options={{
            headerShown: false,
          }}
        />
      </DashboardStack.Navigator>
    );
  }

  function Coach() {
    return authState.userData && authState.userData.survey_required ? (
      <CoachStack.Navigator
        initialRouteName={"Survey"}
        screenOptions={{
          headerBackTitle: "Retour",
        }}
      >
        <CoachStack.Screen
          name="Survey"
          component={Survey}
          options={{
            headerShown: false,
          }}
        />
      </CoachStack.Navigator>
    ) : (
      <CoachStack.Navigator
        initialRouteName={"Coach"}
        screenOptions={{
          headerBackTitle: "Retour",
        }}
      >
        <CoachStack.Screen
          name="Coach"
          component={CoachScreen}
          initialParams={props}
          options={{
            headerShown: false,
          }}
        />
        <CoachStack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{
            headerShown: false,
          }}
        />
        <CoachStack.Screen
          name="Advice"
          component={AdviceScreen}
          options={({ route }) => {
            return {
              headerTitle: () => {
                return (
                  <Text style={styles.headerTitleText}>
                    Un conseil pour vous !
                  </Text>
                );
              },
            };
          }}
        />
      </CoachStack.Navigator>
    );
  }

  function Survey() {
    return (
      <SurveyStack.Navigator
        initialRouteName={"SurveyIntroduction"}
        screenOptions={{
          headerShown: false,
          headerBackTitle: "Retour",
        }}
      >
        <SurveyStack.Screen
          name="SimplifiedSurveyIntroduction"
          component={IntroductionSimplifiedSurveyScreen}
          initialParams={{ ...props }}
        />
        <SurveyStack.Screen
          name="SurveyIntroduction"
          component={IntroductionSurveyScreen}
          initialParams={{ ...props }}
        />
        <SurveyStack.Screen
          name="SurveyQuestionnaire"
          component={SurveyQuestionnaireScreen}
          initialParams={{ ...props }}
        />
      </SurveyStack.Navigator>
    );
  }

  // if (authState.userData && authState.userData.survey_required) {
  //   return Survey();
  // }

  return authState.userToken &&
    !authState.userData?.hasError &&
    authState.firstTime === "true" ? (
    <TutorialStack.Navigator
      screenOptions={{
        headerShown: false,
        headerBackTitle: "Retour",
      }}
    >
      <TutorialStack.Screen
        name="Tutorial"
        component={TutorialScreen}
        initialParams={{ ...props }}
      />
    </TutorialStack.Navigator>
  ) : authState.userToken && !authState.userData?.hasError ? (
    <Tab.Navigator
      initialRouteName={
        authState.userData && authState.userData.survey_required
          ? "Dashboard"
          : "Itinerary"
      }
      tabBarOptions={{
        labelStyle: styles.labelStyle,
        inactiveTintColor: colors.primary,
        activeTintColor: "white",
        activeBackgroundColor: colors.secondary,
        tabStyle: styles.tabStyle,
      }}
    >
      <Tab.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          tabBarLabel: "Dashboard",
          tabBarIcon: ({ color, size }) => (
            <AntDesign
              name="dashboard"
              size={color === "white" ? size : size * 0.8}
              color={color}
            />
          ),
        }}
      />
      <Tab.Screen
        name="Itinerary"
        component={Itinerary}
        options={{
          tabBarLabel: "Itinéraire",
          tabBarIcon: ({ color, size }) => (
            <SimpleLineIcons
              name="directions"
              size={color === "white" ? size : size * 0.8}
              color={color}
            />
          ),
        }}
      />
      <Tab.Screen
        name="Coach"
        component={Coach}
        options={{
          tabBarLabel: "Coach",
          tabBarIcon: ({ color, size }) => (
            <FontAwesome
              name="group"
              size={color === "white" ? size : size * 0.8}
              color={color}
            />
          ),
        }}
      />
    </Tab.Navigator>
  ) : (
    <AuthStack.Navigator
      initialRouteName={"Auth"}
      screenOptions={{
        headerShown: false,
        headerBackTitle: "Retour",
      }}
    >
      <AuthStack.Screen
        name="Auth"
        component={AuthScreen}
        initialParams={{ ...props }}
      />
      <AuthStack.Screen
        name="Questionnaire"
        component={QuestionnaireScreen}
        initialParams={{ ...props }}
      />
    </AuthStack.Navigator>
  );
}

export default withAuth(MainNavigator);
