import React, { useEffect, useRef, useState } from "react";
import { Modal, View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/modalNotificationsStyles";
import * as Notifications from "expo-notifications";
import * as Permissions from "expo-permissions";
import illu from "../../assets/acctiv_info.png";
import OneQuestion from "./OneQuestion";
import api from "../services/api";
import colors from "../styles/variables";
import { useNavigation } from "@react-navigation/native";

export default function ModalInformation(props) {
  const { open, close, content } = props;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <View style={componentStyles.centeredView}>
        <View style={componentStyles.modalView}>
          <Text style={componentStyles.titleText}>{content?.title}</Text>
          <Image
            source={illu}
            style={{
              flex: 1,
              width: "100%",
              resizeMode: "contain",
            }}
          />
          <Text
            style={[
              styles.normalText,
              { paddingBottom: 20, textAlign: "center" },
            ]}
          >
            {content?.text}
          </Text>
          <TouchableOpacity
            style={componentStyles.openButton}
            onPress={() => close()}
          >
            <Text style={componentStyles.textStyle}>OK</Text>
          </TouchableOpacity>
        </View>
      </View>
    </div>
  );
}
