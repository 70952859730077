import React from "react";
import styles from "../../styles/global";
import componentStyles from "../styles/adviceComponentStyles";
import { Linking, Text, View, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

const LINKS = {
  "Roulez Malin": "https://www.roulezmalin.com/communes/covoiturages/rouen",
  "Trajet à la carte": "https://www.trajetalacarte.com/covoiturage/rouen",
  "La roue verte":
    "http://www.covoiturage-travail.fr/point-d-interet/17563-rouen.html",
  "Covoiturage 76": "http://www.covoiturage76.net/",
};

export default function AdviceEBike(props) {
  return (
    <>
      <View style={{ marginTop: 20 }}>
        <Text style={[styles.lightNormalText, { marginBottom: 20 }]}>
          Avec les économies réalisées en diminuant l’usage de la voiture, vous
          pourriez faire l’acquisition d’un vélo électrique !
        </Text>
        <Text style={[styles.lightNormalText, { marginVertical: 10 }]}>
          Depuis le déconfinement, La Métropole Rouen Normandie octroie une aide
          à l’achat de vélos à assistance électrique, vélos cargos et vélos
          pliants pouvant aller jusqu’à 30% de la valeur du vélo dans la limite
          de 300 euros !
        </Text>

        <TouchableOpacity
          style={componentStyles.linkContainer}
          onPress={async () =>
            await Linking.openURL(
              "https://www.metropole-rouen-normandie.fr/sites/default/files/documents/velo/Reglement_aide_velo%202019_web.pdf"
            )
          }
        >
          <Text style={styles.textBold}>
            En savoir plus sur l'aide de Rouen
          </Text>
          <Text>👉</Text>
        </TouchableOpacity>
        <Text style={[styles.lightNormalText, { marginVertical: 10 }]}>
          De plus, vous pouvez cumuler cette aide avec le « Bonus vélo » octroyé
          par l’Etat, dans la limite de 200 euros.
        </Text>
        <TouchableOpacity
          style={componentStyles.linkContainer}
          onPress={async () =>
            await Linking.openURL(
              "https://www.economie.gouv.fr/particuliers/prime-velo-electrique"
            )
          }
        >
          <Text style={styles.textBold}>
            En savoir plus sur la prime de l'Etat
          </Text>
          <Text>👉</Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
