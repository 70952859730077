import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Dimensions } from "react-native";
import styles from "../styles/global";
import screenStyles from "../styles/dashboardStyles";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import Header from "../components/Header";
import colors from "../styles/variables";
import Goal from "../components/Goal";
import api from "../services/api";
import SplashScreen from "./SplashScreen";
import { useGoal } from "../context/goalContext";
import CoachTab from "../components/CoachTab";
import HelmetMeta from "./Helmet/Meta";
import { useCoach } from "../context/coachContext";

const TabBudget = (props) => (
  <CoachTab {...props} type={"budget"} unit={"€ par an"} />
);

const TabDuration = (props) => (
  <CoachTab {...props} type={"duration"} unit={"min par trajet"} />
);

const TabEmission = (props) => (
  <CoachTab {...props} type={"emission"} unit={"kg de CO2 par an"} />
);

const initialLayout = { width: Dimensions.get("window").width };

function CoachScreen(props) {
  const { goal, functions } = useGoal();
  const { coach } = useCoach();
  const [coachData, setCoachData] = useState(null);

  useEffect(() => {
    if (coach.length) {
      setCoachData({
        budget: coach.map((el) => {
          return {
            mode: el.mode,
            value: el.mode_budget_value,
            value_2: el.mode_budget_value_2,
            frequency: el.frequency,
          };
        }),
        duration: coach.map((el) => {
          return {
            mode: el.mode,
            value: el.mode_duration_value,
            frequency: el.frequency,
          };
        }),
        emission: coach.map((el) => {
          return {
            mode: el.mode,
            value: el.mode_emission_value,
            frequency: el.frequency,
          };
        }),
      });
    }
  }, [coach]);

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "budget", title: "Budget" },
    { key: "duration", title: "Temps" },
    { key: "emission", title: "CO2" },
  ]);

  const renderScene = SceneMap({
    budget: (props) => TabBudget({ ...props, goal, data: coachData.budget }),
    duration: (props) =>
      TabDuration({ ...props, goal, data: coachData.duration }),
    emission: (props) =>
      TabEmission({ ...props, goal, data: coachData.emission }),
  });

  return coachData === null && goal === null ? (
    <SplashScreen />
  ) : (
    <>
      <HelmetMeta title={"ACCTIV - Dashboard"} />
      <Header title={"Coach"} />
      <View style={screenStyles.coachScene}>
        {coachData ? (
          <TabView
            goal={goal}
            navigationState={{ index, routes }}
            renderTabBar={renderTabBar}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={initialLayout}
          />
        ) : null}
      </View>
      <Goal goal={goal} updateGoal={functions.updateGoal} />
    </>
  );
}

export default CoachScreen;

const renderTabBar = (props) => (
  <TabBar
    {...props}
    indicatorStyle={screenStyles.indicator}
    style={screenStyles.tabBar}
    activeColor={colors.secondary}
    inactiveColor={colors.primary}
  />
);
