import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
} from "react-native";
import { useBusLines } from "../context/busLinesContext";
import styles from "../styles/global";
import BusIcon from "./BusIcon";
import componentStyles from "./styles/modalBusStopsStyles";

export default function ModalShowBusStops({ close }) {
  const { lines, disruptions, functions } = useBusLines();

  const [showDisruptions, setShowDisruptions] = useState(false);

  const handleSelectLine = (id) => {
    functions.setStops(id);
    close();
  };

  const getColor = (criticity) => {
    switch (criticity) {
      case "MEDIUM":
        return "orange";
      case "HIGH":
        return red;
      default:
        return "yellow";
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: "50%",
        right: 0,
        left: 0,
        zIndex: 99999,
      }}
    >
      <View style={componentStyles.centeredView}>
        <View style={componentStyles.modalView}>
          <Text style={componentStyles.titleText}>
            Afficher les arrêts de bus / tramway
          </Text>

          <Text style={styles.normalText}>
            {showDisruptions
              ? "Incidents en cours :"
              : "Veuillez sélectionner une ligne :"}
          </Text>
          <ScrollView style={{ width: "100%", flex: 1 }}>
            {showDisruptions
              ? disruptions?.map((disruption, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={componentStyles.listItemContainer}
                      onPress={async () =>
                        await Linking.openURL(disruption.url)
                      }
                    >
                      <View
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          marginTop: -2,
                          marginRight: -2,
                          width: 15,
                          height: 15,
                          borderRadius: 99,
                          backgroundColor: getColor(disruption.criticity),
                        }}
                      />
                      <View>
                        <Text style={styles.normalText}>
                          {disruption.title}
                        </Text>
                        <Text style={styles.textBold}>Lignes affectées :</Text>
                        <View style={styles.flexRow}>
                          {disruption.lines.map((line, indexLine) => {
                            return (
                              <BusIcon
                                key={indexLine}
                                color={line.color || "D7B43B"}
                                acronym={line.sName}
                              />
                            );
                          })}
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                })
              : lines?.map((line, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={componentStyles.listItemContainer}
                      onPress={() => handleSelectLine(line.id)}
                    >
                      <BusIcon
                        color={line.color || "D7B43B"}
                        acronym={line.sName}
                      />
                      <Text style={componentStyles.listItemText}>
                        {line.lName}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
          </ScrollView>
          <TouchableOpacity
            style={{ padding: 10 }}
            onPress={() => setShowDisruptions(!showDisruptions)}
          >
            <Text style={[styles.normalText, { textAlign: "center" }]}>
              {showDisruptions ? (
                <Text style={{ textDecorationLine: "underline" }}>
                  Retour à la liste des lignes
                </Text>
              ) : (
                <>
                  ⚠️ {disruptions.length} incidents en cours sur le réseau.{" "}
                  <Text style={{ textDecorationLine: "underline" }}>
                    En savoir plus
                  </Text>
                </>
              )}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              componentStyles.openButton,
              {
                marginTop: "auto",
              },
            ]}
            onPress={() => close()}
          >
            <Text style={componentStyles.textStyle}>Fermer</Text>
          </TouchableOpacity>
        </View>
      </View>
    </div>
  );
}
