import * as React from "react";
import { Text, View, Button, StyleSheet, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import colors from "../../styles/variables";

export default function ShowFullScreenButton({
  showFullScreen,
  setShowFullScreen,
}) {
  return (
    <TouchableOpacity
      style={[styles.switchView, { top: showFullScreen ? "92%" : "45%" }]}
      onPress={() => setShowFullScreen(!showFullScreen)}
    >
      <Text style={styles.switchViewText}>
        <FontAwesome
          name={`long-arrow-${showFullScreen ? "up" : "down"}`}
          size={14}
          color="white"
        />
        {"  "}
        {showFullScreen ? "Désactiver" : "Activer"} plein écran
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  switchView: {
    zIndex: 99,
    position: "absolute",
    left: 20,
    marginRight: 10,
    borderRadius: 99,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: `${colors.primary}50`,
  },
  switchViewText: {
    color: "white",
  },
});
