import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import moment from "../services/localeMoment";
import componentStyles from "./styles/barGraphStyles";
import colors from "../styles/variables";
import MicroModalFeedback from "./MicroModalFeedback";

export default function BarGraph(props) {
  const { values, unit, createdAt, phase, phaseVal, h } = props;

  const [currentIsInitial, setCurrentIsInitial] = useState(
    values.initial === values.current
  );
  const [ratios, setRatios] = useState(null);
  const [openFeedback, setOpenFeedback] = useState(false);

  useEffect(() => {
    const { initial, current, goal } = values;
    const max = Math.max(initial || 0, current || 0, goal || 0);
    let A, B, C;
    if (initial === max) {
      A = 100;
      B = values.initial > 0 ? (values.current * 100) / values.initial : 1;
      C =
        values.goal && values.initial > 0
          ? (values.goal * 100) / values.initial
          : 1;
    } else if (current === max) {
      A = values.current > 0 ? (values.initial * 100) / values.current : 1;
      B = 100;
      C =
        values.goal && values.current > 0
          ? (values.goal * 100) / values.current
          : 1;
    } else if (goal === max) {
      A = values.goal > 0 ? (values.initial * 100) / values.goal : 1;
      B = values.goal > 0 ? (values.current * 100) / values.goal : 1;
      C = 100;
    }
    console.log(A, B, C);
    setRatios({
      A,
      B,
      C,
    });
  }, [values, unit]);

  const formatDuration = (d) => {
    const hours = d / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours}h ${rminutes}min par an`;
  };

  const unitsWithEmojis = {
    "min par an": "⏱️",
    "€ par an": "💸",
    "kg de CO2 par an": "🌍",
  };

  return ratios ? (
    <>
      <View style={componentStyles.graphContainer}>
        <View style={componentStyles.oneBarContainer}>
          <View style={componentStyles.labelContainer}>
            <Text style={componentStyles.oneBarLabel}>
              {unit === "min par an"
                ? `${formatDuration(values.initial)} ${unitsWithEmojis[unit]}`
                : `${Math.round(values.initial)} ${unit} ${
                    unitsWithEmojis[unit]
                  }`}
            </Text>
          </View>
          <View
            style={[
              componentStyles.oneBar,
              {
                height: (h * ratios.A) / (2.2 * 100),
                width: Dimensions.get("window").width / 6,
                backgroundColor: colors.secondary,
              },
            ]}
          />
        </View>
        {!currentIsInitial ? (
          <View style={componentStyles.oneBarContainer}>
            <View style={componentStyles.labelContainer}>
              {values.initial > 0 && values.initial !== values.current ? (
                <Text
                  style={[
                    componentStyles.oneBarComparison,
                    {
                      color: values.current < values.initial ? "green" : "red",
                    },
                  ]}
                >
                  {values.current < values.initial ? "- " : "+ "}
                  {Math.round(
                    Math.abs(values.current / values.initial - 1) * 100
                  )}
                  % {values.current < values.initial ? "👍" : "👎"}
                </Text>
              ) : null}
              <Text style={componentStyles.oneBarLabel}>
                {unit === "min par an"
                  ? `${formatDuration(values.current)} ${unitsWithEmojis[unit]}`
                  : `${Math.round(values.current)} ${unit} ${
                      unitsWithEmojis[unit]
                    }`}
              </Text>
            </View>
            <View
              style={[
                componentStyles.oneBar,
                {
                  height: (h * ratios.B) / (2.2 * 100),
                  width: Dimensions.get("window").width / 6,
                  backgroundColor: colors.pink,
                },
              ]}
            />
          </View>
        ) : null}
        {values.goal ? (
          <TouchableOpacity
            style={componentStyles.oneBarContainer}
            onPress={() =>
              setOpenFeedback({
                val: true,
                type: "GOAL_BUDGET",
              })
            }
          >
            <View style={componentStyles.labelContainer}>
              {values.initial > 0 && values.initial !== values.goal ? (
                <Text
                  style={[
                    componentStyles.oneBarComparison,
                    {
                      color: values.goal < values.initial ? "green" : "red",
                    },
                  ]}
                >
                  {values.goal < values.initial ? "- " : "+ "}
                  {Math.round(Math.abs(values.goal / values.initial - 1) * 100)}
                  % {values.goal < values.initial ? "👍" : "👎"}
                </Text>
              ) : null}
              <Text style={componentStyles.oneBarLabel}>
                {unit === "min par an"
                  ? `${formatDuration(values.goal)} ${unitsWithEmojis[unit]}`
                  : `${Math.round(values.goal)} ${unit} ${
                      unitsWithEmojis[unit]
                    }`}
              </Text>
            </View>
            <View
              style={[
                componentStyles.oneBar,
                {
                  height: (h * ratios.C) / (2.2 * 100),
                  width: Dimensions.get("window").width / 6,
                  overflow: "hidden",
                  borderColor: colors.COVOIT,
                  borderWidth: 1,
                },
              ]}
            >
              <View
                style={{
                  marginTop: "auto",
                  height:
                    phase === "ACTION"
                      ? "2%"
                      : phase === "POSTACTION"
                      ? `${Math.max(phaseVal * 11, 11)}%`
                      : phase === "MAINTIEN"
                      ? "100%"
                      : "2%",
                  backgroundColor: colors.COVOIT,
                }}
              />
            </View>
          </TouchableOpacity>
        ) : null}
      </View>
      <View style={componentStyles.bottomLabelContainer}>
        <View style={componentStyles.oneBottomLabel}>
          <Text style={componentStyles.bottomLabel}>
            {moment(createdAt).format("L")}
          </Text>
          <Text style={componentStyles.bottomSmallLabel}>
            {moment(createdAt).fromNow()}
          </Text>
        </View>
        {!currentIsInitial ? (
          <View style={componentStyles.oneBottomLabel}>
            <Text style={componentStyles.bottomLabel}>Maintenant</Text>
          </View>
        ) : null}
        {values.goal ? (
          <View style={componentStyles.oneBottomLabel}>
            <Text style={componentStyles.bottomLabel}>Objectif</Text>
          </View>
        ) : null}
      </View>
      {openFeedback?.val && (
        <MicroModalFeedback
          open={openFeedback?.val}
          data={{
            type: openFeedback?.type,
            phase: phase,
            phaseVal: phaseVal,
            goalBudget: values.goal,
          }}
          close={() =>
            setOpenFeedback({
              val: false,
            })
          }
        />
      )}
    </>
  ) : (
    <ActivityIndicator color={colors.secondary} />
  );
}
