import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetMeta({ title = "ACCTIV" }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </>
  );
}
