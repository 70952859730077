import { config } from "../config/default";

const itinerary = {
  calculate: async (city = "rouen", from, to) => {
    try {
      const res = await fetch(`${config.itineraryUrl}/${city}/itineraries`, {
        method: "POST",
        body: JSON.stringify({
          from,
          to,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  getDurations: async (
    city = "rouen",
    from,
    to,
    hour_start_work,
    hour_end_work
  ) => {
    try {
      const res = await fetch(`${config.itineraryUrl}/${city}/durations`, {
        method: "POST",
        body: JSON.stringify({
          from,
          to,
          hour_start_work,
          hour_end_work,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  getDirections: async (from, to) => {
    try {
      const formattedFrom = `${from.lat}, ${from.long}`;
      const formattedTo = `${to.lat}, ${to.long}`;
      const res = await fetch(
        `${config.directionsApiUrl}?origin=${formattedFrom}&destination=${formattedTo}&mode=transit&language=fr&key=${config.googleMapsApiKey}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  getLines: async (city = "rouen") => {
    try {
      const res = await fetch(`${config.itineraryUrl}/${city}/lines`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
  getStops: async (city = "rouen", lineId) => {
    try {
      const res = await fetch(`${config.itineraryUrl}/${city}/stop-points`, {
        method: "POST",
        body: JSON.stringify({ line_id: lineId }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res && res.ok ? await res.json() : false;
    } catch (err) {
      console.error(err);
    }
  },
};

export default itinerary;
