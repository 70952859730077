import * as React from "react";
import { Text, View, Button, StyleSheet, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { FontAwesome } from "@expo/vector-icons";
import colors from "../../styles/variables";
import img from "../../../assets/cyclelane.png";

export default function ShowCycleLanesButton(props) {
  return (
    <View
      style={[
        styles.main,
        {
          top: "44%",
        },
      ]}
    >
      {props.show && (
        <View
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            backgroundColor: "#6a9c1aee",
            width: 14,
            height: 14,
            borderRadius: 99,
          }}
        />
      )}
      <TouchableOpacity onPress={props.handleShow}>
        <Image source={img} style={{ width: 33, height: 33 }} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    zIndex: 99,
    position: "absolute",
    right: 20,
    backgroundColor: colors.BIKE,
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
