import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  title: {
    marginTop: 12,
    marginBottom: 6,
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
  },
  textInput: {
    fontSize: 16,
    backgroundColor: "white",
    borderRadius: 33,
    color: colors.primary,
    padding: 10,
    fontFamily: "Raleway_400Regular",
  },
  datePickerText: {
    fontSize: 16,
    color: colors.primary,
    padding: 4,
    fontFamily: "Raleway_400Regular",
  },
  simulateTextInput: {
    position: "absolute",
    textAlign: "center",
    bottom: "50%",
    left: 0,
    right: 0,
    fontSize: 16,
    color: colors.primary,
    fontFamily: "Raleway_400Regular",
  },
  questionContainer: {
    width: 30,
    height: 30,
    backgroundColor: colors.secondary,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 33,
    padding: 6,
    marginRight: 6,
    marginTop: 10,
    overflow: "visible",
  },
  listContainer: {},
  weekItemContainer: {
    backgroundColor: "white",
    margin: 2,
    padding: 6,
    flex: 1,
    minWidth: "13%",
    minHeight: 40,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  weekItemContainerSelected: {
    backgroundColor: colors.secondary,
    margin: 2,
    padding: 6,
    flex: 1,
    minWidth: "13%",
    minHeight: 40,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  addressItemContainer: {
    backgroundColor: "white",
    margin: 2,
    padding: 6,
    flex: 1,
    minWidth: "30%",
    minHeight: 60,
    borderRadius: 5,
    alignItems: "flex-start",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  addressItemContainerSelected: {
    backgroundColor: colors.secondary,
    margin: 2,
    padding: 6,
    flex: 1,
    minWidth: "30%",
    minHeight: 60,
    borderRadius: 5,
    alignItems: "flex-start",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  searchIconContainer: {
    marginLeft: -10,
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primary,
    padding: 6,
    borderRadius: 33,
  },
  listItemContainer: {
    backgroundColor: "white",
    margin: 2,
    padding: 6,
    flex: 1,
    minWidth: "30%",
    minHeight: 60,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  listItemText: {
    textAlign: "center",
    color: colors.primary,
    fontFamily: "Raleway_400Regular",
  },

  listItemContainerSelected: {
    backgroundColor: colors.secondary,
    margin: 2,
    padding: 6,
    flex: 1,
    minWidth: "30%",
    minHeight: 60,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  listItemTextSelected: {
    color: "white",
    fontFamily: "Raleway_700Bold",
    textAlign: "center",
  },
  unit: {
    fontFamily: "Raleway_400Regular",
    position: "absolute",
    right: 10,
    bottom: "30%",
    alignItems: "center",
    justifyContent: "center",
  },
  unitFlex: {
    margin: 4,
    fontFamily: "Raleway_400Regular",
  },
  orderListIndex: {
    backgroundColor: `${colors.primary}AA`,
    fontFamily: "Raleway_400Regular",
    fontSize: 12,
    paddingVertical: 4,
    paddingHorizontal: 8,
    position: "absolute",
    top: 2,
    right: 2,
    color: "white",
    borderRadius: 99,
  },
});
