import React, { useReducer, useContext, useMemo, useEffect } from "react";
import api from "../services/api";
import { useAuth } from "./authContext";

const Coach = React.createContext();

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COACH_DATA":
      return Object.assign({}, state, {
        ...state,
        coachData: action.coachData,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const CoachProvider = ({ children }) => {
  const authState = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);

  const functions = useMemo(
    () => ({
      setCoachData: async (data) =>
        dispatch({ type: "SET_COACH_DATA", coachData: data }),
    }),
    []
  );

  useEffect(() => {
    const init = async () => {
      const userId = authState.userData.id;
      console.log("Init coach data", userId);
      const c = await api.get("coach-data", `?user_eq=${userId}`);
      // console.log("API returned...", c);
      if (c.length) {
        functions.setCoachData([...c]);
      }
    };

    if (
      authState.userToken &&
      authState.userData?.id &&
      !(state.goal && state.goal.mode)
    ) {
      init();
    }
  }, [authState.userToken]);

  return (
    <Coach.Provider value={{ coach: state.coachData, functions }}>
      {children}
    </Coach.Provider>
  );
};

export const useCoach = () => useContext(Coach);
