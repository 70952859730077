import React, { useState, useEffect } from "react";
import styles from "../styles/global";
import screenStyles from "../styles/informationStyles";
import {
  Text,
  View,
  ScrollView,
  Image,
  ImageBackground,
  ActivityIndicator,
} from "react-native";
import Markdown from "react-native-markdown-renderer";
import Header from "../components/Header";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useAuth } from "../context/authContext";
import api from "../services/api";
import AdviceElectricCar from "../components/AdviceComponents/AdviceElectricCar";
import AdvicePubtrans from "../components/AdviceComponents/AdvicePubtrans";
import AdviceEBike from "../components/AdviceComponents/AdviceEBike";
import AdviceCovoit from "../components/AdviceComponents/AdviceCovoit";
import AdviceCostCar from "../components/AdviceComponents/AdviceCostCar";

export default function AdviceScreen(props) {
  const { userData, updateUser } = useAuth();
  const [data, setData] = useState(props.route.params.data);
  const [loading, setLoading] = useState(false);

  const renderMain = () => {
    switch (data?.key) {
      case "ELECTRIC_CAR":
        return <AdviceElectricCar />;
      case "COVOIT":
        return <AdviceCovoit />;
      case "EBIKE":
        return <AdviceEBike />;
      case "PUBTRANS":
        return <AdvicePubtrans />;
      case "COST_CAR":
        return <AdviceCostCar data={data?.data} />;
    }
  };

  const renderQuestion = () => {
    switch (data?.key) {
      case "ELECTRIC_CAR":
        return "Pensez-vous investir dans un véhicule moins polluant au cours des prochains mois ?";
      case "COVOIT":
        return "Pensez-vous tenter l’aventure du covoiturage prochainement ?";
      case "EBIKE":
        return "Pensez-vous investir dans un vélo électrique au cours des prochains mois ?";
      case "PUBTRANS":
        return "Pensez-vous utiliser les transports en commun ?";
    }
  };

  const handlePressOption = async (option) => {
    setLoading(true);
    const updatedUser = await api.update("users", userData.id, {
      motivation_intention: {
        key: data?.key,
        question: renderQuestion(),
        answer: option,
      },
    });
    setLoading(false);
    if (updatedUser) {
      updateUser(updatedUser);
    }
  };

  return (
    <>
      <View style={styles.container}>
        <View
          style={{
            flex: 6,
            alignItems: "center",
          }}
        >
          <ScrollView
            contentContainerStyle={{
              borderRadius: 15,
              padding: 20,
            }}
          >
            <Text style={screenStyles.title}>{data.value}</Text>
            {renderMain()}
          </ScrollView>
        </View>
        {data?.key !== "COST_CAR" &&
          !(userData?.motivation_intention?.key === data?.key) && (
            <View style={screenStyles.ctaContainer}>
              {loading ? (
                <ActivityIndicator color={"white"} />
              ) : (
                <>
                  <Text style={styles.lightNormalText}>{renderQuestion()}</Text>
                  <View style={screenStyles.optionsContainer}>
                    <TouchableOpacity
                      style={screenStyles.optionContainer}
                      onPress={() => handlePressOption("Oui")}
                    >
                      <Text style={styles.textBold}>Oui</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={screenStyles.optionContainer}
                      onPress={() => handlePressOption("Peut-être")}
                    >
                      <Text style={styles.textBold}>Peut-être</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={screenStyles.optionContainer}
                      onPress={() => handlePressOption("Non")}
                    >
                      <Text style={styles.textBold}>Non</Text>
                    </TouchableOpacity>
                  </View>
                </>
              )}
            </View>
          )}
      </View>
    </>
  );
}
