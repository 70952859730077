import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  graphContainer: {
    flexDirection: "row",
    marginTop: "auto",
  },
  oneBarContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    marginHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightgray,
    position: "relative",
  },
  labelContainer: {
    // position: "absolute",
    // top: "0%",
    zIndex: 99,
    // backgroundColor: `#ffffffaa`,
    borderRadius: 15,
    // paddingVertical: 8,
    paddingHorizontal: 6,
  },
  bottomLabelContainer: {
    flexDirection: "row",
  },
  oneBottomLabel: {
    flex: 1,
    marginTop: 10,
    alignItems: "center",
  },
  bottomLabel: {
    fontSize: 14,
    color: "white",
    fontFamily: "Raleway_400Regular",
  },
  bottomLabelPhase: {
    fontSize: 10,
    color: colors.pink,
    textAlign: "center",
    fontFamily: "Raleway_400Regular",
  },
  bottomSmallLabel: {
    color: "white",
    fontFamily: "Raleway_400Regular",
    marginTop: 2,
    fontSize: 10,
  },
  oneBar: {
    marginTop: 5,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  oneBarLabel: {
    fontSize: 14,
    textAlign: "center",
    color: "white",
    fontFamily: "Raleway_700Bold",
  },
  oneBarComparison: {
    backgroundColor: "white",
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 15,
    fontFamily: "Raleway_400Regular",
    marginTop: 5,
    fontSize: 12,
    textAlign: "center",
    marginBottom: 4,
  },
});
