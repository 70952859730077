import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  settingsContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primary,
    paddingHorizontal: 20,
    paddingBottom: 20,
    paddingTop: 20,
  },
  sectionTitleContainer: {
    padding: 12,
    borderRadius: 15,
    margin: 6,
    backgroundColor: "white",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 9999,
    borderWidth: 1,
    borderColor: colors.secondary,
    borderStyle: "solid",
  },
  sectionScrollView: {
    width: "100%",

    minHeight: 300,
    marginTop: -20,
    marginBottom: 10,
    paddingTop: 10,
    backgroundColor: colors.lightgray,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  sectionTitle: {
    fontFamily: "Raleway_700Bold",
    color: colors.primary,
  },
});
