import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Platform,
  ActionSheetIOS,
  ActivityIndicator,
} from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/goalStyles";
import { TouchableOpacity } from "react-native-gesture-handler";
import colors from "../styles/variables";
import { Ionicons } from "@expo/vector-icons";
import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "../services/localeMoment";
import { modes } from "../config/modes";
import { Picker } from "@react-native-community/picker";
import ModalInformation from "./ModalInformation";
import CoachAdviceCTA from "./CoachAdviceCTA";

export default function Goal(props) {
  const { goal, updateGoal } = props;

  const onChangeDeadline = (event) => {
    updateGoal("deadline", event.target.value);
  };

  const [showModalInformation, setShowModalInformation] = useState(false);

  return (
    <>
      {showModalInformation && (
        <ModalInformation
          className={"full-modal"}
          content={{
            title:
              "Un objectif, pour économiser, se faciliter la vie ou faire un geste la planète...?",
            text:
              "ACCTIV encourage ses utilisateurs à réduire l'utilisation de la voiture pour privilégier d'autres modes de transport. blablablablablablablabla",
          }}
          open={showModalInformation}
          close={() => setShowModalInformation(false)}
        />
      )}
      <View style={componentStyles.container}>
        <View style={componentStyles.goalTitle}>
          <View style={componentStyles.yourGoalContainer}>
            <Text style={componentStyles.yourGoalText}>Votre objectif 🌱</Text>
          </View>

          <TouchableOpacity
            containerStyle={{ marginLeft: "auto" }}
            onPress={() => setShowModalInformation(true)}
          >
            <Ionicons
              name="md-information-circle-outline"
              size={24}
              color={colors.primary}
            />
          </TouchableOpacity>

          <View style={styles.flexRow}>
            <Text style={styles.normalText}>Je veux prendre</Text>
            <View
              style={{
                flex: 1,
                marginLeft: 8,
                marginVertical: 4,
              }}
            >
              <View style={componentStyles.textInput}>
                <Picker
                  style={{
                    minWidth: 120,
                    minHeight: 40,
                    // height: 16,
                    // color: "white",
                  }}
                  selectedValue={goal.mode}
                  onValueChange={(itemValue, itemIndex) =>
                    updateGoal("mode", itemValue)
                  }
                >
                  {Object.keys(modes)
                    .filter((el) => el !== "CAR")
                    .map((key, index) => (
                      <Picker.Item key={index} label={modes[key]} value={key} />
                    ))}
                </Picker>
                {/* <Text style={componentStyles.simulateTextInput}>
                {modes[goal.mode]}
              </Text> */}
              </View>
            </View>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.normalText}>Au moins</Text>
            <View
              style={{
                flex: 1,
                marginLeft: 8,
                marginVertical: 4,
              }}
            >
              <View style={componentStyles.textInput}>
                <Picker
                  style={
                    {
                      // height: 16,
                      // color: "white",
                    }
                  }
                  selectedValue={goal.frequency}
                  onValueChange={(itemValue, itemIndex) => {
                    updateGoal("frequency", itemValue);
                  }}
                >
                  {[
                    ...new Array(
                      "1 jour/semaine",
                      "2 jours/semaine",
                      "3 jours/semaine",
                      "4 jours/semaine",
                      "5 jours/semaine"
                    ),
                  ].map((el, index) => (
                    <Picker.Item key={index} label={el} value={index + 1} />
                  ))}
                </Picker>
              </View>
            </View>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.normalText}>D'ici le</Text>
            <View
              style={{
                flex: 1,
                marginLeft: 8,
                marginVertical: 4,
              }}
            >
              <input
                type="date"
                value={moment(goal.deadline).format("YYYY-MM-DD")}
                onChange={onChangeDeadline}
              />
            </View>
          </View>

          {goal && goal.mode && goal.frequency && goal.deadline ? (
            <Text style={componentStyles.majText}>
              Objectif fixé {moment(goal.updated_at).fromNow()}
            </Text>
          ) : (
            <Text style={componentStyles.majTextLead}>
              Vous n'avez pas encore d'objectif, choisissez un mode, une
              fréquence et une durée pour en fixer un !
            </Text>
          )}
        </View>
        <CoachAdviceCTA
          goalMode={goal?.mode}
          motivations={goal?.user?.motivations}
          profile={goal?.user?.profile}
        />
      </View>
    </>
  );
}
