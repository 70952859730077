import { StyleSheet } from "react-native";

export default StyleSheet.create({
  headerBannerContainer: {
    position: "absolute",
    top: 10,
    left: 20,
    right: 20,
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#6a9c1aee",
    flexDirection: "row",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  bannerCloseContainer: {
    marginLeft: "auto",
    borderRadius: 33,
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff50",
  },
});
