import React, { useState, useEffect } from "react";
import styles from "../styles/global";
import screenStyles from "../styles/settingsStyles";
import { Text, View, ScrollView, TouchableOpacity } from "react-native";
import Header from "../components/Header";
import SignUpForm from "../components/SignUpForm";
import { FontAwesome } from "@expo/vector-icons";
import colors from "../styles/variables";
import withAuth from "../hoc/withAuth";
import authStyles from "../styles/authStyles";
import api from "../services/api";
import Markdown from "react-native-markdown-renderer";
import HelmetMeta from "./Helmet/Meta";

function SettingsScreen(props) {
  const params = props.route.params;

  const { userData, updateAllUserData, authState } = props;

  const [show, setShow] = useState(params?.to || "account");
  const [accountData, setAccountData] = useState({});
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (userData && userData.firstname) {
      setAccountData({
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        city: userData.city.id,
        company: userData.company.id,
      });
    }
  }, [userData]);

  useEffect(() => {
    const fetchInfo = async () => {
      setInfo(await api.get("about-and-source"));
    };
    if (!info) fetchInfo();
  }, [info]);

  const updateAccountData = () => {
    updateAllUserData(accountData);
  };

  return (
    <>
      <HelmetMeta />
      <Header title={"Paramètres"} />
      <View style={screenStyles.settingsContainer}>
        <TouchableOpacity
          style={screenStyles.sectionTitleContainer}
          onPress={() => setShow("account")}
        >
          <Text style={screenStyles.sectionTitle}>Mon compte</Text>
          <FontAwesome name="chevron-down" size={14} color={colors.primary} />
        </TouchableOpacity>
        {show === "account" && accountData && (
          <ScrollView
            style={screenStyles.sectionScrollView}
            contentContainerStyle={{
              borderRadius: 15,
              padding: 20,
            }}
          >
            <SignUpForm
              accountData={accountData}
              setAccountData={setAccountData}
              isSettings={true}
            />
            <TouchableOpacity
              style={styles.buttonLightContainer}
              onPress={() => updateAccountData()}
            >
              <Text style={styles.buttonText}>Enregistrer les changements</Text>
            </TouchableOpacity>
          </ScrollView>
        )}
        {/* <TouchableOpacity
          style={screenStyles.sectionTitleContainer}
          onPress={() => setShow("password")}
        >
          <Text style={screenStyles.sectionTitle}>Mon mot de passe</Text>
          <FontAwesome name="chevron-down" size={14} color={colors.primary} />
        </TouchableOpacity>
        {show === "password" && (
          <ScrollView
            style={screenStyles.sectionScrollView}
            contentContainerStyle={{
              borderRadius: 15,
              padding: 20,
            }}
          >
            <Text>Change password</Text>
          </ScrollView>
        )} */}
        <TouchableOpacity
          style={screenStyles.sectionTitleContainer}
          onPress={() => setShow("about")}
        >
          <Text style={screenStyles.sectionTitle}>A propos d'ACCTIV</Text>
          <FontAwesome name="chevron-down" size={14} color={colors.primary} />
        </TouchableOpacity>
        {show === "about" && (
          <ScrollView
            style={screenStyles.sectionScrollView}
            contentContainerStyle={{
              borderRadius: 15,
              padding: 20,
            }}
          >
            {info && <Markdown>{info.body}</Markdown>}
          </ScrollView>
        )}
        <TouchableOpacity
          style={screenStyles.sectionTitleContainer}
          onPress={() => setShow("logout")}
        >
          <Text style={screenStyles.sectionTitle}>Déconnexion</Text>
          <FontAwesome name="chevron-down" size={14} color={colors.primary} />
        </TouchableOpacity>
        {show === "logout" && (
          <ScrollView
            style={screenStyles.sectionScrollView}
            contentContainerStyle={{
              borderRadius: 15,
              padding: 20,
            }}
          >
            <TouchableOpacity
              style={styles.buttonLightContainer}
              onPress={() => authState.signOut()}
            >
              <Text style={styles.buttonText}>Me déconnecter</Text>
            </TouchableOpacity>
          </ScrollView>
        )}
      </View>
    </>
  );
}

export default withAuth(SettingsScreen);
