import * as React from "react";
import { Text, View, Button, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { FontAwesome } from "@expo/vector-icons";
import colors from "../../styles/variables";

export default function BackButton(props) {
  return (
    <View
      style={[
        styles.buttonBack,
        {
          top: props.isHome ? "58%" : 20,
        },
      ]}
    >
      <TouchableOpacity onPress={props.handleBack}>
        <FontAwesome name="map-marker" size={30} color="white" />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonBack: {
    zIndex: 99,
    position: "absolute",
    right: 20,
    backgroundColor: colors.lightgray,
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
