import React from "react";
import styles from "../../styles/global";
import componentStyles from "../styles/adviceComponentStyles";
import { Linking, Text, View, TouchableOpacity } from "react-native";

const LINKS = {
  "Roulez Malin": "https://www.roulezmalin.com/communes/covoiturages/rouen",
  "Trajet à la carte": "https://www.trajetalacarte.com/covoiturage/rouen",
  "La roue verte":
    "http://www.covoiturage-travail.fr/point-d-interet/17563-rouen.html",
  "Covoiturage 76": "http://www.covoiturage76.net/",
};

export default function AdviceElectricCar(props) {
  return (
    <>
      <View style={{ marginTop: 20 }}>
        <Text style={[styles.lightNormalText, { marginBottom: 20 }]}>
          Nous comprenons que votre mode de vie dépend fortement de l’usage
          quotidien de la voiture. Ces habitudes peuvent paraître difficiles à
          changer, ou du moins à court terme. Néanmoins, vous pouvez agir sur
          vos émissions de CO2 en changeant de véhicule pour une voiture moins
          polluante, électrique, hybride, ou à carburant alternatif. Pour cela,
          vous bénéficiez d’aides de l’État, et de la région Normandie.
        </Text>
        <Text style={[styles.lightNormalText, { marginVertical: 10 }]}>
          Entre la prime à la conversion et les différents bonus, vous pouvez
          cumuler jusqu’à 14 000€ d’aides publiques. Selon votre situation, vous
          pouvez également prétendre à des micro-crédits et subventions
          supplémentaires.
        </Text>
        <TouchableOpacity
          style={componentStyles.linkContainer}
          onPress={async () =>
            await Linking.openURL(
              "https://storage.googleapis.com/acctiv-bucket/default/achat_ACCTIV_32c53689ae.pdf"
            )
          }
        >
          <Text style={styles.textBold}>Télécharger le détail des aides</Text>
          <Text>👉</Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
