import React, { useState, useEffect } from "react";
import { Text, View, TouchableOpacity, ActivityIndicator } from "react-native";
import styles from "../../styles/global";
import { FontAwesome5 } from "@expo/vector-icons";
import screenStyles from "../../styles/settingsAddressesStyles";
import { TextInput } from "react-native-gesture-handler";
import api from "../../services/api";
import withAuth from "../../hoc/withAuth";
import SplashScreen from "../SplashScreen";
import HelmetMeta from "../Helmet/Meta";

function SettingsAddressesScreen(props) {
  const { userData, updateUserData } = props;
  const [input, setInput] = useState("");
  const [searching, setSearching] = useState(null);
  const [loading, setLoading] = useState(false);
  const [placesResults, setPlacesResults] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    setPlacesResults(await api.searchPlace(input));
    setLoading(false);
  };

  const handleClickResult = async (data) => {
    setLoading(true);
    await updateUserData(searching, data);
    setLoading(false);
  };

  if (!userData) return <SplashScreen />;

  return (
    <>
      <HelmetMeta />
      {searching ? (
        <>
          <View style={screenStyles.formContainer}>
            <Text style={screenStyles.formLabel}>
              Remplissez l'adresse de votre{" "}
              {searching === "home_address" ? "domicile" : "travail"} :
            </Text>
            <TextInput
              autoFocus={true}
              returnKeyType={"search"}
              style={screenStyles.inputPlace}
              placeholder={"Adresse"}
              value={input}
              onChangeText={(text) => setInput(text)}
              onBlur={() => handleSearch()}
            />
          </View>
        </>
      ) : null}
      <View style={screenStyles.container}>
        {!searching && (
          <>
            <View style={screenStyles.oneAddressContainer}>
              <View style={styles.flexRow}>
                <FontAwesome5 name="home" size={14} color="white" />
                <Text style={screenStyles.oneAddressLabel}>Mon domicile</Text>
              </View>
              <TouchableOpacity onPress={() => setSearching("home_address")}>
                <Text style={screenStyles.oneAddressText}>
                  {userData.home_address
                    ? userData.home_address.formatted_address
                    : "Aucune adresse renseignée"}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={screenStyles.oneAddressContainer}>
              <View style={styles.flexRow}>
                <FontAwesome5 name="building" size={14} color="white" />
                <Text style={screenStyles.oneAddressLabel}>Mon travail</Text>
              </View>
              <TouchableOpacity onPress={() => setSearching("work_address")}>
                <Text style={screenStyles.oneAddressText}>
                  {userData.work_address ? (
                    <>
                      {`${userData.work_address.name} - ${userData.work_address.formatted_address}`}
                    </>
                  ) : (
                    "Aucune adresse renseignée"
                  )}
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}

        {placesResults && placesResults.length ? (
          placesResults.map((place, index) => {
            return (
              <TouchableOpacity
                key={index}
                style={screenStyles.placeResultContainer}
                onPress={() => handleClickResult(place)}
              >
                <Text style={screenStyles.placeResultName}>{place.name}</Text>
                <Text style={screenStyles.placeResultAddress}>
                  {place.formatted_address}
                </Text>
              </TouchableOpacity>
            );
          })
        ) : placesResults ? (
          <Text>Aucun résultat</Text>
        ) : loading ? (
          <View>
            <ActivityIndicator color="white" size="large" />
          </View>
        ) : null}
      </View>
    </>
  );
}

export default withAuth(SettingsAddressesScreen);
