import React, { useState, useEffect } from "react";
import styles from "../../styles/global";
import screenStyles from "../../styles/searchItineraryStyles";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import api from "../../services/api";
import * as Location from "expo-location";
import { ScrollView } from "react-native-gesture-handler";
import itinerary from "../../services/itinerary";
import JourneysList from "../../components/JourneysList";
import { useNavigation } from "@react-navigation/native";
import colors from "../../styles/variables";
import withAuth from "../../hoc/withAuth";
import HelmetMeta from "../Helmet/Meta";

function SearchItineraryScreen(props) {
  const { userData } = props;
  const params = props.route.params;
  const navigation = useNavigation();

  const [step, setStep] = useState(0);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [placesResults, setPlacesResults] = useState(null);
  const [departure, setDeparture] = useState(null);
  const [destination, setDestination] = useState(null);
  const [journeys, setJourneys] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (location && params && params.goTo) {
      setStep(2);
      setDeparture(location);
      setDestination(props.route.params.goTo);
    }
  }, [params, location]);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestPermissionsAsync();
      if (status === "granted") {
        const { coords } = await Location.getCurrentPositionAsync({});
        setLocation({
          geometry: {
            location: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          },
          name: "Ma position actuelle",
        });
      }
    })();
  }, []);

  const handleSearch = async () => {
    setLoading(true);
    setPlacesResults(null);
    setJourneys(null);
    setPlacesResults(await api.searchPlace(input));
    setLoading(false);
  };

  const handleClickResult = (data) => {
    setJourneys(null);
    switch (step) {
      case 0:
        if (destination) {
          setStep(2);
        } else {
          setStep(1);
        }
        setPlacesResults(null);
        setInput("");
        return setDeparture(data);
      case 1:
        setStep(2);
        setPlacesResults(null);
        setInput("");
        return setDestination(data);
      default:
        return null;
    }
  };

  const handleClickJourney = (journey) => {
    navigation.navigate("ItinerarySteps", journey);
  };

  const fetchItinerary = async () => {
    setLoading(true);
    const from = {
      lat: departure.geometry.location.lat,
      long: departure.geometry.location.lng,
    };
    const to = {
      lat: destination.geometry.location.lat,
      long: destination.geometry.location.lng,
    };
    const [resCalculator, resDirections] = await Promise.all([
      await itinerary.calculate("rouen", from, to),
      await itinerary.getDirections(from, to),
    ]);

    let enhancedItineraries = resCalculator.itineraries;
    let trainItinerary = { ...resDirections?.routes?.[0]?.legs?.[0] };

    if (trainItinerary.duration) {
      trainItinerary.isTrain = true;
      console.log(trainItinerary);
      enhancedItineraries.push(trainItinerary);
    }

    setJourneys(enhancedItineraries);
    setPlacesResults(null);
    setLoading(false);
  };

  useEffect(() => {
    if (departure && destination && !journeys) {
      fetchItinerary();
    }
  }, [departure, destination]);

  return (
    <>
      <HelmetMeta />
      <View style={screenStyles.formContainer}>
        {step === 0 ? (
          <>
            <Text style={styles.normalText}>Départ</Text>
            <TextInput
              autoFocus={true}
              returnKeyType={"search"}
              style={screenStyles.inputPlace}
              placeholder={"Départ"}
              value={input}
              onChangeText={(text) => setInput(text)}
              onBlur={() => input !== "" && handleSearch()}
            />
          </>
        ) : null}
        {step === 1 ? (
          <>
            <Text style={styles.normalText}>Départ</Text>
            <TouchableOpacity
              style={screenStyles.inputPlaceContainer}
              onPress={() => {
                setStep(0);
                setDeparture(null);
                setInput("");
              }}
            >
              <Text style={screenStyles.inputPlaceText}>{departure.name}</Text>
            </TouchableOpacity>
            <Text style={styles.normalText}>Arrivée</Text>
            <TextInput
              returnKeyType={"search"}
              style={screenStyles.inputPlace}
              placeholder={"Arrivée"}
              value={input}
              onChangeText={(text) => setInput(text)}
              onBlur={() => input !== "" && handleSearch()}
            />
          </>
        ) : null}
        {step === 2 ? (
          <>
            <Text style={styles.normalText}>Départ</Text>
            <TouchableOpacity
              style={screenStyles.inputPlaceContainer}
              onPress={() => {
                setStep(0);
                setDeparture(null);
                setInput("");
              }}
            >
              <Text style={screenStyles.inputPlaceText}>{departure.name}</Text>
            </TouchableOpacity>
            <Text style={styles.normalText}>Arrivée</Text>
            <TouchableOpacity
              style={screenStyles.inputPlaceContainer}
              onPress={() => {
                setStep(1);
                setDestination(null);
                setInput("");
              }}
            >
              <Text style={screenStyles.inputPlaceText}>
                {destination.name}
              </Text>
            </TouchableOpacity>
          </>
        ) : null}
      </View>

      <ScrollView
        style={screenStyles.container}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={fetchItinerary}
            colors={[colors.secondary, colors.primary]}
          />
        }
      >
        {step === 0 || step === 1 ? (
          <>
            {location ? (
              <>
                <TouchableOpacity
                  style={[
                    screenStyles.placeResultContainer,
                    {
                      flexDirection: "row",
                      alignItems: "center",
                    },
                  ]}
                  onPress={() => handleClickResult(location)}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 8,
                      backgroundColor: "blue",
                      marginRight: 10,
                    }}
                  />
                  <Text style={screenStyles.currentLocationText}>
                    Utiliser la position actuelle
                  </Text>
                </TouchableOpacity>
              </>
            ) : null}
            <View style={styles.flexRow}>
              {userData && userData.home_address ? (
                <TouchableOpacity
                  style={[
                    screenStyles.placeResultContainer,
                    {
                      flex: 1,
                      marginRight: 5,
                      flexDirection: "row",
                      alignItems: "center",
                    },
                  ]}
                  onPress={() => handleClickResult(userData.home_address)}
                >
                  <FontAwesome5 name="home" size={14} color={colors.primary} />
                  <Text style={screenStyles.addressText}>Domicile</Text>
                </TouchableOpacity>
              ) : null}
              {userData && userData.work_address ? (
                <TouchableOpacity
                  style={[
                    screenStyles.placeResultContainer,
                    { flex: 1, flexDirection: "row", alignItems: "center" },
                  ]}
                  onPress={() => handleClickResult(userData.work_address)}
                >
                  <FontAwesome5
                    name="building"
                    size={14}
                    color={colors.primary}
                  />
                  <Text style={screenStyles.addressText}>Travail</Text>
                </TouchableOpacity>
              ) : null}
            </View>
          </>
        ) : null}

        {departure && destination && journeys ? (
          <JourneysList
            userId={userData.id}
            journeys={journeys}
            handleClickJourney={handleClickJourney}
          />
        ) : null}

        {placesResults && placesResults.length ? (
          placesResults.map((place, index) => {
            return (
              <TouchableOpacity
                key={index}
                style={screenStyles.placeResultContainer}
                onPress={() => handleClickResult(place)}
              >
                <Text style={screenStyles.placeResultName}>{place.name}</Text>
                <Text style={screenStyles.placeResultAddress}>
                  {place.formatted_address}
                </Text>
              </TouchableOpacity>
            );
          })
        ) : placesResults ? (
          <Text
            style={[
              styles.lightNormalText,
              {
                marginTop: 10,
              },
            ]}
          >
            Aucun résultat
          </Text>
        ) : loading ? (
          <View>{/* <ActivityIndicator color="white" size="large" /> */}</View>
        ) : null}
      </ScrollView>
    </>
  );
}

export default withAuth(SearchItineraryScreen);
