import * as React from "react";
import styles from "../styles/global";
import screenStyles from "../styles/tutorialStyles";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  BackHandler,
  ScrollView,
  Dimensions,
} from "react-native";
import step1 from "../../assets/tutorial/Step1.png";
import step3 from "../../assets/tutorial/Step2.png";
import step5 from "../../assets/tutorial/Step3.png";
import step2 from "../../assets/tutorial/Step4.png";
import step4 from "../../assets/tutorial/Step5.png";
import step6 from "../../assets/tutorial/Step6.png";
import welcome from "../../assets/welcome.png";
import Header from "../components/Header";
import { useAuth } from "../context/authContext";

const stepsImages = {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step8: welcome,
};

const stepsTexts = {
  step1: (
    <>
      <Text style={[styles.textBold, { fontSize: 15 }]}>Le dashboard</Text> est
      votre tableau de bord : il vous montre l’impact de votre usage de la
      voiture sur votre budget, votre temps et votre empreinte carbone. Regardez
      vos économies augmenter lorsque vous réduisez l'usage de la voiture !
    </>
  ),
  step2: (
    <>
      ACCTIV vous interrogera régulièrement sur vos pratiques. Vous pouvez aussi{" "}
      <Text style={[styles.textBold, { fontSize: 15 }]}>
        mettre à jour votre dashboard
      </Text>{" "}
      quand vous le voulez en cliquant sur le bandeau rose qui s'affiche en bas
      !
    </>
  ),
  step3: (
    <>
      <Text style={[styles.textBold, { fontSize: 15 }]}>
        Le calculateur d’itinéraires
      </Text>{" "}
      vous donne accès aux plans de transports en commun pour chaque ligne, des
      pistes cyclables ainsi que de l'état du trafic routier. Vous pouvez
      également voir des informations spécifiques communiquées par la Métropole
      de Rouen, par votre entreprise, ainsi que des astuces personnalisées.
    </>
  ),
  step4: (
    <>
      Il vous permet de découvrir les alternatives à la voiture sur votre
      itinéraire. Comparez le coût, la durée et les émissions associées à chaque
      trajet !
    </>
  ),
  step5: (
    <>
      <Text style={styles.textBold}>Le coach</Text> vous accompagne. Découvrez
      vos conseils personnalisés et choisissez le mode le plus adapté à votre
      situation. Vous pourrez ensuite vous fixer un objectif de changement à
      plus ou moins long terme.
    </>
  ),
  step6: (
    <>
      En haut de l'écran, choisissez l’équivalence qui vous intéresse le plus
      dans le menu déroulant !
    </>
  ),
  step7: (
    <>
      Chaque jour, ACCTIV vous accompagne par le biais de notifications
      personnalisées. Pensez à les activer dans les réglages de votre téléphone
      !
    </>
  ),
  step8: (
    <>
      A vous de jouer ! Nous nous retrouvons dans un mois pour faire le point
      sur votre projet. L’application vous proposera un nouveau questionnaire,
      qui vous permettra d’évaluer vos progrès et de formuler les barrières
      auxquelles vous faites encore face.
    </>
  ),
};

export default function TutorialScreen() {
  const [step, setStep] = React.useState(1);

  const { endTutorial } = useAuth();

  const scrollViewRef = React.useRef();

  React.useEffect(() => {
    const backAction = () => {
      setStep(Math.max(step - 1, 1));
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, [step]);

  React.useEffect(() => scrollViewRef.current?.scrollTo({ y: 0 }), [step]);

  React.useEffect(() => {
    const handleEndTutorial = async () => {
      localStorage.setItem("firstTime", "false");
      endTutorial();
    };

    if (step > 8) {
      handleEndTutorial();
    }
  }, [step]);

  return (
    <>
      <Header title={`Didactitiel (étape ${step}/8)`} isOnboarding={true} />
      <View style={screenStyles.container}>
        <ScrollView
          style={{
            flex: 1,
          }}
          contentContainerStyle={{
            paddingBottom: 40,
          }}
          ref={scrollViewRef}
        >
          {stepsImages[`step${step}`] && (
            <Image
              source={stepsImages[`step${step}`]}
              style={{
                width: "100%",
                height:
                  Dimensions.get("window").height * (step === 8 ? 0.5 : 0.75),
                resizeMode: "contain",
              }}
            />
          )}

          <Text
            style={[
              styles.normalText,
              {
                paddingVertical: 20,
                marginHorizontal: 7,
                fontSize: 15,
              },
            ]}
          >
            {stepsTexts[`step${step}`]}
          </Text>
        </ScrollView>
        <TouchableOpacity
          style={[
            styles.buttonLightContainer,
            { position: "absolute", right: 0, bottom: 0, borderRadius: 33 },
          ]}
          onPress={() => setStep(step + 1)}
        >
          <Text style={styles.buttonText}>
            {step === 8 ? "Terminer et accéder à ACCTIV" : "Suivant"}
          </Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
