import React from "react";
import { Text, View } from "react-native";
import componentStyles from "../styles/markerStyles";
import colors from "../../styles/variables";

export default function MarkerInterchange(props) {
  const { type, labelStart, labelEnd } = props;
  return (
    <View style={componentStyles.container}>
      <View style={componentStyles.internalContainerInterchange}>
        <Text style={componentStyles.type}>{type}</Text>
        <Text style={componentStyles.label}>{labelStart}</Text>
        <Text style={componentStyles.label}>{labelEnd}</Text>
      </View>
      <View style={componentStyles.arrowDown} />
      <View
        style={{
          width: 20,
          height: 20,
          borderRadius: 33,
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: colors.secondary,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: colors.primary,
        }}
      />
    </View>
  );
}
