import React, { useEffect, useRef, useState } from "react";
import { Modal, View, Text, TouchableOpacity } from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/microModalFeedbackStyles";
import ModeIconCoach from "./ModeIconCoach";
import { useGoal } from "../context/goalContext";
import colors from "../styles/variables";

export default function MicroModalCheckPhase(props) {
  const { open, close, handleCheckPhase } = props;
  const { goal, functions } = useGoal();

  console.log("PHASE", goal.user.phase);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <View style={componentStyles.centeredView}>
        <View style={componentStyles.modalView}>
          <Text style={componentStyles.titleText}>
            Alors,{" "}
            {goal.user.phase === "MAINTIEN"
              ? "maintenez-vous votre objectif ?"
              : "avancez-vous vers votre objectif ?"}
          </Text>

          <Text
            style={[
              styles.normalText,
              { paddingBottom: 20, textAlign: "center" },
            ]}
          >
            {goal.user.phase == "MAINTIEN"
              ? `Régulièrement, nous vous demanderons si vous maintenez votre objectif ! Vous continuerez de voir vos économies augmenter si vous maintenez l'objectif !`
              : `Chaque semaine, nous vous demanderons si vous tenez bien votre objectif, vous verrez vos économies augmenter si vous tenez l'objectif !`}
          </Text>
          <View
            style={{
              flexDirection: "row",
              marginBottom: "auto",
              width: "100%",
            }}
          >
            <TouchableOpacity
              style={[
                componentStyles.listItemContainer,
                {
                  flex: 1,
                  backgroundColor: colors[goal?.mode],
                  paddingTop: 10,
                },
              ]}
              onPress={() => handleCheckPhase(true)}
            >
              <Text style={styles.lightNormalText}>Oui ! </Text>
              <ModeIconCoach mode={goal?.mode} />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                componentStyles.listItemContainer,
                { flex: 1, backgroundColor: colors.CAR, paddingTop: 10 },
              ]}
              onPress={() => handleCheckPhase(false)}
            >
              <Text style={styles.lightNormalText}>Non...</Text>
              <ModeIconCoach mode={"CAR"} />
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={close}>
            <Text
              style={[
                styles.normalText,
                { textDecorationLine: "underline", marginTop: 10 },
              ]}
            >
              Répondre plus tard
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </div>
  );
}
