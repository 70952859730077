import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  formContainer: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    backgroundColor: "white",
    borderBottomColor: colors.verylightgray,
    borderBottomWidth: 1,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: colors.primary,
  },
  inputPlace: {
    backgroundColor: "white",
    borderColor: colors.lightgray,
    borderWidth: 1,
    borderRadius: 33,
    marginTop: 4,
    padding: 10,
    marginBottom: 16,
    color: colors.primary,
    fontSize: 14,
    fontFamily: "Raleway_400Regular",
  },
  inputPlaceContainer: {
    backgroundColor: "white",
    borderColor: colors.lightgray,
    borderWidth: 1,
    borderRadius: 33,
    marginTop: 4,
    padding: 10,
    marginBottom: 16,
  },
  inputPlaceText: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: "Raleway_600SemiBold",
  },
  placeResultContainer: {
    backgroundColor: colors.verylightgray,
    borderRadius: 5,
    marginVertical: 4,
    paddingVertical: 8,
    paddingHorizontal: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  placeResultName: {
    color: colors.primary,
    fontSize: 15,
    fontFamily: "Raleway_700Bold",
    marginBottom: 4,
  },
  placeResultAddress: {
    color: colors.primary,
    fontSize: 12,
    fontFamily: "Raleway_400Regular",
  },
  currentLocationText: {
    color: "blue",
    fontSize: 15,
    fontFamily: "Raleway_600SemiBold",
  },
  addressText: {
    marginLeft: 5,
    fontSize: 15,
    color: colors.primary,
    fontFamily: "Raleway_600SemiBold",
  },
});
