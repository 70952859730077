import React, { useState, useEffect } from "react";
import styles from "../../styles/global";
import screenStyles from "../../styles/introductionSurveyStyles";
import { Text, View, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import illu from "../../../assets/acctiv_info_survey.png";
import HelmetMeta from "../Helmet/Meta";
import { FontAwesome } from "@expo/vector-icons";

export default function IntroductionSimplifiedSurveyScreen(props) {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <HelmetMeta />
      <View
        style={{
          flex: 1,
          width: "100%",
          alignItems: "center",
          paddingHorizontal: 20,
          paddingVertical: 40,
        }}
      >
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("Dashboard", {
              screen: "Dashboard",
            })
          }
          style={{ marginRight: "auto" }}
        >
          <FontAwesome name="arrow-left" size={20} style={{ color: "white" }} />
        </TouchableOpacity>

        <View style={screenStyles.titleContainer}>
          <Text style={screenStyles.title}>
            Alors, du neuf dans vos pratiques de mobilité ?
          </Text>
        </View>
        <Image
          source={illu}
          style={{
            width: "100%",
            resizeMode: "contain",
            flex: 1,
          }}
        />
        <View style={{ flex: 1 }}>
          <Text style={screenStyles.body}>
            Votre situation ou vos pratiques de mobilité ont évolué ? Génial !
            Dites-nous en plus et Acctiv vous fournira des informations et des
            conseils plus précis.
          </Text>
          <TouchableOpacity
            style={styles.buttonLightContainer}
            onPress={() =>
              navigation.navigate("SurveyQuestionnaire", {
                type: "show_in_simplified",
              })
            }
          >
            <Text style={styles.buttonText}>Je réponds au questionnaire</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
