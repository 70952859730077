import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  StatusBar,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  ActionSheetIOS,
} from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/oneQuestionStyles";
import DateTimePicker from "@react-native-community/datetimepicker";
import { Picker } from "@react-native-community/picker";
import { FontAwesome5 } from "@expo/vector-icons";
import api from "../services/api";
import { ScrollView } from "react-native-gesture-handler";
import colors from "../styles/variables";
import moment from "../services/localeMoment";

export default function OneQuestion(props) {
  const { index, question, answers, handleChange } = props;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchAddressInput, setSearchAddressInput] = useState(null);
  const [addresses, setAddresses] = useState(null);

  const getUnit = (unit) => {
    switch (unit) {
      case "days_per_week":
        return "jours / semaine";
      case "km_per_year":
        return "km par an";
      case "euros_per_year":
        return "euros par an";
      case "none":
        return "";
      case "cars":
        return "voitures";
      default:
        return unit;
    }
  };

  const thisYear = () => {
    const today = new Date();
    return today.getFullYear();
  };

  const handleSearch = async () => {
    setAddresses(await api.searchPlace(searchAddressInput));
  };

  const debouncedInput = useDebounce(searchAddressInput, 400);

  useEffect(() => {
    if (debouncedInput) {
      handleSearch();
    }
  }, [debouncedInput]);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    React.useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const calculateUnitCoefficient = (unit) => {
    switch (unit) {
      case "km_per_year":
        return 1000;
      case "euros_per_year":
        return 100;
      default:
        return 1;
    }
  };

  const onPress = (question, data) => {
    let options = [];
    switch (question.type) {
      case "duration":
        if (data === "hour") {
          options = [...new Array(12)];
        } else {
          options = [...new Array(59)];
        }

        options = [...options.map((el, index) => `${index}`), "Fermer"];
        ActionSheetIOS.showActionSheetWithOptions(
          {
            options: [...options],
            destructiveButtonIndex: options.length - 1,
          },
          (buttonIndex) => {
            if (buttonIndex !== options.length - 1) {
              if (data === "hour") {
                let newValue = answers[question.label] % 60 || 0;
                newValue = newValue + buttonIndex * 60;
                handleChange(question.label, newValue);
              } else {
                let newValue =
                  answers[question.label] - (answers[question.label] % 60) || 0;
                newValue = newValue + buttonIndex;
                handleChange(question.label, newValue);
              }
            }
          }
        );

        break;
      case "agree_scale":
        options = [
          "Très négative",
          "Plutôt négative",
          "Plutôt positive",
          "Très positive",
        ];
        options = [...options, "Fermer"];
        ActionSheetIOS.showActionSheetWithOptions(
          {
            options: [...options],
            destructiveButtonIndex: options.length - 1,
          },
          (buttonIndex) => {
            if (buttonIndex !== options.length - 1) {
              handleChange(question.label, options[buttonIndex]);
            }
          }
        );
        break;
      case "year":
        options = [...new Array(22)];
        options = [
          ...options.map((el, index) => `${thisYear() - index}`),
          `Antérieure à ${thisYear() - 21}`,
          "Fermer",
        ];
        ActionSheetIOS.showActionSheetWithOptions(
          {
            options: [...options],
            destructiveButtonIndex: options.length - 1,
          },
          (buttonIndex) => {
            if (buttonIndex !== options.length - 1) {
              handleChange(question.label, options[buttonIndex]);
            }
          }
        );
        break;
      default:
        options = [...new Array(100)];
        options = [
          ...options.map(
            (el, index) => `${index * calculateUnitCoefficient(question.unit)}`
          ),
          "Fermer",
        ];
        ActionSheetIOS.showActionSheetWithOptions(
          {
            options: [...options],
            destructiveButtonIndex: options.length - 1,
          },
          (buttonIndex) => {
            if (buttonIndex !== options.length - 1) {
              handleChange(
                question.label,
                buttonIndex * calculateUnitCoefficient(question.unit)
              );
            }
          }
        );
        break;
    }
  };

  return (
    <>
      <View>
        <View style={{ flexDirection: "row" }}>
          <View style={[componentStyles.questionContainer]}>
            <Text style={[styles.textBold, { color: "white" }]}>
              {index + 1}
            </Text>
            {answers[question.label] !== "" ? (
              <View
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  marginTop: -10,
                  marginLeft: -1,
                }}
              >
                <Text>✅</Text>
              </View>
            ) : (
              <View
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  backgroundColor: "rgba(255,255,255,0.7)",
                  borderWidth: 1,
                  borderColor: "red",
                  borderStyle: "solid",
                  borderRadius: 99,
                }}
              />
            )}
          </View>
          <Text style={[componentStyles.title, { flex: 1 }]}>
            {question.question}
          </Text>
        </View>

        <View>
          {question.type === "text" && (
            <>
              <TextInput
                style={componentStyles.textInput}
                value={answers[question.label] || ""}
                onChangeText={(text) => handleChange(question.label, text)}
              />
            </>
          )}
          {question.type === "yesno" && (
            <View style={{ justifyContent: "center" }}>
              {[
                {
                  label: (question.answers && question.answers[0]) || "Oui",
                  value: true,
                },
                {
                  label: (question.answers && question.answers[1]) || "Non",
                  value: false,
                },
              ].map((ans, index) => {
                return (
                  <TouchableOpacity
                    style={
                      answers[question.label] === ans.value
                        ? componentStyles.listItemContainerSelected
                        : componentStyles.listItemContainer
                    }
                    key={index}
                    onPress={() => handleChange(question.label, ans.value)}
                  >
                    <Text
                      style={
                        answers[question.label] === ans.value
                          ? componentStyles.listItemTextSelected
                          : componentStyles.listItemText
                      }
                    >
                      {ans.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          {question.type === "duration" && (
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View style={{ ...componentStyles.textInput, flex: 1 }}>
                <Picker
                  style={{
                    height: 30,
                    // color: "white"
                  }}
                  selectedValue={Math.floor(answers[question.label] / 60) || 0}
                  onValueChange={(itemValue, itemIndex) => {
                    let newValue = answers[question.label] % 60 || 0;
                    newValue = newValue + itemValue * 60;
                    console.log("NEW HOUR VALUE", newValue);
                    handleChange(question.label, newValue);
                  }}
                >
                  {[...new Array(12)].map((el, index) => (
                    <Picker.Item
                      key={index}
                      label={`${index + 1} h`}
                      value={index + 1}
                    />
                  ))}
                </Picker>
                {/* <Text style={componentStyles.simulateTextInput}>
                  {Math.floor(answers[question.label] / 60) || 0}h
                </Text> */}
              </View>
              <View style={{ ...componentStyles.textInput, flex: 1 }}>
                <Picker
                  style={{
                    height: 30,
                    // color: "white"
                  }}
                  selectedValue={answers[question.label] % 60 || 0}
                  onValueChange={(itemValue, itemIndex) => {
                    let newValue =
                      answers[question.label] -
                        (answers[question.label] % 60) || 0;
                    newValue = parseInt(newValue) + parseInt(itemValue);
                    console.log("NEW MIN VALUE", newValue);
                    handleChange(question.label, newValue);
                  }}
                >
                  {[...new Array(59)].map((el, index) => (
                    <Picker.Item
                      key={index}
                      label={`${index + 1} min`}
                      value={index + 1}
                    />
                  ))}
                </Picker>
                {/* <Text style={componentStyles.simulateTextInput}>
                  {answers[question.label] % 60 || 0}min
                </Text> */}
              </View>
            </View>
          )}
          {question.type === "hour" && (
            <>
              <TouchableOpacity
                style={componentStyles.textInput}
                onPress={() => setShowDatePicker(true)}
              >
                <Text style={componentStyles.datePickerText}>
                  {answers[question.label]
                    ? moment(answers[question.label]).format("HH:mm")
                    : "Sélectionnez..."}
                </Text>
              </TouchableOpacity>
              {showDatePicker && (
                <DateTimePicker
                  style={componentStyles.pickerStyle}
                  testID="dateTimePicker"
                  value={answers[question.label] || new Date()}
                  mode={"time"}
                  display="default"
                  onChange={(event, selectedDate) => {
                    setShowDatePicker(Platform.OS === "ios");
                    handleChange(question.label, selectedDate);
                  }}
                />
              )}
            </>
          )}
          {question.type === "agree_scale" && (
            <>
              <View style={{ flex: 1 }}>
                <View style={componentStyles.textInput}>
                  {Platform.OS === "ios" ? (
                    <>
                      <TouchableOpacity
                        style={componentStyles.textInput}
                        onPress={() => onPress(question)}
                      >
                        <Text style={componentStyles.simulateTextInput}>
                          {answers[question.label]}
                        </Text>
                      </TouchableOpacity>
                    </>
                  ) : (
                    <>
                      <Picker
                        style={{ height: 30, color: "white" }}
                        selectedValue={answers[question.label] || "Pas d'avis"}
                        onValueChange={(itemValue, itemIndex) =>
                          handleChange(question.label, itemValue)
                        }
                      >
                        <Picker.Item label={``} value={""} />
                        <Picker.Item label={`Sans avis`} value={"Sans avis"} />
                        <Picker.Item
                          label={`Très négative`}
                          value={"Très négative"}
                        />
                        <Picker.Item
                          label={`Plutôt négative`}
                          value={`Plutôt négative`}
                        />
                        <Picker.Item
                          label={`Plutôt positive`}
                          value={`Plutôt positive`}
                        />
                        <Picker.Item
                          label={`Très positive`}
                          value={"Très positive"}
                        />
                      </Picker>
                      <Text style={componentStyles.simulateTextInput}>
                        {answers[question.label]}
                      </Text>
                    </>
                  )}
                </View>
              </View>
            </>
          )}
          {question.type === "address" && (
            <View style={{ justifyContent: "center" }}>
              <View style={{ flexDirection: "row", marginBottom: 10 }}>
                <TextInput
                  returnKeyType="search"
                  style={[componentStyles.textInput, { flex: 1 }]}
                  value={searchAddressInput}
                  onChangeText={(text) => setSearchAddressInput(text)}
                  onBlur={handleSearch}
                />
                <TouchableOpacity
                  onPress={handleSearch}
                  style={componentStyles.searchIconContainer}
                >
                  <FontAwesome5 name="search" size={18} color="white" />
                </TouchableOpacity>
              </View>

              {!addresses &&
              answers[question.label] &&
              answers[question.label].place_id ? (
                <View style={componentStyles.addressItemContainerSelected}>
                  <View style={{ padding: 6 }}>
                    <Text
                      style={[
                        styles.textBold,
                        {
                          color: colors.primary,
                        },
                      ]}
                    >
                      {answers[question.label].name}
                    </Text>
                    <Text
                      style={[
                        componentStyles.listItemText,
                        { textAlign: "left" },
                      ]}
                    >
                      {answers[question.label].formatted_address}
                    </Text>
                  </View>
                </View>
              ) : null}

              {isLoading ? (
                <View style={{ justifyContent: "center" }}>
                  <ActivityIndicator color="white" />
                </View>
              ) : null}

              <ScrollView style={{ maxHeight: 200 }}>
                {!isLoading &&
                  addresses &&
                  addresses.map((ans, index) => {
                    return (
                      <TouchableOpacity
                        style={componentStyles.addressItemContainer}
                        key={index}
                        onPress={() => {
                          setSearchAddressInput(null);
                          setAddresses(null);
                          handleChange(question.label, ans);
                        }}
                      >
                        <View style={{ padding: 6 }}>
                          <Text
                            style={[
                              styles.textBold,
                              {
                                color: colors.primary,
                              },
                            ]}
                          >
                            {ans.name}
                          </Text>
                          <Text
                            style={[
                              componentStyles.listItemText,
                              { textAlign: "left" },
                            ]}
                          >
                            {ans.formatted_address}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
              </ScrollView>
            </View>
          )}
          {question.type === "year" && (
            <>
              <View style={componentStyles.textInput}>
                <Picker
                  style={{
                    height: 30,
                    //  color: "white"
                  }}
                  selectedValue={answers[question.label] || 2015}
                  onValueChange={(itemValue, itemIndex) =>
                    handleChange(question.label, itemValue)
                  }
                >
                  {[...new Array(50)].map((el, index) => (
                    <Picker.Item
                      key={index}
                      label={`${thisYear() - index}`}
                      value={thisYear() - index}
                    />
                  ))}
                </Picker>
                {/* <Text style={componentStyles.simulateTextInput}>
                  {answers[question.label] || 2015}
                </Text> */}
              </View>
            </>
          )}
          {question.type === "integer" && (
            <>
              <View style={[styles.flexRow, { justifyContent: "center" }]}>
                {question.unit.includes("per_week") ? (
                  <>
                    {[...new Array(7)].map((el, index) => (
                      <TouchableOpacity
                        style={[
                          index === answers[question.label]
                            ? componentStyles.weekItemContainerSelected
                            : componentStyles.weekItemContainer,
                        ]}
                        key={index}
                        onPress={() => handleChange(question.label, index)}
                      >
                        <Text
                          style={
                            index === answers[question.label]
                              ? componentStyles.listItemTextSelected
                              : componentStyles.listItemText
                          }
                        >
                          {index}
                        </Text>
                      </TouchableOpacity>
                    ))}
                    <Text style={componentStyles.unitFlex}>
                      {getUnit(question.unit)}
                    </Text>
                  </>
                ) : (
                  <View style={{ flex: 1 }}>
                    <View style={componentStyles.textInput}>
                      <Picker
                        style={{
                          height: 30,
                          // color: "white"
                        }}
                        selectedValue={answers[question.label] || 3}
                        onValueChange={(itemValue, itemIndex) =>
                          handleChange(question.label, itemValue)
                        }
                      >
                        {[...new Array(120)].map((el, index) => (
                          <Picker.Item
                            key={index}
                            label={`${
                              index * calculateUnitCoefficient(question.unit)
                            } ${getUnit(question.unit)}`}
                            value={
                              index * calculateUnitCoefficient(question.unit)
                            }
                          />
                        ))}
                      </Picker>
                      {/* <Text style={componentStyles.simulateTextInput}>
                        {answers[question.label]}{" "}
                        <Text style={componentStyles.unit}>
                          {getUnit(question.unit)}
                        </Text>
                      </Text> */}
                    </View>
                  </View>
                )}
              </View>
            </>
          )}
          {question.type === "list" && (
            <>
              <View style={[styles.flexRow, { justifyContent: "center" }]}>
                {question.answers.map((ans, indAns) => (
                  <TouchableOpacity
                    style={[
                      ans === answers[question.label] ||
                      (question.max &&
                        question.max > 0 &&
                        answers[question.label] &&
                        answers[question.label].length &&
                        answers[question.label].includes(ans))
                        ? componentStyles.listItemContainerSelected
                        : componentStyles.listItemContainer,
                      question.max && question.max > 0
                        ? {
                            minWidth: "100%",
                          }
                        : null,
                    ]}
                    key={indAns}
                    onPress={() => {
                      let newAns;
                      if (question.max && question.max > 0) {
                        if (
                          answers[question.label] &&
                          answers[question.label].length
                        ) {
                          const itemIndex = answers[question.label].findIndex(
                            (el) => el === ans
                          );
                          newAns = [...answers[question.label]];
                          if (itemIndex !== -1) {
                            console.log(itemIndex, newAns[itemIndex]);
                            newAns.splice(itemIndex, 1);
                          } else if (newAns.length < question.max) {
                            newAns.push(ans);
                          }
                        } else {
                          newAns = [];
                          newAns.push(ans);
                        }
                      } else {
                        newAns = ans;
                      }
                      handleChange(question.label, newAns);
                    }}
                  >
                    <Text
                      style={
                        ans === answers[question.label]
                          ? componentStyles.listItemTextSelected
                          : componentStyles.listItemText
                      }
                    >
                      {ans}
                      {question.unit}
                    </Text>
                  </TouchableOpacity>
                ))}
                {question.if_other_indicate &&
                  typeof answers[question.label] !== "string" && (
                    <TextInput
                      placeholder={"Si autre, indiquez"}
                      style={[
                        componentStyles.textInput,
                        { flex: 1, minWidth: "100%", margin: 5 },
                      ]}
                      value={answers[question.label]}
                      onChangeText={(text) =>
                        handleChange(question.label, [`${text}`])
                      }
                    />
                  )}
              </View>
            </>
          )}
          {question.type === "order" && (
            <>
              <View style={[styles.flexRow, { justifyContent: "center" }]}>
                {question.answers.map((ans, indAns) => (
                  <TouchableOpacity
                    style={[
                      ans === answers[question.label] ||
                      (question.max &&
                        question.max > 0 &&
                        answers[question.label] &&
                        answers[question.label].length &&
                        answers[question.label].includes(ans))
                        ? componentStyles.listItemContainerSelected
                        : componentStyles.listItemContainer,
                      question.max && question.max > 0
                        ? {
                            minWidth: "100%",
                          }
                        : null,
                    ]}
                    key={indAns}
                    onPress={() => {
                      let newAns;
                      if (question.max && question.max > 0) {
                        if (
                          answers[question.label] &&
                          answers[question.label].length
                        ) {
                          const itemIndex = answers[question.label].findIndex(
                            (el) => el === ans
                          );
                          newAns = [...answers[question.label]];
                          if (itemIndex !== -1) {
                            console.log(itemIndex, newAns[itemIndex]);
                            newAns.splice(itemIndex, 1);
                          } else if (newAns.length < question.max) {
                            newAns.push(ans);
                          }
                        } else {
                          newAns = [];
                          newAns.push(ans);
                        }
                      } else {
                        newAns = ans;
                      }
                      handleChange(question.label, newAns);
                    }}
                  >
                    <Text
                      style={
                        ans === answers[question.label]
                          ? componentStyles.listItemTextSelected
                          : componentStyles.listItemText
                      }
                    >
                      {ans}
                      {question.unit}
                    </Text>

                    {answers[question.label]?.length &&
                    answers[question.label].includes(ans) ? (
                      <Text style={componentStyles.orderListIndex}>
                        {answers[question.label].findIndex((el) => el === ans) +
                          1}
                      </Text>
                    ) : null}
                  </TouchableOpacity>
                ))}
                {question.if_other_indicate &&
                  typeof answers[question.label] !== "string" && (
                    <TextInput
                      placeholder={"Si autre, indiquez"}
                      style={[
                        componentStyles.textInput,
                        { flex: 1, minWidth: "100%", margin: 5 },
                      ]}
                      value={answers[question.label]}
                      onChangeText={(text) =>
                        handleChange(question.label, [`${text}`])
                      }
                    />
                  )}
              </View>
            </>
          )}
        </View>
      </View>
    </>
  );
}
