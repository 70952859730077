import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from "react-native";
import componentStyles from "./styles/modalNotificationsStyles";
import * as Notifications from "expo-notifications";
import * as Permissions from "expo-permissions";
import illu from "../../assets/acctiv_notif.png";
import OneQuestion from "./OneQuestion";
import api from "../services/api";
import colors from "../styles/variables";
import { useNavigation } from "@react-navigation/native";

export default function ModalNotifications(props) {
  const {
    notifications,
    open,
    markNotificationAsSeen,
    markAllNotificationAsSeen,
    userId,
  } = props;

  const navigation = useNavigation();

  const [step, setStep] = useState(1);
  const [notificationToDisplay, setNotificationToDisplay] = useState(null);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    setLoading(false);
    setAnswer(null);
    if (notifications.length) {
      setNotificationToDisplay(notifications[0]);
    } else {
      setNotificationToDisplay(null);
    }
  }, [notifications]);

  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(
      (notification) => {
        console.log(notification);
        // setNotification(notification);
      }
    );

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        console.log(response);
      }
    );

    return () => {
      Notifications.removeNotificationSubscription(notificationListener);
      Notifications.removeNotificationSubscription(responseListener);
    };
  }, []);

  const handleSetAnswer = async (ans) => {
    setLoading(true);
    setAnswer(ans);
    await api.update(`users-permissions/phase`, "", {
      user: userId,
      answer: ans,
    });
    markNotificationAsSeen(notificationToDisplay);
  };

  const getLabel = (notif) => {
    if (notif.repeatable) {
      return "Alors, vous avancez vers votre objectif ?";
    } else {
      switch (notif.type) {
        case "FEEDBACK":
          return "A propos de vos pratique de mobilité";
        case "OBJECTIF_REMINDER":
          return "Votre objectif !";
        case "OBJECTIF_EVALUATION":
          return "Votre objectif !";
        case "PERSONALIZED_ADVICE":
          return "Psst.. un petit conseil !";
        case "BEHAVIOUR_REPETITION":
          return "Bravo !";
        case "LONG_TERM_MAINTIEN":
          return "Bravo !";
      }
    }
  };

  const handlePass = () => {
    setLoading(true);
    markAllNotificationAsSeen();
  };

  return (
    notificationToDisplay && (
      <div
        style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
      >
        <View style={componentStyles.centeredView}>
          <View style={componentStyles.modalView}>
            <Text style={componentStyles.titleText}>
              {getLabel(notificationToDisplay.notification_id)}
            </Text>
            <Image
              source={illu}
              style={{
                flex: 1,
                width: "100%",
                resizeMode: "contain",
              }}
            />
            <View style={{ flex: 1, width: "100%" }}>
              <Text style={componentStyles.messageText}>
                {notificationToDisplay.notification_id && step === 2
                  ? "Pensez-vous réutiliser ce mode de transport à l'avenir ?"
                  : notificationToDisplay.message}
              </Text>
              {loading ? (
                <View style={{ marginTop: 20 }}>
                  <ActivityIndicator color={colors.primary} />
                </View>
              ) : notificationToDisplay.notification_id.key ===
                  "ACTION_KEEPING_GOAL" ||
                notificationToDisplay.notification_id.key ===
                  "ACTION_KEEPING_GOAL_TIME" ||
                notificationToDisplay.notification_id.key ===
                  "ACTION_KEEPING_GOAL_CO2" ? (
                <View
                  style={{
                    marginBottom: "auto",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <TouchableOpacity
                    style={
                      answer === true
                        ? componentStyles.listItemContainerSelected
                        : componentStyles.listItemContainer
                    }
                    onPress={() => {
                      if (step === 1) {
                        setStep(2);
                      } else {
                        handleSetAnswer(true);
                      }
                    }}
                  >
                    <Text
                      style={
                        answer === true
                          ? componentStyles.listItemTextSelected
                          : componentStyles.listItemText
                      }
                    >
                      Oui !
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={
                      answer === false
                        ? componentStyles.listItemContainerSelected
                        : componentStyles.listItemContainer
                    }
                    onPress={() => handleSetAnswer(false)}
                  >
                    <Text
                      style={
                        answer === false
                          ? componentStyles.listItemTextSelected
                          : componentStyles.listItemText
                      }
                    >
                      Non...
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <TouchableOpacity
                  style={componentStyles.openButton}
                  onPress={() => markNotificationAsSeen(notificationToDisplay)}
                >
                  <Text style={componentStyles.textStyle}>
                    {notificationToDisplay.notification_id.call_to_action
                      ? notificationToDisplay.notification_id.call_to_action
                      : "Suivant"}
                  </Text>
                </TouchableOpacity>
              )}
            </View>

            <View style={{ marginTop: 20, flexDirection: "row" }}>
              {[...new Array(notifications.length)].map((el, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      borderRadius: 10,
                      width: 5,
                      height: 5,
                      backgroundColor: "grey",
                      margin: 5,
                    }}
                  />
                );
              })}
            </View>
            <TouchableOpacity onPress={handlePass}>
              <Text style={{ textDecorationLine: "underline" }}>Passer</Text>
            </TouchableOpacity>
          </View>
        </View>
      </div>
    )
  );
}
