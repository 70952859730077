import React, { useEffect, useState } from "react";
import { Text, View, StatusBar } from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/journeysListStyles";
import { TouchableOpacity } from "react-native-gesture-handler";
import moment from "../services/localeMoment";
import ModeIconStep from "./ModeIconStep";
import { FontAwesome5 } from "@expo/vector-icons";
import colors from "../styles/variables";
import api from "../services/api";

export default function JourneysList(props) {
  const { userId, journeys, handleClickJourney } = props;

  const [budgetAndEmissions, setBudgetAndEmissions] = useState(null);

  useEffect(() => {
    const init = async (distance) => {
      setBudgetAndEmissions(
        await api.create(`emissions`, {
          user: userId,
          kms: distance,
        })
      );
    };

    const carObj = journeys.filter(function (el) {
      return el.mode?.[0] === "CAR";
    });

    const distance = carObj[0].steps.reduce((acc, item) => {
      return acc + item.distance;
    }, 0);

    init(distance / 1000);
  }, [journeys]);

  const formatDuration = (d) => {
    const hours = d / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    if (rhours > 0) {
      return `${rhours}h${rminutes}`;
    } else {
      return `${rminutes}`;
    }
  };

  const getEmission = (j) => {
    if (j.mode?.[0] === "BUS" || j.mode?.[0] === "METRO")
      return Math.round(
        budgetAndEmissions.emissions["CAR"] * 1000 -
          budgetAndEmissions.emissions["PUBTRANS"] * 1000
      );
    else if (j.mode?.[0] === "W") {
      return Math.round(budgetAndEmissions.emissions["CAR"] * 1000);
    } else {
      return Math.round(
        budgetAndEmissions.emissions["CAR"] * 1000 -
          budgetAndEmissions.emissions[j.mode?.[0]] * 1000
      );
    }
  };

  const getTimeCar = (j) => {
    j.map((el) => console.log("el type", typeof el));
    const filtered = j.filter((el) => el.mode?.[0] === "CAR");
    console.log("Filtered", filtered);
    return formatDuration(filtered?.[0].steps[0].duration / 60);
  };

  const getModePrice = (mode, distance) => {
    if (mode === "BIKE") {
      return Math.round(distance * 0.11 * 100) / 100;
    } else if (mode === "BUS" || "METRO") {
      return 1.7;
    }
  };

  const formatIsTrainUniqueModes = (j) => {
    return {
      mode: "TRAIN",
    };
  };

  const getDuration = (j) => {
    return j.isTrain
      ? j.duration.value / 60
      : moment
          .duration(moment(j.time.arrival).diff(moment(j.time.start)))
          .asMinutes();
  };

  return journeys.length ? (
    <View style={componentStyles.container}>
      {budgetAndEmissions && budgetAndEmissions.car_price && (
        <Text style={componentStyles.priceCar}>
          <Text style={componentStyles.priceCarHighlight}>
            En voiture, ce trajet vous aurait coûté{" "}
            {Math.round(budgetAndEmissions.car_price * 10) / 10}€ et vous aurait
            pris {getTimeCar(journeys)}
            minutes. Vous auriez émis{" "}
            {Math.round(budgetAndEmissions.emissions.CAR * 1000)} g de CO2.
          </Text>{" "}
          Utilisez un autre mode de transport pour faire des économies et
          réduire vos émissions de CO2 🌱
        </Text>
      )}
      {journeys
        .sort((a, b) => getDuration(a) - getDuration(b))
        .map((j, index) => {
          if (j.isTrain && !j.departure_time) return;
          return j && j.mode?.[0] === "CAR" ? null : (
            <TouchableOpacity
              key={index}
              style={componentStyles.journeyContainer}
              onPress={() => handleClickJourney(j)}
            >
              <View style={styles.flexRow}>
                <UniqueModes
                  steps={j.isTrain ? formatIsTrainUniqueModes(j) : j.steps}
                  isTrain={j.isTrain}
                />
                <Text style={componentStyles.duration}>
                  ~{formatDuration(getDuration(j))}
                  min
                </Text>
              </View>

              {j.isTrain && (
                <>
                  <Text style={componentStyles.trainHelper}>
                    En partant à{" "}
                    <Text style={{ fontFamily: "Raleway_700Bold" }}>
                      {moment(j.departure_time?.value * 1000).format("HH:mm")}
                    </Text>
                    {/* de{" "}
                  {j.start_address} */}
                  </Text>
                  <Text style={componentStyles.trainHelper}>
                    Arrivée à{" "}
                    <Text style={{ fontFamily: "Raleway_700Bold" }}>
                      {moment(j.arrival_time?.value * 1000).format("HH:mm")}
                    </Text>
                    {/* à{" "}
                  {j.end_address} */}
                  </Text>
                </>
              )}

              <View style={styles.flexRow}>
                {budgetAndEmissions && !j.isTrain && (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={[
                        componentStyles.emission,
                        { flex: 1, textAlign: "left", paddingRight: 50 },
                      ]}
                    >
                      <Text style={{ fontFamily: "Raleway_700Bold" }}>
                        {getEmission(j)}g de CO2
                      </Text>{" "}
                      de moins qu'en voiture 🍃{" "}
                    </Text>
                    {j.mode?.[0] !== "W" ? (
                      <View
                        style={{
                          backgroundColor: colors.secondary,
                          borderRadius: 15,
                          minWidth: 60,
                          justifyContent: "center",
                        }}
                      >
                        <Text style={componentStyles.price}>
                          {getModePrice(
                            j.mode?.[0],
                            j.steps[0].distance / 1000
                          )}
                          €
                        </Text>
                      </View>
                    ) : null}
                  </View>
                )}
              </View>
            </TouchableOpacity>
          );
        })}
    </View>
  ) : (
    <View>
      <Text>Aucun itinéraire possible</Text>
    </View>
  );
}

function UniqueModes(props) {
  const { steps, isTrain } = props;
  const [uniqueModes, setUniqueModes] = useState(null);
  useEffect(() => {
    if (steps && !uniqueModes && !isTrain) {
      setUniqueModes([
        ...new Set(
          steps.map((s) => {
            return {
              mode: s.mode,
              lines: s.lines,
              duration: s.duration,
            };
          })
        ),
      ]);
    } else if (steps && !uniqueModes && isTrain) {
      console.log("Is train", steps);
      setUniqueModes([
        {
          mode: steps.mode,
        },
      ]);
    }
  }, [steps, isTrain]);

  return (
    <View style={styles.flexRow}>
      {uniqueModes
        ? uniqueModes.map((mode, index) => {
            return (
              <View key={index}>
                <ModeIconStep mode={mode} />
              </View>
            );
          })
        : null}
    </View>
  );
}
