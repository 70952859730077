import React, { useEffect, useRef, useState } from "react";
import { Modal, View, Text, TouchableOpacity } from "react-native";
import styles from "../styles/global";
import componentStyles from "./styles/microModalFeedbackStyles";
import { useGoal } from "../context/goalContext";
import moment from "../services/localeMoment";

export default function MicroModalFeedback(props) {
  const { open, close, data } = props;

  const { goal } = useGoal();

  const getTitle = () => {
    switch (data.type) {
      case "SAVINGS":
        return "Vos économies depuis le début du programme";
      case "GOAL_BUDGET":
        return "Votre objectif !";
    }
  };

  const getBody = () => {
    switch (data.type) {
      case "SAVINGS":
        return "Chaque semaine, nous vous demanderons si vous tenez bien votre objectif, si c'est le cas vous verrez vos économies augmenter !";
      case "GOAL_BUDGET":
        let message = "";
        switch (data?.phase) {
          case "MAINTIEN":
            message = `Bravo ! Vous avez tenu bon votre objectif pendant plusieurs semaines. Maintenez ce rythme et vous réduirez votre budget transport à ${data?.goalBudget}€ cette année.`;
            break;
          case "ACTION":
            message = `Si vous tenez votre objectif, votre budget transport ne sera que de ${data?.goalBudget}€, à vous les économies !`;
            break;
          case "POSTACTION":
            message = `Bravo, vous êtes passé à l'action, vous économisez ! Continuez encore et votre budget transport ne sera que de ${data?.goalBudget}€ cette année!`;
            break;
        }
        return `${message}`;
    }
  };

  const getDetails = () => {
    switch (data.type) {
      case "SAVINGS":
        return "";
      case "GOAL_BUDGET":
        return `Objectif fixé ${moment(goal.updated_at).fromNow()}`;
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <View style={componentStyles.centeredView}>
        <View style={componentStyles.modalView}>
          <Text style={componentStyles.titleText}>{getTitle()}</Text>
          <Text
            style={[
              styles.normalText,
              { paddingBottom: 20, textAlign: "center" },
            ]}
          >
            {getBody()}
          </Text>
          <Text style={componentStyles.smallText}>{getDetails()}</Text>
          <TouchableOpacity
            style={componentStyles.openButton}
            onPress={() => close()}
          >
            <Text style={componentStyles.textStyle}>Fermer</Text>
          </TouchableOpacity>
        </View>
      </View>
    </div>
  );
}
