import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  container: {
    padding: 20,
    flex: 1,
  },
  progressBar: {
    height: 10,
    width: "100%",
    backgroundColor: colors.lightgray,
  },
  activeProgress: {
    height: "100%",
    backgroundColor: colors.secondary,
    minWidth: 5,
  },
  errorContainer: {
    fontFamily: "Raleway_700Bold",
    marginTop: 2,
    marginBottom: 20,
    color: "white",
    backgroundColor: colors.primary,
    padding: 12,
    // borderRadius: 8,
  },
});
