import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  labelStyle: {
    fontFamily: "Raleway_700Bold",
    textTransform: "uppercase",
  },
  tabStyle: {
    borderTopLeftRadius: 99,
    borderTopRightRadius: 99,
    marginTop: -10,
    paddingTop: 8,
    paddingBottom: 3,
  },
  headerTitleText: {
    fontFamily: "Raleway_700Bold",
    fontSize: 18,
    color: colors.primary,
  },
  headerTitleTopLogo: {
    backgroundColor: "#ffffffbb",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginTop: -30,
    paddingTop: 40,
    paddingBottom: 10,
    paddingHorizontal: 10,
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
});
