import { StyleSheet } from "react-native";
import colors from "./variables";

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    paddingHorizontal: 20,
    // paddingBottom: 20,
    paddingTop: 0,
  },
});
