import { StyleSheet } from "react-native";
import colors from "../../styles/variables";

export default StyleSheet.create({
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 25,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: "80%",
    alignItems: "center",
  },
  openButton: {
    margin: 7,
    padding: 16,
    borderRadius: 5,
    backgroundColor: colors.primary,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  titleText: {
    fontSize: 16,
    color: colors.primary,
    fontFamily: "Raleway_700Bold",
    textAlign: "center",
    padding: 20,
  },
  smallText: {
    fontSize: 12,
    color: colors.secondary,
    fontFamily: "Raleway_700Bold",
    textAlign: "center",
    paddingBottom: 20,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  messageText: {
    textAlign: "center",
    fontFamily: "Raleway_400Regular",
    marginBottom: 10,
  },
  listItemContainer: {
    backgroundColor: "white",
    margin: 2,
    padding: 6,
    borderRadius: 5,
    minHeight: 60,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  listItemText: {
    textAlign: "center",
    color: colors.primary,
    fontFamily: "Raleway_400Regular",
  },

  listItemContainerSelected: {
    backgroundColor: colors.secondary,
    margin: 2,
    padding: 6,
    borderRadius: 5,
    minHeight: 60,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  listItemTextSelected: {
    color: "white",
    fontFamily: "Raleway_700Bold",
    textAlign: "center",
  },
  linkContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    backgroundColor: colors.verylightgray,
    padding: 10,
    marginVertical: 4,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
});
